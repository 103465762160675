import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';

import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import JumboChipsGroup from '@jumbo/components/JumboChipsGroup';
import Div from '@jumbo/shared/Div';

import MultipleBranchesComparisonChart from './components/MultipleBranchesComparisonChart';
import { makeChips, randomColorGenerator } from './utils';

const MultipleBranchesComparison = () => {
  const { branches } = useSelector(state => state.accounts.accounts);
  const result = useMemo(() => makeChips(branches), [branches]);
  const [branchesToShow, setBranchesToShow] = useState(result);
  const [selectedBranches, setSelectedBranches] = useState([]);

  const handleChipClick = e => {
    const {
      target: { value },
    } = e;

    const isAlreadyPresent = selectedBranches?.filter(
      item => item.name.toLowerCase() === value.name.toLowerCase(),
    );
    if (isAlreadyPresent.length) {
      const filteredBranches = selectedBranches.filter(
        branch => branch.name.toLowerCase() !== value.name.toLowerCase(),
      );

      setSelectedBranches(filteredBranches);
      const filtered = branchesToShow.filter(
        branch => branch.name.toLowerCase() === value.name.toLowerCase(),
      )[0];
      filtered.color = 'gray';

      const remaining = branchesToShow.filter(
        branch => branch.name.toLowerCase() !== value.name.toLowerCase(),
      );
      setBranchesToShow([...remaining, filtered]);
      return;
    }

    const filtered = branchesToShow.filter(
      branch => branch.name.toLowerCase() === value.name.toLowerCase(),
    )[0];
    const remaining = branchesToShow.filter(
      branch => branch.name.toLowerCase() !== value.name.toLowerCase(),
    );
    filtered.color = 'orange';
    filtered.colorToShow = randomColorGenerator();

    setBranchesToShow([...remaining, filtered]);
    setSelectedBranches(prev => [...prev, value]);
  };

  return (
    <JumboCardQuick
      title={'Branches Expense Earning Comparison'}
      wrapperSx={{ pt: 0 }}>
      <Div
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <Typography>Select Branches</Typography>
        <Div>
          <JumboChipsGroup
            chips={branchesToShow}
            max={branchesToShow.length}
            onClick={handleChipClick}
            spacing={1}
            mapKeys={{ label: 'name', color: 'chipColor' }}
          />
        </Div>
      </Div>

      <Div>
        {selectedBranches.length ? (
          <MultipleBranchesComparisonChart data={selectedBranches} />
        ) : (
          <Div
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}>
            <img
              src="/images/empty.png"
              alt="Failed to Load "
              style={{ marginTop: -50 }}
            />
            <Typography sx={{ mt: -2, fontSize: '20px', fontWeight: '500' }}>
              Please Select Branch
            </Typography>
          </Div>
        )}
      </Div>
    </JumboCardQuick>
  );
};

export default MultipleBranchesComparison;
