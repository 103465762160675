import { Typography } from '@mui/material';

const CustomTypography = ({
  variant = 'body1',
  textAlign = 'left',
  color = '#000',
  children,
  sx = {},
}) => {
  return (
    <Typography variant={variant} textAlign={textAlign} color={color} sx={sx}>
      {children}
    </Typography>
  );
};

export default CustomTypography;
