import {
  Typography,
  Tooltip,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import { Science } from '@mui/icons-material';
import styled from '@emotion/styled';

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 24,
  height: 48,
  width: 48,
  borderRadius: '50%',
  minWidth: 42,
  marginRight: 16,
  padding: theme.spacing(1),
  alignItems: 'center',
  justifyContent: 'center',
  border: `solid 1px ${theme.palette.divider}`,
}));

const Resume = ({ teacher: { resume } }) => {
  return (
    <>
      {resume?.map(item => (
        <div key={item.id}>
          <ListItem
            sx={{
              width: { xs: '100%', sm: '50%', xl: '33.33%' },
            }}>
            <StyledListItemIcon>
              <Science fontSize={'inherit'} />
            </StyledListItemIcon>
            <ListItemText
              primary={
                <Tooltip title="Name of Institute">
                  <Typography
                    fontSize={'12px'}
                    variant="h6"
                    color="text.secondary"
                    mb={0.5}>
                    {item.name}
                  </Typography>
                </Tooltip>
              }
              secondary={
                <>
                  <Tooltip title="Duration">
                    <Typography variant="body1" color="text.primary">
                      {item.duration}
                    </Typography>
                  </Tooltip>
                  <Tooltip title="Description">
                    <Typography variant="body1" color="text.primary">
                      {item.description}
                    </Typography>
                  </Tooltip>
                </>
              }
            />
          </ListItem>
        </div>
      ))}
    </>
  );
};

export default Resume;
