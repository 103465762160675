export const calculatePaidFees = students =>
  students.reduce(
    (prevValue, currentValue) => prevValue + currentValue.paidFee,
    0,
  );

export const calculateRemainingFees = students =>
  students.reduce(
    (prevValue, currentValue) =>
      prevValue +
      (currentValue.recommendedFee -
        currentValue.registerFeeDiscount -
        currentValue.firstMonthFee -
        currentValue.paidFee),
    0,
  );

export const calculateForMonth = (students, month) => {
  let paidFee = 0,
    discount = 0,
    remainingFee = 0;

  students.map(student => {
    const { feeHistory } = student;
    const selectedMonth = feeHistory.filter(item => item.month === month)[0];

    remainingFee +=
      selectedMonth.totalFee - selectedMonth.discount - selectedMonth.paidFee;
    paidFee += selectedMonth.paidFee;
    discount += selectedMonth.discount;

    return student;
  });

  return { paidFee, discount, remainingFee };
};

const COLORS = ['warning', 'secondary', 'success'];

export const calculateBranchForMonth = (students, month) => {
  const results = [];

  for (let i = 0; i < students.length; i++) {
    const selectedBranch = students.filter(
      ({ branch }) => branch === students[i].branch,
    );
    let paidFee = 0,
      totalFee = 0;

    for (let j = 0; j < selectedBranch.length; j++) {
      const { feeHistory } = selectedBranch[j];
      const selectedMonth = feeHistory.filter(item => item.month === month)[0];
      paidFee += selectedMonth.paidFee;
      totalFee += selectedMonth.totalFee;
    }

    let obj = {
      id: i + 1,
      name: students[i].branch,
      value: Number.parseFloat((paidFee / totalFee) * 100).toFixed(2),
    };

    if (obj.value < 50) {
      obj.color = COLORS[1];
    }
    if (obj.value < 80 && obj.value > 50) {
      obj.color = COLORS[0];
    }
    if (obj.value > 80) {
      obj.color = COLORS[2];
    }

    results.push(obj);
  }
  return results;
};
