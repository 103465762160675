import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  API_DELETE,
  API_GET,
  API_POST,
  API_PUT,
} from 'app/utils/constants/api/apiRequests';
import { setLoading, setLoadingFinished } from './loadingSlice';
import { showAlert } from './alertSlice';

const initialState = {
  occupations: [],
  isDataFetched: false,
};

export const getAllOccupations = createAsyncThunk(
  'occupations/getAllOccupations',
  async (_, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data } = await API_GET('/occupation');

      if (status) {
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const addNewOccupation = createAsyncThunk(
  'occupations/addNewOccupation',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, message } = await API_POST('/occupation', payload);
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllOccupations());
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const editOccupation = createAsyncThunk(
  'occupations/editOccupation',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    const dataToSend = { name: payload.name, status: payload.status };
    try {
      const { status, data, message } = await API_PUT(
        '/occupation',
        payload.id,
        dataToSend,
      );
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllOccupations());
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const deleteOccupation = createAsyncThunk(
  'occupations/deleteOccupation',
  async (id, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_DELETE('/occupation', id);
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllOccupations());
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const occupationsSlice = createSlice({
  name: 'occupations',
  initialState,

  extraReducers: builder => {
    builder.addCase(getAllOccupations.fulfilled, (state, { payload }) => {
      state.occupations = payload;
      state.isDataFetched = true;
    });
  },
});

export default occupationsSlice.reducer;
