import { useDispatch } from 'react-redux';

import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { Form, Formik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import Div from '@jumbo/shared/Div';

import { subjectSchema } from '../../utils/schema';
import JumboDropDown from '@jumbo/components/JumboFormik/JumboDropDown';
import { SUBJECT_GROUPS, GENERIC_YES_NO } from 'app/shared/constants';
import { addNewSubject } from 'app/store/features/subjectsSlice';

const SubjectForm = ({ payload }) => {
  const dispatch = useDispatch();
  const { hideDialog } = useJumboDialog();

  const isUpdate = payload ? true : false;
  const initialValues = {
    name: payload ? payload.name : '',
    user_subject_code: payload ? payload.user_subject_code : '',
    fee_amount: payload ? payload.fee_amount : '',
    group: payload ? payload.group : '',
    status: payload ? payload.status : '',
  };

  const addClassHandler = data => {
    const payload = data;
    payload.status = data.status.toLowerCase() === 'yes' ? true : false;
    dispatch(addNewSubject(payload));
    hideDialog();
  };

  const updateClassHandler = data => {
    hideDialog();
  };

  const onClassSave = data => {
    isUpdate ? updateClassHandler(data) : addClassHandler(data);
  };
  return (
    <Formik
      validateOnChange={true}
      initialValues={payload?.id ? payload : initialValues}
      enableReinitialize={true}
      validationSchema={subjectSchema}
      onSubmit={onClassSave}>
      {({ isSubmitting }) => (
        <Form noValidate autoComplete="off">
          <Div
            sx={{
              '& .MuiTextField-root': {
                mb: 3,
              },
            }}>
            <JumboTextField
              fullWidth
              size="small"
              variant="outlined"
              name="name"
              label="Subject Name"
            />
            <JumboTextField
              fullWidth
              size="small"
              variant="outlined"
              name="user_subject_code"
              label="User Subject Code"
              type="number"
            />

            <JumboTextField
              fullWidth
              size="small"
              variant="outlined"
              name="fee_amount"
              label="Subject Fee"
              type="number"
            />
            <JumboDropDown
              fullWidth
              size="small"
              variant="outlined"
              name="group"
              label="Please Select Subject Group"
              options={SUBJECT_GROUPS}
            />
            <JumboDropDown
              fullWidth
              size="small"
              variant="outlined"
              name="status"
              label="Is Subject Active(status)?"
              options={GENERIC_YES_NO}
            />
            <Div
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
              }}>
              <LoadingButton
                type="submit"
                variant="contained"
                size="large"
                sx={{
                  mb: 3,
                  backgroundColor: '#E44A77',
                  ':hover': {
                    backgroundColor: '#e44a77',
                  },
                }}
                onClick={() => hideDialog()}>
                Close
              </LoadingButton>
              <LoadingButton
                type="submit"
                variant="contained"
                size="large"
                sx={{ mb: 3 }}
                loading={isSubmitting}>
                Save
              </LoadingButton>
            </Div>
          </Div>
        </Form>
      )}
    </Formik>
  );
};

export default SubjectForm;
