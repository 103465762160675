import { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Tooltip,
  Button,
} from '@mui/material';
import Typography from 'app/shared/components/Typography';
import Div from '@jumbo/shared/Div';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import moment from 'moment';

const BranchCard = ({
  name = '',
  logo,
  handleDelete,
  handleEdit,
  branch,
  inChargeName,
  address,
  contactNumber,
  email,
  code,
  details,
  status,
  createdAt,
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Accordion expanded={expanded} square sx={{ borderRadius: 2, mb: 1 }}>
      <AccordionSummary
        expandIcon={
          expanded ? (
            <RemoveIcon onClick={() => setExpanded(false)} />
          ) : (
            <AddIcon onClick={() => setExpanded(true)} />
          )
        }
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{
          px: 3,
          flexDirection: 'row-reverse',

          '& .MuiAccordionSummary-content': {
            alignItems: 'center',

            '&.Mui-expanded': {
              margin: '12px 0',
            },
          },
          '.MuiAccordionSummary-expandIconWrapper': {
            borderRadius: 1,
            border: 1,
            color: 'text.secondary',
            borderColor: 'divider',
            transform: 'none',
            height: 28,
            width: 28,
            alignItems: 'center',
            justifyContent: 'center',
            mr: 1,

            '&.Mui-expanded': {
              transform: 'none',
              color: 'primary.main',
              borderColor: 'primary.main',
            },

            '& svg': {
              fontSize: '1.25rem',
            },
          },
        }}>
        <Div
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '60%',
          }}>
          <Div sx={{ flexShrink: 0, px: 1 }}>
            <Avatar
              sx={{ width: 56, height: 56, borderRadius: '100%' }}
              alt={`${name}`}
              src={
                logo
                  ? logo
                  : 'https://static01.nyt.com/images/2011/04/17/education/edl-17branch-t_CA2/edl-17branch-t_CA2-jumbo.jpg?quality=75&auto=webp&disable=upscale'
              }
              variant="rounded"
            />
          </Div>

          <Div>
            <Tooltip title="Branch Name">
              <Typography variant="h3" sx={{ mx: 1 }}>
                {name}
              </Typography>
            </Tooltip>
            <Tooltip title="Incharge Name">
              <Typography variant="h6" sx={{ mx: 1 }}>
                {inChargeName}
              </Typography>
            </Tooltip>
          </Div>
        </Div>
        <Div
          sx={{
            display: 'flex',
            width: '40%',
            justifyContent: 'flex-end',
            flexShrink: 0,
            px: 1,
          }}>
          <Button
            variant="outlined"
            onClick={() => handleEdit(branch)}
            sx={{ marginRight: 2 }}>
            Edit
          </Button>
          <Button variant="contained" onClick={() => handleDelete(branch.id)}>
            Delete
          </Button>
        </Div>
      </AccordionSummary>

      <AccordionDetails
        sx={{
          borderTop: 1,
          borderColor: 'divider',
          p: theme => theme.spacing(2, 2, 2, 8.25),
        }}>
        <Div
          sx={{
            display: { xs: 'flex' },
            minHeight: 200,
            overflowY: 'scroll',
            flexDirection: 'column',
          }}>
          <Div sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Branch Name:{' '}
            </Typography>
            <Typography
              variant="text1"
              sx={{ marginLeft: 1, marginTop: '-5px' }}>
              {name}
            </Typography>
          </Div>
          <Div sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Branch Incharge Name:{' '}
            </Typography>
            <Typography
              variant="text1"
              sx={{ marginLeft: 1, marginTop: '-5px' }}>
              {inChargeName}
            </Typography>
          </Div>
          <Div sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Branch Address:{' '}
            </Typography>
            <Typography
              variant="text1"
              sx={{ marginLeft: 1, marginTop: '-5px' }}>
              {address}
            </Typography>
          </Div>
          <Div sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Branch Contact Number:{' '}
            </Typography>
            <Typography
              variant="text1"
              sx={{ marginLeft: 1, marginTop: '-5px' }}>
              {contactNumber}
            </Typography>
          </Div>
          <Div sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Branch Email:{' '}
            </Typography>
            <Typography
              variant="text1"
              sx={{ marginLeft: 1, marginTop: '-5px' }}>
              {email}
            </Typography>
          </Div>

          <Div sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Branch Code:{' '}
            </Typography>
            <Typography
              variant="text1"
              sx={{ marginLeft: 1, marginTop: '-5px' }}>
              {code}
            </Typography>
          </Div>

          <Div sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Branch Details:{' '}
            </Typography>
            <Typography
              variant="text1"
              sx={{ marginLeft: 1, marginTop: '-5px' }}>
              {details}
            </Typography>
          </Div>

          <Div sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Branch Status:{' '}
            </Typography>
            <Typography
              variant="text1"
              sx={{ marginLeft: 1, marginTop: '-5px' }}>
              {status}
            </Typography>
          </Div>

          <Div sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Branch Created At:{' '}
            </Typography>
            <Typography
              variant="text1"
              sx={{ marginLeft: 1, marginTop: '-5px' }}>
              {moment(createdAt).format('DD-MM-YYYY')}
            </Typography>
          </Div>
        </Div>
      </AccordionDetails>
    </Accordion>
  );
};

export default BranchCard;
