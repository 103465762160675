import * as yup from 'yup';

export const expenseSchema = yup.object().shape({
  expense: yup
    .number()
    .positive('Expense cannot be Negative')
    .required('Expense is Required'),
  expenseReason: yup.string().required('Please mention Withdrawal Reason'),
});

export const depositSchema = yup.object().shape({
  deposit: yup
    .number()
    .positive('Deposit cannot be Negative')
    .required('Deposit is Required'),
  depositReason: yup.string().required('Please mention Deposit Reason'),
});
