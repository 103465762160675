import { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import JumboDropDown from '@jumbo/components/JumboFormik/JumboDropDown';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import JumboCNICField from '@jumbo/components/JumboFormik/JumboCNICField';

import { getAllOccupations } from 'app/store/features/occupationSlice';
import JumboPhoneNumberField from '@jumbo/components/JumboFormik/JumboPhoneNumberField';
import { getAllStudents } from 'app/store/features/studentsSlice';

const options = [
  {
    label: 'Father',
    value: 'father',
  },
  {
    label: 'Mother',
    value: 'mother',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];
const ParentalInfo = () => {
  const dispatch = useDispatch();
  const [noOfSiblings, setNoOfSiblings] = useState(0);

  const isStudentsFetched = useSelector(state => state.students.isDataFetched);
  const students = useSelector(state => state.students.students);

  const isOccupationsFetched = useSelector(
    state => state.occupations.isDataFetched,
  );
  const occupations = useSelector(state => state.occupations.occupations);

  const fetchData = useCallback(async () => {
    if (!isOccupationsFetched) dispatch(getAllOccupations());
    if (!isStudentsFetched) dispatch(getAllStudents());
  }, [isOccupationsFetched, dispatch, isStudentsFetched]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const calculateNoOfSiblings = () => {};

  return (
    <div style={{ paddingRight: 10 }}>
      <JumboTextField
        fullWidth
        size="small"
        variant="outlined"
        name="father_name"
        label="Father Name"
      />
      <JumboCNICField
        fullWidth
        size="small"
        variant="outlined"
        name="father_cnic"
        label="Father CNIC"
      />
      <JumboTextField
        fullWidth
        size="small"
        variant="outlined"
        name="father_qualification"
        label="Father's Qualification"
      />

      <JumboDropDown
        fullWidth
        size="small"
        variant="outlined"
        name="father_occupation_id"
        label="Father's Occupation"
        options={occupations?.filter(
          occupation => occupation?.name !== 'House Wife',
        )}
      />

      <JumboTextField
        fullWidth
        size="small"
        variant="outlined"
        name="mother_name"
        label="Mother Name"
      />
      <JumboCNICField
        fullWidth
        size="small"
        variant="outlined"
        name="mother_cnic"
        label="Mother's CNIC"
      />
      <JumboTextField
        fullWidth
        size="small"
        variant="outlined"
        name="mother_qualification"
        label="Mother's Qualification"
      />
      <JumboDropDown
        fullWidth
        size="small"
        variant="outlined"
        name="mother_occupation_id"
        label="Mother's Occupation"
        options={occupations}
      />

      <JumboPhoneNumberField
        fullWidth
        name="father_mobile_number"
        label="Father's Mobile Number"
      />

      <JumboPhoneNumberField
        fullWidth
        name="mother_mobile_number"
        label="Mother's Mobile Number"
      />

      <JumboPhoneNumberField
        fullWidth
        name="other_mobile_number"
        label="Other Mobile Number"
      />

      <JumboDropDown
        fullWidth
        size="small"
        variant="outlined"
        name="primary_contact"
        label="Primary Contact"
        options={options}
      />

      <JumboTextField
        fullWidth
        size="small"
        variant="outlined"
        name="family_code"
        label="Family Code"
      />
      <JumboTextField
        fullWidth
        size="small"
        variant="outlined"
        name="no_of_siblings"
        label="Number of Siblings"
        type="number"
        disabled
      />
      <JumboTextField
        fullWidth
        size="small"
        variant="outlined"
        name="caste"
        label="Caste of Student"
      />
    </div>
  );
};

export default ParentalInfo;
