import * as yup from 'yup';

export const studentItemSchema = yup.object().shape({
  item_id: yup.number().required('Item is Required'),
  assigned_by_id: yup.number().required('Assigned by is Required'),
  quantity: yup
    .number()
    .min(1, 'Quantity cannot be less than 1')
    .required('Quantity is Required'),
  receivable_amount: yup
    .number()
    .min(1, 'Receivable amount cannot be less than 1')
    .required('Receivable amount is Required'),
});
