import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Paper,
  Button,
  TableRow,
  TableCell,
} from '@mui/material';
import { useSelector } from 'react-redux';

const BillExpensesList = ({ expenses, setExpenses }) => {
  const storeExpenses = useSelector(state => state.expense.expenses);

  const handleDelete = id => {
    const newExpenses = expenses.filter(expense => expense.id !== id);

    setExpenses(newExpenses);
  };

  const resolveLabel = id => {
    const item = storeExpenses.find(item => item.id === id);

    return item.title;
  };

  return (
    <TableContainer component={Paper} sx={{ mb: 2 }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Expense</TableCell>
            <TableCell align="right">Description</TableCell>
            <TableCell align="right">Quantity</TableCell>
            <TableCell align="right">Unit Price</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {expenses.map(expense => (
            <TableRow key={expense.id}>
              <TableCell component="th" scope="row">
                {resolveLabel(expense.expense_id)}
              </TableCell>
              <TableCell align="right">{expense.description}</TableCell>
              <TableCell align="right">{expense.quantity}</TableCell>
              <TableCell align="right">{expense.unit_rate}</TableCell>
              <TableCell align="right">{expense.amount}</TableCell>
              <TableCell align="right">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleDelete(expense.id)}>
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BillExpensesList;
