import React from 'react';
import { useField } from 'formik';
import TextField from '@mui/material/TextField';
import { formatPhoneNumber } from 'app/utils/appHelpers';

const JumboPhoneNumber = ({ name, ...props }) => {
  const [field, meta, helpers] = useField(name);
  const errorText = meta.touched && meta.error ? meta.error : '';

  const handleChange = e => {
    const { value } = e.target;
    const formattedValue = formatPhoneNumber(value);
    helpers.setValue(formattedValue);
  };

  return (
    <TextField
      {...props}
      {...field}
      helperText={errorText}
      error={!!errorText}
      onChange={handleChange}
      inputProps={{
        inputMode: 'numeric',
        pattern: '[+0-9 -]*',
      }}
    />
  );
};

export default JumboPhoneNumber;
