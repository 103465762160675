import { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import { Card, ButtonGroup, Button } from '@mui/material';
import { List, ViewComfy } from '@mui/icons-material';

import JumboRqList from '@jumbo/components/JumboReactQuery/JumboRqList';
import JumboListToolbar from '@jumbo/components/JumboList/components/JumboListToolbar';
import JumboSearch from '@jumbo/components/JumboSearch';

import FeeItem from './FeeItem';
import { getAllFee } from 'app/store/features/feeSlice';

const FeeList = () => {
  const { category } = useParams();
  const dispatch = useDispatch();

  const listRef = useRef();
  const fee = useSelector(state => state.fee.fee);
  const isFeeFetched = useSelector(state => state.fee.isDataFetched);

  const [view, setView] = useState('list');
  const [dataToShow, setDataToShow] = useState(fee);

  useEffect(() => {
    setDataToShow(fee);
  }, [fee]);

  const fetchData = useCallback(() => {
    if (!isFeeFetched) {
      dispatch(getAllFee());
    }
  }, [dispatch, isFeeFetched]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (category === 'all') {
      setDataToShow(fee);
    } else {
      const filteredStudents = fee?.filter(
        student =>
          student.status?.toLowerCase() === category?.toLocaleLowerCase(),
      );

      setDataToShow(filteredStudents);
    }
  }, [category, fee]);

  const renderItem = useCallback(
    fee => {
      return <FeeItem fee={fee} view={view} />;
    },
    [view],
  );

  const handleOnChange = useCallback(
    keyword => {
      const filtered = fee?.filter(fee => {
        const name = `${fee?.student?.first_name} ${fee?.student?.last_name}`;
        return name.toLowerCase().includes(keyword?.toLowerCase());
      });
      setDataToShow(filtered);
    },
    [fee],
  );

  return (
    <JumboRqList
      ref={listRef}
      data={dataToShow}
      wrapperComponent={Card}
      primaryKey={'id'}
      itemsPerPage={8}
      itemsPerPageOptions={[8, 15, 20]}
      renderItem={renderItem}
      componentElement={'div'}
      sx={view === 'grid' && { p: theme => theme.spacing(1, 3, 3) }}
      wrapperSx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
      toolbar={
        <JumboListToolbar
          hideItemsPerPage={true}
          actionTail={
            <ButtonGroup
              variant="outlined"
              disableElevation
              sx={{
                '& .MuiButton-root': {
                  px: 1,
                },
              }}>
              <Button
                variant={view === 'list' ? 'contained' : 'outlined'}
                onClick={() => setView('list')}>
                <List />
              </Button>
              <Button
                variant={view === 'grid' ? 'contained' : 'outlined'}
                onClick={() => setView('grid')}>
                <ViewComfy />
              </Button>
            </ButtonGroup>
          }>
          <JumboSearch
            onChange={handleOnChange}
            sx={{
              display: { xs: 'none', md: 'block' },
            }}
          />
        </JumboListToolbar>
      }
      view={view}
    />
  );
};

export default FeeList;
