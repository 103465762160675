import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import NonExpenseSidebar from './components/NonExpenseSidebar';
import PaymentsList from './components/NonExpensePaymentsList';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { getAllNonExpensePayments } from 'app/store/features/nonExpenseSlice';

const NonExpensePayments = () => {
  const dispatch = useDispatch();
  const isNonExpensePaymentsFetched = useSelector(
    state => state.nonExpensePayments.isDataFetched,
  );
  const user = useSelector(state => state.auth.user);

  const isSidebarVisibleToUser =
    user?.user_type === 'admin' ||
    user?.permissions['non-expense-payment']?.add;

  const fetchData = useCallback(() => {
    if (!isNonExpensePaymentsFetched) dispatch(getAllNonExpensePayments());
  }, [dispatch, isNonExpensePaymentsFetched]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <JumboContentLayout
        sidebar={isSidebarVisibleToUser && <NonExpenseSidebar />}>
        <PaymentsList />
      </JumboContentLayout>
    </>
  );
};
export default NonExpensePayments;
