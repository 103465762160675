import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

import { Scrollbars } from 'react-custom-scrollbars-2';

const data = [
  {
    class: 'First',
    subjects: [
      {
        name: 'Islamyat',
        marks: 80,
        grade: 'A+',
      },
    ],
    status: 'Passed',
  },
];

const ResultOverview = ({ student }) => {
  return (
    <Scrollbars style={{ height: 300 }}>
      <div style={{ width: '100%' }}>
        {data?.map((classItem, index) => (
          <div key={index} style={{ marginBottom: 20 }}>
            <Typography variant="h4">Name: {classItem.class}</Typography>
            <TableContainer component={Paper} sx={{ width: '100%' }}>
              <Table aria-label="result table">
                <TableHead>
                  <TableRow>
                    <TableCell>Subject Name</TableCell>
                    <TableCell>Obtained Marks</TableCell>
                    <TableCell>Grade</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {classItem?.subjects?.map((subject, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}>
                      <TableCell component="th" scope="row">
                        {subject.name}
                      </TableCell>
                      <TableCell>{subject.marks}</TableCell>
                      <TableCell>{subject.grade}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Typography marginTop={1}>
              Status:{' '}
              {classItem.status === 'Passed' ? (
                <span style={{ color: 'green' }}>Passed</span>
              ) : (
                <span style={{ color: 'red' }}>Failed</span>
              )}
            </Typography>
          </div>
        ))}
      </div>
    </Scrollbars>
  );
};

export default ResultOverview;
