import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ListItemText,
  ListItemIcon,
  ListItemAvatar,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Tooltip,
  Typography,
  Avatar,
  Stack,
  List,
  ListItem,
} from '@mui/material';
import {
  MoreHoriz,
  Edit,
  Delete,
  Category,
  Book,
  Inventory,
  Inventory2,
  PriceCheck,
} from '@mui/icons-material';
import styled from '@emotion/styled';

import JumboDdMenu from '@jumbo/components/JumboDdMenu';
import Span from '@jumbo/shared/Span';
import JumboListItem from '@jumbo/components/JumboList/components/JumboListItem';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import JumboGridItem from '@jumbo/components/JumboList/components/JumboGridItem';

import ClassForm from '../ItemForm';
import ClassesDetail from '../ItemDetail';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { deleteItem } from 'app/store/features/itemSlice';

const Item = styled(Span)(({ theme }) => ({
  minWidth: 0,
  flexGrow: 0,
  padding: theme.spacing(0, 1),
}));

const InventoryItem = ({ item, view }) => {
  const { showDialog, hideDialog } = useJumboDialog();
  const user = useSelector(state => state.auth.user);

  const isUserHasEditAccess =
    user?.user_type === 'admin' || user?.permissions['inventory']?.edit;
  const isUserHasDeleteAccess =
    user?.user_type === 'admin' || user?.permissions['inventory']?.delete;

  const dispatch = useDispatch();
  const Swal = useSwalWrapper();

  const showContactDetail = React.useCallback(() => {
    showDialog({
      content: <ClassesDetail item={item} onClose={hideDialog} />,
    });
  }, [showDialog, item, hideDialog]);

  const handleDeleteItem = data => {
    dispatch(deleteItem(data.id));
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Item has been deleted successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
    hideDialog();
  };

  const handleItemAction = menuItem => {
    switch (menuItem.action) {
      case 'edit':
        showDialog({
          title: 'Update Item',
          content: <ClassForm item={item} />,
        });
        break;
      case 'delete':
        showDialog({
          variant: 'confirm',
          title: 'Are you sure about deleting this item?',
          content: "You won't be able to recover this item later",
          onYes: () => handleDeleteItem(item),
          onNo: hideDialog,
        });
        break;
      default:
        break;
    }
  };

  if (view === 'grid') {
    return (
      <JumboGridItem xs={12} md={6} lg={4}>
        <Card variant="outlined" elevation={0}>
          <CardHeader
            avatar={
              <Avatar
                sx={{ width: 48, height: 48 }}
                alt={item?.name}
                src={item?.profile_pic}
              />
            }
            action={
              <>
                {(isUserHasDeleteAccess || isUserHasEditAccess) && (
                  <JumboDdMenu
                    icon={<MoreHoriz />}
                    menuItems={[
                      isUserHasEditAccess && {
                        icon: <Edit />,
                        title: 'Edit',
                        action: 'edit',
                      },
                      isUserHasDeleteAccess && {
                        icon: <Delete />,
                        title: 'Delete',
                        action: 'delete',
                      },
                    ]}
                    onClickCallback={handleItemAction}
                  />
                )}
              </>
            }
            title={
              <Tooltip title="Item Name">
                <Typography variant={'h6'} color={'text.primary'} mb={0.25}>
                  {item.name}
                </Typography>
              </Tooltip>
            }
            subheader={
              <Tooltip title="Item Group">
                <Typography variant={'body1'} color={'text.secondary'}>
                  {item?.group}
                </Typography>
              </Tooltip>
            }
          />
          <CardContent sx={{ pt: 0 }}>
            <Divider sx={{ mb: 2 }} />
            <List disablePadding>
              <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                <ListItemIcon sx={{ minWidth: 50 }}>
                  <Book />
                </ListItemIcon>
                <ListItemText
                  primary={'Items Description'}
                  secondary={item?.description}
                />
              </ListItem>

              <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                <ListItemIcon sx={{ minWidth: 50 }}>
                  <PriceCheck />
                </ListItemIcon>
                <ListItemText primary={'Item Price'} secondary={item?.price} />
              </ListItem>

              <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                <ListItemIcon sx={{ minWidth: 50 }}>
                  <Inventory2 />
                </ListItemIcon>
                <ListItemText
                  primary={'Is Item Stockable?'}
                  secondary={item?.is_stockable ? 'Yes' : 'No'}
                />
              </ListItem>
              {item?.current_stock && (
                <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                  <ListItemIcon sx={{ minWidth: 50 }}>
                    <Inventory />
                  </ListItemIcon>
                  <ListItemText
                    primary={'Item Current Stock'}
                    secondary={item?.current_stock}
                  />
                </ListItem>
              )}
              <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                <ListItemIcon sx={{ minWidth: 50 }}>
                  <Inventory />
                </ListItemIcon>
                <ListItemText
                  primary={'Item Minimum Threshhold'}
                  secondary={item?.min_threshhold}
                />
              </ListItem>

              <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                <ListItemIcon sx={{ minWidth: 50 }}>
                  <Category />
                </ListItemIcon>
                <ListItemText
                  primary={'User Item Code'}
                  secondary={item?.user_item_code}
                />
              </ListItem>
              <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                <ListItemIcon sx={{ minWidth: 50 }}>
                  <Category />
                </ListItemIcon>
                <ListItemText
                  primary={'Item Status(Active)'}
                  secondary={item?.status ? 'Yes' : 'No'}
                />
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </JumboGridItem>
    );
  }
  return (
    <JumboListItem
      componentElement={'div'}
      itemData={item}
      secondaryAction={
        <>
          {(isUserHasDeleteAccess || isUserHasEditAccess) && (
            <JumboDdMenu
              icon={<MoreHoriz />}
              menuItems={[
                isUserHasEditAccess && {
                  icon: <Edit />,
                  title: 'Edit',
                  action: 'edit',
                },
                isUserHasDeleteAccess && {
                  icon: <Delete />,
                  title: 'Delete',
                  action: 'delete',
                },
              ]}
              onClickCallback={handleItemAction}
            />
          )}
        </>
      }
      sx={{
        cursor: 'pointer',
        borderTop: 1,
        borderColor: 'divider',
        '&:hover': {
          bgcolor: 'action.hover',
        },
      }}>
      <ListItemIcon sx={{ minWidth: 40 }}></ListItemIcon>
      <ListItemAvatar onClick={showContactDetail}>
        <Avatar alt={item?.name} src={item?.profile_pic} />
      </ListItemAvatar>
      <ListItemText
        onClick={showContactDetail}
        primary={
          <Typography variant={'body1'} component={'div'}>
            <Stack direction={'row'} alignItems={'center'} sx={{ minWidth: 0 }}>
              <Item
                sx={{
                  flexBasis: { xs: '100%', sm: '50%', md: '25%' },
                }}>
                <Tooltip title={'Item Name'}>
                  <Typography
                    variant={'h5'}
                    fontSize={14}
                    lineHeight={1.25}
                    mb={0}
                    noWrap>
                    {item.name}
                  </Typography>
                </Tooltip>
              </Item>

              <Item
                sx={{
                  flexBasis: { sm: '50%', md: '28%' },
                  display: { xs: 'none', sm: 'block' },
                }}>
                <Tooltip title={'Group'}>
                  <Typography variant={'body1'} noWrap>
                    {item?.group}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { sm: '50%', md: '28%' },
                  display: { xs: 'none', sm: 'block' },
                }}>
                <Tooltip title={'Price of Item'}>
                  <Typography variant={'body1'} noWrap>
                    {item.price}
                  </Typography>
                </Tooltip>
              </Item>

              <Item
                sx={{
                  flexBasis: { sm: '50%', md: '28%' },
                  display: { xs: 'none', sm: 'block' },
                }}>
                <Tooltip title={'Current Stock of Item'}>
                  <Typography variant={'body1'} noWrap>
                    {item.current_stock}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title={'Minimum Threshhold'}>
                  <Typography variant={'body1'} noWrap>
                    {item.min_threshhold}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { md: '22%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title={'User Item Code'}>
                  <Typography variant={'body1'} noWrap>
                    {item.user_item_code}
                  </Typography>
                </Tooltip>
              </Item>
            </Stack>
          </Typography>
        }
      />
    </JumboListItem>
  );
};

export default InventoryItem;
