import { useMemo, useCallback } from 'react';
import { Typography, Tooltip } from '@mui/material';
import List from '@mui/material/List';

import Div from '@jumbo/shared/Div';

const GenderSeparation = ({ data }) => {
  const calculateMales = useCallback(() => {
    return data?.filter(item => item.gender.toLowerCase().trim() === 'male')
      .length;
  }, [data]);

  const males = useMemo(() => calculateMales(data), [data, calculateMales]);

  return (
    <>
      <List
        sx={{
          height: '90%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}>
        <Tooltip title={'Males'}>
          <Div
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '90%',
              cursor: 'pointer',
              marginBottom: 2,
            }}>
            <Typography fontSize={30}>Males</Typography>
            <Typography fontWeight={'bold'} fontSize={40}>
              {males}
            </Typography>
          </Div>
        </Tooltip>
        <Tooltip title={'Females'}>
          <Div
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '90%',
              cursor: 'pointer',
            }}>
            <Typography fontSize={30}>Females</Typography>
            <Typography fontSize={40} fontWeight={'bold'}>
              {data.length - males}
            </Typography>
          </Div>
        </Tooltip>
      </List>
    </>
  );
};

export default GenderSeparation;
