import { TextField } from '@mui/material';

const Input = ({
  value = '',
  name = '',
  label = 'Outlined',
  variant = 'outlined',
  type = 'text',
  handleChange = () => console.log('Please Provide handleChange Method'),
  handleBlur,
  sx = { width: '100%' },
  error,
  helperText,
  minRows,
  multiline = false,
}) => {
  return (
    <TextField
      name={name}
      label={label}
      variant={variant}
      value={value}
      type={type}
      onChange={handleChange}
      onBlur={handleBlur}
      sx={sx}
      error={error}
      helperText={helperText}
      rows={minRows}
      multiline={multiline}
    />
  );
};

export default Input;
