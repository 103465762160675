import React from 'react';
import JumboNavSection from '@jumbo/components/JumboVerticalNavbar/JumboNavSection';
import JumboNavCollapsible from '@jumbo/components/JumboVerticalNavbar/JumboNavCollapsible';
import JumboNavItem from '@jumbo/components/JumboVerticalNavbar/JumboNavItem';

const NAV_VARIANTS = {
  section: JumboNavSection,
  collapsible: JumboNavCollapsible,
  'nav-item': JumboNavItem,
};

const JumboNavIdentifier = ({ item, isNested, translate }) => {
  const user = JSON.parse(
    localStorage.getItem('fotanistanUser')
      ? localStorage.getItem('fotanistanUser')
      : '{}',
  );
  const isAdmin = user && user.user_type === 'admin';
  const permissions = user?.permissions;

  if (
    item.type === 'section' &&
    item?.children?.length === 2 &&
    item?.children[0]?.key === 'home' &&
    user?.user_type !== 'admin'
  ) {
    return null;
  }

  if (isAdmin || !item?.key || permissions[item.key]?.listing) {
    const NavComponent = NAV_VARIANTS[item.type];
    return NavComponent ? (
      <NavComponent translate item={item} isNested={isNested} />
    ) : null;
  }

  return null;
};

JumboNavIdentifier.defaultProps = {
  isNested: false,
};

export default JumboNavIdentifier;
