import { useMemo } from 'react';
import { Grid, Typography, Tooltip } from '@mui/material';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import {
  calculateEarning,
  calculateExpenses,
  calculateNonExpenses,
} from '../../utils';
import Div from '@jumbo/shared/Div';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = props => {
  const {
    cx,
    cy,
    midAngle,
    outerRadius,
    payload: { payload },
    percent,
    innerRadius,
  } = props;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <Tooltip title={payload.name}>
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}% `}
      </text>
    </Tooltip>
  );
};
const MultipleBranchesComparisonChart = ({ data }) => {
  const expenses = useMemo(() => calculateExpenses(data), [data]);
  const earnings = useMemo(() => calculateEarning(data), [data]);
  const nonExpenses = useMemo(() => calculateNonExpenses(data), [data]);

  const dataToMap = [
    {
      label: 'Expenses',
      data: expenses,
    },
    {
      label: 'Non Expenses',
      data: nonExpenses,
    },
    {
      label: 'Earning',
      data: earnings,
    },
    {
      label: 'Cash In Hand',
      data: expenses,
    },
  ];

  return (
    <Grid container>
      {dataToMap?.map(({ label, data }, key) => (
        <Grid item xs={12} sm={6} md={3} key={key}>
          <ResponsiveContainer width="100%" height={250}>
            <PieChart>
              <Pie
                dataKey="value"
                data={data}
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={80}
                fill={'#1e88e5'}>
                {data.map((entry, index) => (
                  <Cell key={index} fill={entry.color} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
          <Typography
            sx={{ marginTop: -2, fontSize: '20px', textAlign: 'center' }}>
            {label}
          </Typography>
        </Grid>
      ))}
      <Div
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          marginTop: 1,
        }}>
        {expenses.map((item, key) => (
          <p key={key} style={{ marginRight: 10 }}>
            <span
              style={{
                backgroundColor: item.color,
                paddingLeft: 15,
                paddingTop: 0,
                marginRight: 5,
              }}>
              {' '}
            </span>
            {item.name}
          </p>
        ))}
      </Div>
    </Grid>
  );
};

export default MultipleBranchesComparisonChart;
