import { useState } from 'react';

import {
  TableCell,
  TableRow,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

import { titlalize } from 'app/utils/appHelpers';

const AttendanceRow = ({ student, handleAttendance }) => {
  const [value, setValue] = useState('');
  return (
    <TableRow
      key={student?.id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell component="th" scope="row">
        {titlalize(student?.first_name)} {titlalize(student?.last_name)}
      </TableCell>
      <TableCell align="right">{titlalize(student?.father_name)}</TableCell>
      <TableCell align="right">{student?.roll_no}</TableCell>
      <TableCell align="right">
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group">
            <FormControlLabel
              value="present"
              control={
                <Radio
                  checked={value === 'present'}
                  onChange={() => {
                    setValue('present');
                    handleAttendance(student, 'present');
                  }}
                  value={value}
                />
              }
              label="Present"
            />
            <FormControlLabel
              value="excused"
              control={
                <Radio
                  checked={value === 'excused'}
                  onChange={() => {
                    setValue('excused');
                    handleAttendance(student, 'excused');
                  }}
                  value={value}
                />
              }
              label="Excused"
            />
            <FormControlLabel
              value="absent"
              control={
                <Radio
                  checked={value === 'absent'}
                  onChange={() => {
                    setValue('absent');
                    handleAttendance(student, 'absent');
                  }}
                  value={value}
                />
              }
              label="Absent"
            />
            <FormControlLabel
              value="late"
              control={
                <Radio
                  checked={value === 'late'}
                  onChange={() => {
                    setValue('late');
                    handleAttendance(student, 'late');
                  }}
                  value={value}
                />
              }
              label="Late"
            />
          </RadioGroup>
        </FormControl>
      </TableCell>
    </TableRow>
  );
};

export default AttendanceRow;
