export const calculatePaidFee = students => {
  let paidFee = 0;

  students.map(student => {
    paidFee += student.paidFee;

    return null;
  });

  return paidFee;
};

export const calculatePayableFee = students =>
  students.reduce(
    (previousValue, currentValue) =>
      previousValue +
      (currentValue?.recommendedFee -
        currentValue?.registerFeeDiscount -
        currentValue?.paidFee),
    0,
  );

export const calculateDiscounts = students =>
  students.reduce(
    (previousValue, currentValue) =>
      previousValue + currentValue.registerFeeDiscount,
    0,
  );

export const studentsDropDownData = students => {
  const results = [];

  for (let i = 0; i < students.length; i++) {
    const obj = {};
    obj.id = i + 1;
    obj.label = `${students[i].firstName} ${students[i].lastName}`;
    obj.value = `${students[i].firstName} ${students[i].lastName}`;

    results.push(obj);
  }
  return results;
};
