import {
  GraphicEq,
  FormatListBulleted,
  SchoolOutlined,
  PeopleOutline,
  Inventory2Outlined,
  HowToRegOutlined,
  PointOfSaleOutlined,
  GroupsOutlined,
  AccountBalanceWalletOutlined,
  MenuBookOutlined,
  LockPerson,
} from '@mui/icons-material';

const user = JSON.parse(localStorage.getItem('fotanistanUser') || '{}');

const menus = [
  {
    label: 'sidebar.menu.home',
    type: 'section',
    children: [
      {
        uri: '/dashboards/analytics',
        label: 'Analytics',
        type: 'nav-item',
        key: 'home',
        icon: <GraphicEq sx={{ fontSize: 20 }} />,
      },
      {
        uri: '/dashboards/accounts',
        label: 'Accounts',
        type: 'nav-item',
        key: 'home',
        icon: <AccountBalanceWalletOutlined sx={{ fontSize: 20 }} />,
      },
    ],
  },
  {
    label: 'sidebar.menu.apps',
    type: 'section',
    children: [
      user?.user_type === 'admin' && {
        uri: '/app/roles',
        label: 'Roles & Permissions',
        type: 'nav-item',
        key: 'roles',
        icon: <LockPerson sx={{ fontSize: 20 }} />,
      },
      {
        uri: '/app/accounts/all',
        label: 'Accounts',
        type: 'nav-item',
        key: 'expense',
        icon: <AccountBalanceWalletOutlined sx={{ fontSize: 20 }} />,
      },
      user?.user_type === 'admin' && {
        uri: '/app/branches',
        label: 'Branches',
        type: 'nav-item',
        key: 'branch',
        icon: <FormatListBulleted sx={{ fontSize: 20 }} />,
      },
      {
        label: 'Examination',
        type: 'collapsible',
        icon: <MenuBookOutlined />,
        key: 'exam_initiator',
        children: [
          {
            uri: '/app/exam-init/all',
            label: 'Initiator',
            type: 'nav-item',
          },
          {
            uri: '/app/exam-follow/all',
            label: 'FollowUp',
            type: 'nav-item',
          },
        ],
      },

      {
        uri: '/app/classes/all',
        label: 'Classes',
        type: 'nav-item',
        key: 'class',
        icon: <SchoolOutlined sx={{ fontSize: 20 }} />,
      },
      {
        label: 'Subjects',
        type: 'nav-item',
        uri: '/app/subjects',
        key: 'subject',
        icon: <GroupsOutlined sx={{ fontSize: 20 }} />,
      },
      {
        uri: '/app/users/all',
        label: 'Users',
        type: 'nav-item',
        key: 'user',
        icon: <PeopleOutline sx={{ fontSize: 20 }} />,
      },
      {
        uri: '/app/students/all',
        label: 'Students',
        type: 'nav-item',
        key: 'student',
        icon: <GroupsOutlined sx={{ fontSize: 20 }} />,
      },
      {
        uri: '/app/inventory',
        label: 'Inventory',
        type: 'nav-item',
        key: 'item',
        icon: <Inventory2Outlined sx={{ fontSize: 20 }} />,
      },
      {
        uri: '/app/fees/all',
        label: 'Fees',
        type: 'nav-item',
        key: 'fees',
        icon: <PointOfSaleOutlined sx={{ fontSize: 20 }} />,
      },
      {
        uri: '/app/nonExpensePayments',
        label: 'Non Expense Payments',
        type: 'nav-item',
        key: 'non_expense_payment',
        icon: <PointOfSaleOutlined sx={{ fontSize: 20 }} />,
      },
      {
        uri: '/app/attendance',
        label: 'Attendance',
        type: 'nav-item',
        key: 'attendance',
        icon: <HowToRegOutlined sx={{ fontSize: 20 }} />,
      },
    ],
  },
];

export default menus;
