import {
  ListItem,
  ListItemText,
  Typography,
  Avatar,
  IconButton,
  CardHeader,
  List,
  ListItemAvatar,
  alpha,
  Divider,
} from '@mui/material';

import {
  FamilyRestroom,
  Close,
  Done,
  Description,
  MonetizationOn,
} from '@mui/icons-material';

import Div from '@jumbo/shared/Div';

const ExpenseDetails = ({ item, onClose }) => {
  return (
    <Div sx={{ m: theme => theme.spacing(-2.5, -3) }}>
      <CardHeader
        title={`Non Expense Payment`}
        subheader={'Details'}
        avatar={
          <Avatar
            sx={{ width: 48, height: 48 }}
            alt={item?.firstName}
            src={item?.paid_to}
          />
        }
        action={
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        }
      />
      <List disablePadding>
        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
              }}>
              <FamilyRestroom sx={{ color: 'primary.light' }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                Paid By
              </Typography>
            }
            secondary={
              <Typography variant={'body2'} mb={0}>
                {item?.paid_by?.username}
              </Typography>
            }
          />
        </ListItem>
        <Divider component={'li'} />

        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
              }}>
              <MonetizationOn sx={{ color: 'primary.light' }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                Payment Mode
              </Typography>
            }
            secondary={
              <Typography variant={'body2'} mb={0}>
                {`${item?.payment_mode
                  ?.charAt(0)
                  .toUpperCase()}${item?.payment_mode?.slice(1)}`}
              </Typography>
            }
          />
        </ListItem>
        <Divider component={'li'} />

        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
              }}>
              <MonetizationOn sx={{ color: 'primary.light' }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                Payment Mode
              </Typography>
            }
            secondary={
              <Typography variant={'body2'} mb={0}>
                Rs. {item?.paid_amount}
              </Typography>
            }
          />
        </ListItem>
        <Divider component={'li'} />

        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
              }}>
              <Description sx={{ color: 'primary.light' }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                Description
              </Typography>
            }
            secondary={
              <Typography variant={'body2'} mb={0}>
                {item?.description}
              </Typography>
            }
          />
        </ListItem>
        <Divider component={'li'} />

        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
              }}>
              {item?.is_approved ? <Done /> : <Close />}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                Payment Status
              </Typography>
            }
            secondary={
              <Typography variant={'body2'} mb={0}>
                {item?.is_approved ? 'Approved' : 'Not Approved'}
              </Typography>
            }
          />
        </ListItem>
        <Divider component={'li'} />
      </List>
    </Div>
  );
};

export default ExpenseDetails;
