import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import Div from '@jumbo/shared/Div';

const RolesSidebar = () => {
  const navigate = useNavigate();

  return (
    <>
      <Div
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: 3,
        }}>
        <Div sx={{ flex: '1 1 auto' }}>
          <Typography variant={'h2'} mb={0.35}>
            Roles & Permissions Management
          </Typography>
        </Div>
      </Div>

      <Button
        fullWidth
        disableElevation
        variant={'contained'}
        startIcon={<PersonAddIcon />}
        sx={{
          mb: 2,
          '& .MuiSvgIcon-root': {
            fontSize: '1.5rem',
          },
        }}
        onClick={() => navigate('/app/roles/form')}>
        Add New Role
      </Button>
    </>
  );
};
export default memo(RolesSidebar);
