import { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import { Card, ButtonGroup, Button } from '@mui/material';
import { List, ViewComfy } from '@mui/icons-material';

import JumboRqList from '@jumbo/components/JumboReactQuery/JumboRqList';
import JumboListToolbar from '@jumbo/components/JumboList/components/JumboListToolbar';
import JumboSearch from '@jumbo/components/JumboSearch';

import ExamItem from './Item';

const ExamInitiatorList = () => {
  const { category } = useParams();
  const listRef = useRef();
  const storeExamFollowUps = useSelector(
    state => state.examination.examFollowUps,
  );

  const [view, setView] = useState('list');
  const [examFollowUps, setExamFollowUps] = useState(storeExamFollowUps);

  useEffect(() => {
    setExamFollowUps(storeExamFollowUps);
  }, [storeExamFollowUps]);

  useEffect(() => {
    if (category === 'all') {
      setExamFollowUps(storeExamFollowUps);
    } else {
      const filtered = storeExamFollowUps?.filter(item => {
        const branchName = item.branch.name;
        if (category === branchName?.toLowerCase()) {
          return item;
        }
        return null;
      });
      setExamFollowUps(filtered);
    }
  }, [category, storeExamFollowUps]);

  const renderData = useCallback(
    item => {
      return <ExamItem item={item} view={view} />;
    },
    [view],
  );

  const handleOnChange = useCallback(
    keyword => {
      let filtered = [];
      filtered = storeExamFollowUps?.filter(item => {
        return (
          item?.exam_hall?.toLowerCase().search(keyword?.toLowerCase()) !== -1
        );
      });
      setExamFollowUps(filtered);
    },
    [storeExamFollowUps],
  );

  return (
    <JumboRqList
      ref={listRef}
      data={examFollowUps}
      wrapperComponent={Card}
      primaryKey={'id'}
      itemsPerPage={8}
      itemsPerPageOptions={[8, 15, 20]}
      renderItem={renderData}
      componentElement={'div'}
      sx={view === 'grid' && { p: theme => theme.spacing(1, 3, 3) }}
      wrapperSx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
      toolbar={
        <JumboListToolbar
          hideItemsPerPage={true}
          actionTail={
            <ButtonGroup
              variant="outlined"
              disableElevation
              sx={{
                '& .MuiButton-root': {
                  px: 1,
                },
              }}>
              <Button
                variant={view === 'list' ? 'contained' : 'outlined'}
                onClick={() => setView('list')}>
                <List />
              </Button>
              <Button
                variant={view === 'grid' ? 'contained' : 'outlined'}
                onClick={() => setView('grid')}>
                <ViewComfy />
              </Button>
            </ButtonGroup>
          }>
          <JumboSearch
            onChange={handleOnChange}
            sx={{
              display: { xs: 'none', md: 'block' },
            }}
          />
        </JumboListToolbar>
      }
      view={view}
    />
  );
};

export default ExamInitiatorList;
