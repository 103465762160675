import { useMemo, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import Card from 'app/pages/dashboards/accounts/components/BitcoinPrice';
import FeesSidebar from './components/FeesSidebar';
import FeeList from './components/FeeList';

import {
  calculateDiscounts,
  calculatePaidFee,
  calculatePayableFee,
} from './utils/calculations';

const Students = () => {
  const { category } = useParams();
  const students = useSelector(state => state.fees.students);
  const [filteredData, setFilteredData] = useState(students);
  const paidFee = useMemo(() => calculatePaidFee(filteredData), [filteredData]);
  const discounts = useMemo(
    () => calculateDiscounts(filteredData),
    [filteredData],
  );
  const payableFee = useMemo(
    () => calculatePayableFee(filteredData),
    [filteredData],
  );

  useEffect(() => {
    if (category === 'all') {
      setFilteredData(students);
    } else {
      const filteredStatus = students?.filter(
        single => single.status.toLowerCase() === category,
      );
      setFilteredData(filteredStatus);
    }
  }, [category, students]);

  return (
    <>
      {/* <Grid container spacing={1} sx={{ marginBottom: 5 }}>
        <Grid item xs={12} sm={6} lg={3}>
          <Card
            title={payableFee}
            subHeader={'Payable Fee'}
            growth={payableFee}
            tooltip={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Card
            title={paidFee}
            subHeader={'Total Paid Fee'}
            growth={paidFee}
            bgColor={'#ff6f60'}
            graphStartColor={'#e53935'}
            graphEndColor={'#e53935'}
            tooltip={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Card
            title={discounts}
            subHeader={'Total Discounts'}
            growth={discounts}
            bgColor={'#A53860'}
            graphStartColor={'#00ada9'}
            graphEndColor={'#00ada9'}
            tooltip={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Card
            title={payableFee - paidFee}
            subHeader={'Remaining Fee'}
            growth={'30%'}
            bgColor={'#4B4453'}
            graphStartColor={'#42a5f5'}
            graphEndColor={'#2196f3'}
            tooltip={false}
          />
        </Grid>
      </Grid> */}
      <JumboContentLayout sidebar={<FeesSidebar />}>
        <FeeList />
      </JumboContentLayout>
    </>
  );
};
export default Students;
