import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  API_DELETE,
  API_GET,
  API_POST,
  API_PUT,
} from 'app/utils/constants/api/apiRequests';
import { setLoading, setLoadingFinished } from './loadingSlice';
import { showAlert } from './alertSlice';

const initialState = {
  inActiveReasons: [],
  isDataFetched: false,
};

export const getAllReasons = createAsyncThunk(
  'inActiveReasons/getAllReasons',
  async (_, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data } = await API_GET('/student-inactivity-reason');

      if (status) {
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const addNewInActiveReason = createAsyncThunk(
  'inActiveReasons/addNewInActiveReason',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, message } = await API_POST(
        '/student-inactivity-reason',
        payload,
      );
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllReasons());
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const editInactiveReason = createAsyncThunk(
  'inActiveReasons/editInactiveReason',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    const dataToSend = { name: payload.name, status: payload.status };
    try {
      const { status, data, message } = await API_PUT(
        '/student-inactivity-reason',
        payload.id,
        dataToSend,
      );
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllReasons());
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const deleteInActiveReason = createAsyncThunk(
  'inActiveReasons/deleteInActiveReason',
  async (id, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_DELETE(
        '/student-inactivity-reason',
        id,
      );
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllReasons());
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const inActiveReasonsSlice = createSlice({
  name: 'inActiveReasons',
  initialState,

  extraReducers: builder => {
    builder.addCase(getAllReasons.fulfilled, (state, { payload }) => {
      state.inActiveReasons = payload;
      state.isDataFetched = true;
    });
  },
});

export default inActiveReasonsSlice.reducer;
