import { useSelector } from 'react-redux';
import { Stack, Typography, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

import { calculateEachBranchRevenue } from '../../utils';

export const branchesData = [
  {
    name: 'Kalaskay',
    amount: '48,548',
  },
  {
    name: 'Verpal',
    amount: '6,478',
  },
  {
    name: 'Dogranwala',
    amount: '210,213',
  },
  {
    name: 'Delha Chattha',
    amount: '6,478',
  },
  {
    name: 'Collected Fees',
    amount: '8,543',
  },
  {
    name: 'Remaining Fees',
    amount: '9,343',
  },
  {
    name: 'Total Fees',
    amount: '692,874',
  },
];

const Item = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  width: '14%',
  marginBottom: 5,
  [theme.breakpoints.down('lg')]: {
    width: '15%',
  },
  [theme.breakpoints.down('md')]: {
    width: '33.3%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '50%',
  },
  padding: theme.spacing(0, 5),
}));

const RenderSalesData = () => {
  const { branches } = useSelector(state => state.accounts.accounts);
  const results = calculateEachBranchRevenue(branches);
  return (
    <Stack
      direction={'row'}
      flexWrap={'wrap'}
      sx={{ p: theme => theme.spacing(0, 1, 2) }}>
      {results?.map((item, key) => (
        <Item key={key}>
          <Tooltip title="Deposits - Expenses" color="rgb(59, 210, 162)">
            <Typography variant={'body1'} color={'common.white'} mb={1}>
              {'PKR. '}
              {item.amount}
            </Typography>
          </Tooltip>
          <Typography variant={'h6'} color={'common.white'}>
            {item.name}
          </Typography>
        </Item>
      ))}
    </Stack>
  );
};
export default RenderSalesData;
