import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import AccountsSidebar from './components/AccountsSidebar';
import ExpensesList from './components/ExpensesList';
import { useSelector } from 'react-redux';

const Accounts = () => {
  const user = useSelector(state => state.auth.user);

  return (
    <>
      <JumboContentLayout sidebar={<AccountsSidebar />}>
        <ExpensesList />
      </JumboContentLayout>
    </>
  );
};
export default Accounts;
