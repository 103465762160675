import React from 'react';
import { useDispatch } from 'react-redux';

import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemAvatar,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Tooltip,
  Typography,
  Avatar,
  Stack,
  Grid,
} from '@mui/material';
import {
  Person,
  MoreHoriz,
  Edit,
  Delete,
  AcUnit,
  Money,
  FilterTiltShift,
  InsertInvitation,
  Book,
  FollowTheSigns,
  Feedback,
  Message,
  ViewAgenda,
} from '@mui/icons-material';
import styled from '@emotion/styled';

import JumboDdMenu from '@jumbo/components/JumboDdMenu';
import Span from '@jumbo/shared/Span';
import JumboListItem from '@jumbo/components/JumboList/components/JumboListItem';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import JumboGridItem from '@jumbo/components/JumboList/components/JumboGridItem';

import ItemDetails from '../ItemDetails';
import { useNavigate } from 'react-router-dom';
import { deleteInitiator } from 'app/store/features/examinationSlice';

const Item = styled(Span)(({ theme }) => ({
  minWidth: 0,
  flexGrow: 0,
  padding: theme.spacing(0, 1),
}));

const ClassItem = ({ item, view }) => {
  const { showDialog, hideDialog } = useJumboDialog();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const showContactDetail = React.useCallback(() => {
    showDialog({
      content: <ItemDetails item={item} onClose={hideDialog} />,
    });
  }, [showDialog, item, hideDialog]);

  const handleDelete = data => {
    dispatch(deleteInitiator(data.id));
    hideDialog();
  };

  const handleItemAction = menuItem => {
    switch (menuItem.action) {
      case 'view':
        showContactDetail();
        break;
      case 'edit':
        navigate('/app/examination/initiator', { state: item });
        break;
      case 'delete':
        showDialog({
          variant: 'confirm',
          title: 'Are you sure about deleting this Exam Initiator?',
          content: "You won't be able to recover this Exam Initiator later",
          onYes: () => handleDelete(item),
          onNo: hideDialog,
        });
        break;
      default:
        break;
    }
  };

  if (view === 'grid') {
    return (
      <JumboGridItem xs={12} md={6}>
        <Card variant="outlined" elevation={0}>
          <CardHeader
            avatar={
              <Avatar
                sx={{ width: 48, height: 48 }}
                alt={item?.branch?.name}
                src={item?.profile_pic}
              />
            }
            action={
              <>
                <JumboDdMenu
                  icon={<MoreHoriz />}
                  menuItems={[
                    { icon: <Edit />, title: 'Edit', action: 'edit' },
                    { icon: <Delete />, title: 'Delete', action: 'delete' },
                  ]}
                  onClickCallback={handleItemAction}
                />
              </>
            }
            title={
              <Tooltip title="Branch">
                <Typography variant={'h6'} color={'text.primary'} mb={0.25}>
                  {item?.branch?.name}
                </Typography>
              </Tooltip>
            }
            subheader={
              <Tooltip title="Final Reviewer">
                <Typography variant={'body1'} color={'text.secondary'}>
                  {item?.al_final_reviewer?.username}
                </Typography>
              </Tooltip>
            }
          />
          <CardContent sx={{ pt: 0 }}>
            <Divider sx={{ mb: 2 }} />
            <Grid container>
              <Grid item xs={12} sm={8}>
                <List disablePadding>
                  {/* date sheet */}
                  <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                    <ListItemIcon sx={{ minWidth: 50 }}>
                      <InsertInvitation />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Tooltip title="Date Sheet Date">
                          <Typography variant={'body1'} color={'text.primary'}>
                            {item?.date_sheet_date}
                          </Typography>
                        </Tooltip>
                      }
                    />
                  </ListItem>

                  {/* exam dates */}
                  <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                    <ListItemIcon sx={{ minWidth: 50 }}>
                      <InsertInvitation />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Tooltip title="Exam Start Date">
                          <Typography variant={'body1'} color={'text.primary'}>
                            {item?.exam_start_date}
                          </Typography>
                        </Tooltip>
                      }
                    />
                  </ListItem>

                  <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                    <ListItemIcon sx={{ minWidth: 50 }}>
                      <InsertInvitation />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Tooltip title="Exam Result Date">
                          <Typography variant={'body1'}>
                            {item?.exam_result_date}
                          </Typography>
                        </Tooltip>
                      }
                    />
                  </ListItem>

                  {/* exam types/terms */}
                  <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                    <ListItemIcon sx={{ minWidth: 50 }}>
                      <Money />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Tooltip title="Exam Type">
                          <Typography variant={'body1'} color={'text.primary'}>
                            {item?.exam_type}
                          </Typography>
                        </Tooltip>
                      }
                    />
                  </ListItem>

                  <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                    <ListItemIcon sx={{ minWidth: 50 }}>
                      <Book />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Tooltip title="Exam Term">
                          <Typography variant={'body1'}>
                            {item?.exam_term}
                          </Typography>
                        </Tooltip>
                      }
                    />
                  </ListItem>

                  {/* users */}
                  <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                    <ListItemIcon sx={{ minWidth: 50 }}>
                      <Person />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Tooltip title="Date Sheet Initiator">
                          <Typography variant={'body1'}>
                            {item?.date_sheet_initiator?.username}
                          </Typography>
                        </Tooltip>
                      }
                    />
                  </ListItem>

                  <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                    <ListItemIcon sx={{ minWidth: 50 }}>
                      <Person />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Tooltip title="Head Examiner">
                          <Typography variant={'body1'}>
                            {item?.head_examiner?.username}
                          </Typography>
                        </Tooltip>
                      }
                    />
                  </ListItem>

                  <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                    <ListItemIcon sx={{ minWidth: 50 }}>
                      <Person />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Tooltip title="Remarks Final Reviewer">
                          <Typography variant={'body1'}>
                            {item?.remarks_final_reviewer?.username}
                          </Typography>
                        </Tooltip>
                      }
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={4}>
                {/* is_freeze */}
                <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                  <ListItemIcon sx={{ minWidth: 50 }}>
                    <AcUnit />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Tooltip title="Is Exam Freezed?">
                        <Typography variant={'body1'}>
                          {item?.is_freeze ? 'Yes' : 'No'}
                        </Typography>
                      </Tooltip>
                    }
                  />
                </ListItem>
                {/* is_publish */}
                <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                  <ListItemIcon sx={{ minWidth: 50 }}>
                    <Book />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Tooltip title="Is Exam Published?">
                        <Typography variant={'body1'}>
                          {item?.is_publish ? 'Yes' : 'No'}
                        </Typography>
                      </Tooltip>
                    }
                  />
                </ListItem>
                {/* is_remarks */}
                <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                  <ListItemIcon sx={{ minWidth: 50 }}>
                    <Feedback />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Tooltip title="Is Exam Remarks Available?">
                        <Typography variant={'body1'}>
                          {item?.is_remarks ? 'Yes' : 'No'}
                        </Typography>
                      </Tooltip>
                    }
                  />
                </ListItem>
                {/* sms_flag */}
                <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                  <ListItemIcon sx={{ minWidth: 50 }}>
                    <Message />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Tooltip title="Is SMS sent?">
                        <Typography variant={'body1'}>
                          {item?.sms_flag ? 'Yes' : 'No'}
                        </Typography>
                      </Tooltip>
                    }
                  />
                </ListItem>
                {/* test_flag */}
                <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                  <ListItemIcon sx={{ minWidth: 50 }}>
                    <FollowTheSigns />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Tooltip title="Is test flag?">
                        <Typography variant={'body1'}>
                          {item?.test_flag ? 'Yes' : 'No'}
                        </Typography>
                      </Tooltip>
                    }
                  />
                </ListItem>
                {/* shift */}
                <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                  <ListItemIcon sx={{ minWidth: 50 }}>
                    <FilterTiltShift />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Tooltip title="Shift">
                        <Typography variant={'body1'}>
                          {item?.shift?.charAt(0).toUpperCase() +
                            item?.shift?.slice(1)}
                        </Typography>
                      </Tooltip>
                    }
                  />
                </ListItem>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </JumboGridItem>
    );
  }
  return (
    <JumboListItem
      componentElement={'div'}
      itemData={item}
      secondaryAction={
        <JumboDdMenu
          icon={<MoreHoriz />}
          menuItems={[
            { icon: <ViewAgenda />, title: 'View', action: 'view' },
            { icon: <Edit />, title: 'Edit', action: 'edit' },
            { icon: <Delete />, title: 'Delete', action: 'delete' },
          ]}
          onClickCallback={handleItemAction}
        />
      }
      sx={{
        cursor: 'pointer',
        borderTop: 1,
        borderColor: 'divider',
        '&:hover': {
          bgcolor: 'action.hover',
        },
      }}>
      <ListItemAvatar onClick={showContactDetail}>
        <Avatar alt={item?.class_section_title} src={item?.profile_pic} />
      </ListItemAvatar>
      <ListItemText
        onClick={showContactDetail}
        primary={
          <Typography variant={'body1'} component={'div'}>
            <Stack direction={'row'} alignItems={'center'} sx={{ minWidth: 0 }}>
              <Item
                sx={{
                  flexBasis: { xs: '100%', sm: '50%', md: '25%' },
                }}>
                <Tooltip title="Branch Name">
                  <Typography
                    variant={'h5'}
                    fontSize={14}
                    lineHeight={1.25}
                    mb={0}
                    noWrap>
                    {item?.branch?.name}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { sm: '50%', md: '28%' },
                  display: { xs: 'none', sm: 'block' },
                }}>
                <Tooltip title={'Date Sheet Date'}>
                  <Typography variant={'body1'} noWrap>
                    {item?.date_sheet_date}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { sm: '50%', md: '28%' },
                  display: { xs: 'none', sm: 'block' },
                }}>
                <Tooltip title={'Exam Start Date'}>
                  <Typography variant={'body1'} noWrap>
                    {item?.exam_start_date}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { sm: '50%', md: '28%' },
                  display: { xs: 'none', sm: 'block' },
                }}>
                <Tooltip title={'Shift'}>
                  <Typography variant={'body1'} noWrap>
                    {item?.shift?.charAt(0)?.toUpperCase() +
                      item?.shift?.slice(1)}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title="Head Examiner">
                  <Typography variant={'body1'} noWrap>
                    {item?.head_examiner?.username}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title="Exam Type">
                  <Typography variant={'body1'} noWrap>
                    {item?.exam_type}
                  </Typography>
                </Tooltip>
              </Item>

              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title={'Exam Term'}>
                  <Typography variant={'body1'} noWrap>
                    {item?.exam_term}
                  </Typography>
                </Tooltip>
              </Item>
            </Stack>
          </Typography>
        }
      />
    </JumboListItem>
  );
};

export default ClassItem;
