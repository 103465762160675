import React from 'react';

import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
  Avatar,
  Stack,
  Tooltip,
} from '@mui/material';
import {
  MoreHoriz,
  Edit,
  FamilyRestroom,
  MonetizationOn,
  CalendarMonth,
  Delete,
} from '@mui/icons-material';

import styled from '@emotion/styled';

import JumboDdMenu from '@jumbo/components/JumboDdMenu';
import Span from '@jumbo/shared/Span';
import Div from '@jumbo/shared/Div';
import JumboListItem from '@jumbo/components/JumboList/components/JumboListItem';
import JumboChipsGroup from '@jumbo/components/JumboChipsGroup';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import JumboGridItem from '@jumbo/components/JumboList/components/JumboGridItem';

import FeeForm from '../FeeForm';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { deleteFee } from 'app/store/features/feeSlice';

const Item = styled(Span)(({ theme }) => ({
  minWidth: 0,
  flexGrow: 0,
  padding: theme.spacing(0, 1),
}));

const StudentItem = ({ fee, view }) => {
  const { showDialog } = useJumboDialog();
  const dispatch = useDispatch();

  const studentStatus =
    fee?.status.charAt(0).toUpperCase() + fee?.status?.slice(1);
  let color = '';
  fee?.status === 'defaulter'
    ? (color = '#E44A77')
    : fee?.status === 'cleared'
    ? (color = '#3BD2A2')
    : (color = '#F39711');
  const label = [{ label: studentStatus, color }];

  const handleDelete = () => {
    dispatch(deleteFee(fee.id));
  };

  const handleItemAction = menuItem => {
    switch (menuItem.action) {
      case 'edit':
        showDialog({
          title: 'Update Fee detail',
          content: <FeeForm fee={fee} />,
        });
        break;
      case 'delete':
        handleDelete();
        break;
      default:
        break;
    }
  };

  if (view === 'grid') {
    return (
      <JumboGridItem xs={12} md={6} lg={4}>
        <Card
          variant="outlined"
          elevation={0}
          sx={{ cursor: 'pointer' }}
          onClick={() => {}}>
          <CardHeader
            avatar={
              <Avatar
                sx={{ width: 48, height: 48 }}
                alt={fee?.firstName}
                src={fee?.profile_pic}
              />
            }
            action={
              <>
                <JumboDdMenu
                  icon={<MoreHoriz />}
                  menuItems={[
                    { icon: <Edit />, title: 'Update', action: 'edit' },
                    { icon: <Delete />, title: 'Delete', action: 'delete' },
                  ]}
                  onClickCallback={handleItemAction}
                />
              </>
            }
            title={
              <Typography variant={'h6'} color={'text.primary'} mb={0.25}>
                {`${fee?.student?.first_name} ${fee?.student?.last_name}`}
              </Typography>
            }
            subheader={
              <Span>
                <Tooltip title="Fee Submitted At">
                  <Typography variant={'body1'} color={'text.secondary'}>
                    {moment(fee?.createdAt).format('LLLL')}
                  </Typography>
                </Tooltip>
              </Span>
            }
          />
          <CardContent sx={{ pt: 0 }}>
            <Divider sx={{ mb: 2 }} />
            <List disablePadding>
              <ListItem
                sx={{
                  p: theme => theme.spacing(0.75, 1.5),
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Span sx={{ display: 'flex' }}>
                  <ListItemIcon sx={{ minWidth: 50 }}>
                    <FamilyRestroom />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Student Parent's Information"}
                    secondary={
                      <Span>
                        <Typography variant="body2">
                          Father Name: {fee?.student?.father_name}
                        </Typography>
                        <Typography variant="body2">
                          Phone Number: {fee?.student?.father_mobile_number}
                        </Typography>
                      </Span>
                    }
                  />
                </Span>
              </ListItem>

              <ListItem
                sx={{
                  p: theme => theme.spacing(0.75, 1.5),
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Span sx={{ display: 'flex' }}>
                  <ListItemIcon sx={{ minWidth: 50 }}>
                    <CalendarMonth />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Student Parent's Information"}
                    secondary={
                      <Span>
                        <Typography variant="body2">
                          Month: {fee?.month}
                        </Typography>
                        <Typography variant="body2">
                          Year: {fee?.year}
                        </Typography>
                      </Span>
                    }
                  />
                </Span>
              </ListItem>

              <ListItem
                sx={{
                  p: theme => theme.spacing(0.75, 1.5),
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Span sx={{ display: 'flex' }}>
                  <ListItemIcon sx={{ minWidth: 50 }}>
                    <MonetizationOn />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Tooltip title="Total Submitted Fee">
                        <Typography variant="body2">
                          Total Submitted Fee
                        </Typography>
                      </Tooltip>
                    }
                    secondary={
                      <Span>
                        <Tooltip title="Recommended Fee">
                          <Typography variant="body2">
                            Rs. {fee?.total}
                          </Typography>
                        </Tooltip>
                      </Span>
                    }
                  />
                </Span>
              </ListItem>
            </List>
            <Divider sx={{ my: 2 }} />
            <Div
              sx={{
                display: 'flex',
                minWidth: 0,
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              {label.length === 0 ? null : (
                <JumboChipsGroup
                  chips={label}
                  mapKeys={{ label: 'name' }}
                  spacing={1}
                  size={'medium'}
                  max={3}
                />
              )}
            </Div>
          </CardContent>
        </Card>
      </JumboGridItem>
    );
  }
  return (
    <JumboListItem
      componentElement={'div'}
      itemData={fee}
      secondaryAction={
        <JumboDdMenu
          icon={<MoreHoriz />}
          menuItems={[
            { icon: <Edit />, title: 'Update', action: 'edit' },
            { icon: <Delete />, title: 'Delete', action: 'delete' },
          ]}
          onClickCallback={handleItemAction}
        />
      }
      sx={{
        cursor: 'pointer',
        borderTop: 1,
        borderColor: 'divider',
        '&:hover': {
          bgcolor: 'action.hover',
        },
      }}>
      <ListItemText
        onClick={() => {}}
        primary={
          <Typography variant={'body1'} component={'div'}>
            <Stack direction={'row'} alignItems={'center'} sx={{ minWidth: 0 }}>
              <Item
                sx={{
                  flexBasis: { xs: '100%', sm: '50%', md: '25%' },
                }}>
                <Tooltip title="Name">
                  <Typography
                    variant={'h5'}
                    fontSize={14}
                    lineHeight={1.25}
                    mb={0}
                    noWrap>
                    {`${fee?.student?.first_name} ${fee?.student?.last_name}`}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { sm: '50%', md: '28%' },
                  display: { xs: 'none', sm: 'block' },
                }}>
                <Tooltip title="Father Name">
                  <Typography variant={'body1'} noWrap>
                    {fee?.student?.father_name}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title="Fee Submitted At">
                  <Typography variant={'body1'} noWrap>
                    {moment(fee?.createdAt).format('YYYY-MM-DD')}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title="Month">
                  <Typography variant={'body1'} noWrap>
                    {fee?.month}
                  </Typography>
                </Tooltip>
              </Item>

              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title="Year">
                  <Typography variant={'body1'} noWrap>
                    {fee?.year}
                  </Typography>
                </Tooltip>
              </Item>

              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title="Paid Fee">
                  <Typography variant={'body1'} noWrap>
                    Rs. {fee?.total}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { md: '22%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title={'Status of Student'}>
                  <JumboChipsGroup
                    chips={label}
                    mapKeys={{ label: 'name' }}
                    spacing={1}
                    size={'small'}
                    max={1}
                  />
                </Tooltip>
              </Item>
            </Stack>
          </Typography>
        }
      />
    </JumboListItem>
  );
};

export default StudentItem;
