import React, { useCallback } from 'react';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import Div from '@jumbo/shared/Div';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import BranchesList from 'app/shared/components/BranchesList';
import UserForm from '../UserForm';
import { useDispatch, useSelector } from 'react-redux';
import { openUserModal } from 'app/store/features/usersSlice';

const UsersSidebar = ({ isUserHasAddPermission }) => {
  const { showDialog } = useJumboDialog();
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);

  const showAddStudentDialog = useCallback(() => {
    dispatch(openUserModal());
    showDialog({
      title: 'Add New User',
      content: <UserForm />,
    });
  }, [dispatch, showDialog]);

  return (
    <>
      <Div
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: 3,
        }}>
        <Div sx={{ flex: '1 1 auto' }}>
          <Typography variant={'h2'} mb={0.35}>
            Users
          </Typography>
        </Div>
      </Div>
      {(user?.user_type === 'admin' || isUserHasAddPermission) && (
        <Button
          fullWidth
          disableElevation
          variant={'contained'}
          startIcon={<PersonAddIcon />}
          sx={{
            mb: 2,
            '& .MuiSvgIcon-root': {
              fontSize: '1.5rem',
            },
          }}
          onClick={showAddStudentDialog}>
          Add New User
        </Button>
      )}
      <BranchesList title="All Users" identifier="users" />
    </>
  );
};
export default React.memo(UsersSidebar);
