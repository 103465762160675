import React from 'react';

import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
  Avatar,
  Stack,
  Tooltip,
} from '@mui/material';
import {
  MoreHoriz,
  Edit,
  FamilyRestroom,
  Visibility,
  MonetizationOn,
  Description,
  Done,
  Close,
  Delete,
} from '@mui/icons-material';

import styled from '@emotion/styled';

import JumboDdMenu from '@jumbo/components/JumboDdMenu';
import Span from '@jumbo/shared/Span';
import JumboListItem from '@jumbo/components/JumboList/components/JumboListItem';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import JumboGridItem from '@jumbo/components/JumboList/components/JumboGridItem';

import NonExpensePaymentForm from '../NonExpensePaymentForm';
import ExpenseDetails from '../StudentDetail';
import { useDispatch, useSelector } from 'react-redux';
import { deleteNonExpensePayment } from 'app/store/features/nonExpenseSlice';

const Item = styled(Span)(({ theme }) => ({
  minWidth: 0,
  flexGrow: 0,
  padding: theme.spacing(0, 1),
}));

const ExpenseItem = ({ item, view }) => {
  const { showDialog, hideDialog } = useJumboDialog();
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);

  const isUserHasEditAccess =
    user?.user_type === 'admin' ||
    user?.permissions['non-expense-payment']?.edit;
  const isUserHasDeleteAccess =
    user?.user_type === 'admin' ||
    user?.permissions['non-expense-payment']?.delete;
  const isUserHasListingAccess =
    user?.user_type === 'admin' ||
    user?.permissions['non-expense-payment']?.listing;

  const showExpenseDetails = React.useCallback(() => {
    showDialog({
      content: <ExpenseDetails item={item} onClose={hideDialog} />,
    });
  }, [showDialog, item, hideDialog]);

  const handleDelete = () => {
    dispatch(deleteNonExpensePayment(item.id));
  };

  const handleItemAction = menuItem => {
    switch (menuItem.action) {
      case 'edit':
        showDialog({
          title: 'Update Payment details',
          content: <NonExpensePaymentForm item={item} />,
        });
        break;
      case 'view':
        showExpenseDetails();
        break;
      case 'delete':
        handleDelete();
        break;
      default:
        break;
    }
  };

  if (view === 'grid') {
    return (
      <JumboGridItem xs={12} md={6} lg={4}>
        <Card
          variant="outlined"
          elevation={0}
          sx={{ cursor: 'pointer' }}
          onClick={showExpenseDetails}>
          <CardHeader
            avatar={
              <Avatar
                sx={{ width: 48, height: 48 }}
                alt={item?.firstName}
                src={item?.paid_to}
              />
            }
            action={
              <>
                {(isUserHasDeleteAccess ||
                  isUserHasEditAccess ||
                  isUserHasListingAccess) && (
                  <JumboDdMenu
                    icon={<MoreHoriz />}
                    menuItems={[
                      isUserHasListingAccess && {
                        icon: <Visibility />,
                        title: 'View',
                        action: 'view',
                      },
                      isUserHasEditAccess && {
                        icon: <Edit />,
                        title: 'Update',
                        action: 'edit',
                      },
                      isUserHasDeleteAccess && {
                        icon: <Delete />,
                        title: 'Delete',
                        action: 'delete',
                      },
                    ]}
                    onClickCallback={handleItemAction}
                  />
                )}
              </>
            }
            title={
              <Tooltip title="Paid To">
                <Typography variant={'h6'} color={'text.primary'} mb={0.25}>
                  {item?.paid_to}
                </Typography>
              </Tooltip>
            }
            subheader={
              <Span>
                <Tooltip title="Paid Amount">
                  <Typography variant={'body1'} color={'text.secondary'}>
                    {item?.paid_amount}
                  </Typography>
                </Tooltip>
              </Span>
            }
          />
          <CardContent sx={{ pt: 0 }}>
            <Divider sx={{ mb: 2 }} />
            <List disablePadding>
              <ListItem
                sx={{
                  p: theme => theme.spacing(0.75, 1.5),
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Span sx={{ display: 'flex' }}>
                  <ListItemIcon sx={{ minWidth: 50 }}>
                    <FamilyRestroom />
                  </ListItemIcon>
                  <Tooltip title="Paid By">
                    <ListItemText primary={item?.paid_by?.username} />
                  </Tooltip>
                </Span>
              </ListItem>

              <ListItem
                sx={{
                  p: theme => theme.spacing(0.75, 1.5),
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Span sx={{ display: 'flex' }}>
                  <ListItemIcon sx={{ minWidth: 50 }}>
                    <MonetizationOn />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Tooltip title="Payment Mode">
                        <Typography variant="body2">
                          {`${item?.payment_mode
                            ?.charAt(0)
                            .toUpperCase()}${item?.payment_mode?.slice(1)}`}
                        </Typography>
                      </Tooltip>
                    }
                  />
                </Span>
              </ListItem>

              <ListItem
                sx={{
                  p: theme => theme.spacing(0.75, 1.5),
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Span sx={{ display: 'flex' }}>
                  <ListItemIcon sx={{ minWidth: 50 }}>
                    <MonetizationOn />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Tooltip title="Paid Amount">
                        <Typography variant="body2">
                          {item?.paid_amount}
                        </Typography>
                      </Tooltip>
                    }
                  />
                </Span>
              </ListItem>

              <ListItem
                sx={{
                  p: theme => theme.spacing(0.75, 1.5),
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Span sx={{ display: 'flex' }}>
                  <ListItemIcon sx={{ minWidth: 50 }}>
                    <Description />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Tooltip title="Description">
                        <Typography variant="body2">
                          {item?.description}
                        </Typography>
                      </Tooltip>
                    }
                  />
                </Span>
              </ListItem>

              <ListItem
                sx={{
                  p: theme => theme.spacing(0.75, 1.5),
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Span sx={{ display: 'flex' }}>
                  <ListItemIcon sx={{ minWidth: 50 }}>
                    {item?.is_approved ? <Done /> : <Close />}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Tooltip title="Payment Approved">
                        <Typography variant="body2">
                          {item?.is_approved ? 'Yes' : 'No'}
                        </Typography>
                      </Tooltip>
                    }
                  />
                </Span>
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </JumboGridItem>
    );
  }
  return (
    <JumboListItem
      componentElement={'div'}
      itemData={item}
      secondaryAction={
        <>
          {(isUserHasDeleteAccess ||
            isUserHasEditAccess ||
            isUserHasListingAccess) && (
            <JumboDdMenu
              icon={<MoreHoriz />}
              menuItems={[
                isUserHasListingAccess && {
                  icon: <Visibility />,
                  title: 'View',
                  action: 'view',
                },
                isUserHasEditAccess && {
                  icon: <Edit />,
                  title: 'Update',
                  action: 'edit',
                },
                isUserHasDeleteAccess && {
                  icon: <Delete />,
                  title: 'Delete',
                  action: 'delete',
                },
              ]}
              onClickCallback={handleItemAction}
            />
          )}
        </>
      }
      sx={{
        cursor: 'pointer',
        borderTop: 1,
        borderColor: 'divider',
        '&:hover': {
          bgcolor: 'action.hover',
        },
      }}>
      <ListItemText
        onClick={showExpenseDetails}
        primary={
          <Typography variant={'body1'} component={'div'}>
            <Stack direction={'row'} alignItems={'center'} sx={{ minWidth: 0 }}>
              <Item
                sx={{
                  flexBasis: { xs: '100%', sm: '50%', md: '25%' },
                }}>
                <Tooltip title="Paid To">
                  <Typography
                    variant={'h5'}
                    fontSize={14}
                    lineHeight={1.25}
                    mb={0}
                    noWrap>
                    {item?.paid_to}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { sm: '50%', md: '28%' },
                  display: { xs: 'none', sm: 'block' },
                }}>
                <Tooltip title="Paid By">
                  <Typography variant={'body1'} noWrap>
                    {item?.paid_by?.username}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title="Payment Mode">
                  <Typography variant={'body1'} noWrap>
                    {item?.payment_mode.charAt(0).toUpperCase() +
                      `${item?.payment_mode.slice(1)}`}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title="Payment Amount">
                  <Typography variant={'body1'} noWrap>
                    Rs. {item?.paid_amount}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title="Is Payment Approved">
                  <Typography variant={'body1'} noWrap>
                    {item?.is_approved ? 'Yes' : 'No'}
                  </Typography>
                </Tooltip>
              </Item>
            </Stack>
          </Typography>
        }
      />
    </JumboListItem>
  );
};

export default ExpenseItem;
