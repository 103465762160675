import { useState, useEffect, useCallback, useRef } from 'react';
import moment from 'moment';
import { useFormikContext } from 'formik';

import JumboDropDown from '@jumbo/components/JumboFormik/JumboDropDown';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import JumboCNICField from '@jumbo/components/JumboFormik/JumboCNICField';

import { BLOOD_GROUPS, RELIGIONS } from 'app/shared/constants';
import { API_GET } from 'app/utils/constants/api/apiRequests';
import Div from '@jumbo/shared/Div/Div';
import PreviewImage from 'app/shared/components/PreviewImage';
import { Button, Typography } from '@mui/material';

const GENDERS = [
  {
    label: 'Male',
    value: 1,
  },
  {
    label: 'Female',
    value: 2,
  },
];

const PersonalInfo = () => {
  const inputRef = useRef(null);

  const [villages, setVillages] = useState([]);
  const [age, setAge] = useState();
  const { values, setFieldValue, touched, errors } = useFormikContext();

  const fetchData = useCallback(async () => {
    try {
      const { status, data } = await API_GET('/village');
      if (status) {
        setVillages(data);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const birthDate = moment(values.date_of_birth);
    const currentDate = moment();
    const duration = moment.duration(currentDate.diff(birthDate));

    const years = duration.years();
    const months = duration.months();
    const days = duration.days();

    setAge(`${years} years, ${months} months, ${days} days old`);
  }, [values.date_of_birth]);

  const currentDate = new Date();
  const formattedDate = moment(currentDate).format('MM-DD-YYYY');

  return (
    <div style={{ paddingRight: 10 }}>
      <Div
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}>
        <PreviewImage file={values.image} alt="branch " />
        <input
          onChange={e => {
            setFieldValue('image', e.target.files[0]);
          }}
          type="file"
          hidden
          ref={inputRef}
        />
        <Typography variant="body2" textAlign={'left'} color={'red'}>
          {touched.image && errors.image ? errors.image : ''}
        </Typography>
        <Button onClick={() => inputRef.current.click()}>Upload</Button>
      </Div>

      <JumboTextField
        fullWidth
        size="small"
        variant="outlined"
        name="first_name"
        label="First Name"
      />
      <JumboTextField
        fullWidth
        size="small"
        variant="outlined"
        name="last_name"
        label="Last Name"
      />
      <JumboTextField
        fullWidth
        size="small"
        variant="outlined"
        name="nick_name"
        label="Nick Name"
      />
      <JumboCNICField
        fullWidth
        size="small"
        variant="outlined"
        name="cnic"
        label="Student's CNIC"
      />
      <JumboDropDown
        fullWidth
        size="small"
        variant="outlined"
        name="gender"
        label="Gender"
        options={GENDERS}
      />
      <JumboTextField
        fullWidth
        type="date"
        size="small"
        variant="outlined"
        name="date_of_birth"
        label="Date of Birth"
        InputLabelProps={{ shrink: true }}
        defaultValue={formattedDate}
      />
      <JumboTextField
        fullWidth
        size="small"
        variant="outlined"
        name="age"
        label={age || 'Age'}
        disabled
      />
      <JumboTextField
        fullWidth
        size="large"
        variant="outlined"
        name="address"
        label="Address"
      />

      <JumboDropDown
        fullWidth
        size="small"
        variant="outlined"
        name="village_id"
        label="Village"
        options={villages}
      />

      <JumboTextField
        fullWidth
        size="small"
        variant="outlined"
        name="tehsil"
        label="Tehsil"
      />
      <JumboTextField
        fullWidth
        size="small"
        variant="outlined"
        name="district"
        label="District"
      />

      <JumboDropDown
        fullWidth
        size="small"
        variant="outlined"
        name="blood_group"
        label="Blood Group"
        options={BLOOD_GROUPS}
      />
      <JumboDropDown
        fullWidth
        size="small"
        variant="outlined"
        name="religion"
        label="Religion"
        options={RELIGIONS}
      />
      <JumboTextField
        fullWidth
        size="small"
        variant="outlined"
        name="home_language"
        label="Home Language of the Student"
      />
      <JumboTextField
        fullWidth
        size="small"
        variant="outlined"
        name="nationality"
        label="Nationality"
      />
    </div>
  );
};

export default PersonalInfo;
