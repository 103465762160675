import React from 'react';

import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
  Avatar,
  Stack,
  Tooltip,
} from '@mui/material';
import {
  MoreHoriz,
  Edit,
  MonetizationOn,
  Delete,
  Person,
  Numbers,
  Person2,
} from '@mui/icons-material';

import styled from '@emotion/styled';

import JumboDdMenu from '@jumbo/components/JumboDdMenu';
import Span from '@jumbo/shared/Span';
import JumboListItem from '@jumbo/components/JumboList/components/JumboListItem';
import JumboGridItem from '@jumbo/components/JumboList/components/JumboGridItem';

import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteItem } from 'app/store/features/studentItemSlice';

const Item = styled(Span)(({ theme }) => ({
  minWidth: 0,
  flexGrow: 0,
  padding: theme.spacing(0, 1),
}));

const StudentItem = ({ fee, view }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(deleteItem(fee.id));
  };

  const handleItemAction = menuItem => {
    switch (menuItem.action) {
      case 'edit':
        navigate('/app/studentItem/new', { state: fee });
        break;
      case 'delete':
        handleDelete();
        break;
      default:
        break;
    }
  };

  if (view === 'grid') {
    return (
      <JumboGridItem xs={12} md={6} lg={4}>
        <Card
          variant="outlined"
          elevation={0}
          sx={{ cursor: 'pointer' }}
          onClick={() => {}}>
          <CardHeader
            avatar={
              <Avatar
                sx={{ width: 48, height: 48 }}
                alt={fee?.firstName}
                src={fee?.profile_pic}
              />
            }
            action={
              <>
                <JumboDdMenu
                  icon={<MoreHoriz />}
                  menuItems={[
                    { icon: <Edit />, title: 'Update', action: 'edit' },
                    { icon: <Delete />, title: 'Delete', action: 'delete' },
                  ]}
                  onClickCallback={handleItemAction}
                />
              </>
            }
            title={
              <Tooltip title="Item Name">
                <Typography variant={'h6'} color={'text.primary'} mb={0.25}>
                  {`${fee?.item?.name}`}
                </Typography>
              </Tooltip>
            }
            subheader={
              <Span>
                <Tooltip title="Item Assigned to Student">
                  <Typography variant={'body1'} color={'text.secondary'}>
                    {moment(fee?.createdAt).format('LLLL')}
                  </Typography>
                </Tooltip>
              </Span>
            }
          />
          <CardContent sx={{ pt: 0 }}>
            <Divider sx={{ mb: 2 }} />
            <List disablePadding>
              <ListItem
                sx={{
                  p: theme => theme.spacing(0.75, 1.5),
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Span sx={{ display: 'flex' }}>
                  <ListItemIcon sx={{ minWidth: 50 }}>
                    <Person2 />
                  </ListItemIcon>
                  <ListItemText
                    primary={'Student'}
                    secondary={
                      <Span>
                        <Tooltip title="Name">
                          <Typography variant="body2">
                            {`${fee?.student?.first_name} ${fee?.student?.last_name}`}
                          </Typography>
                        </Tooltip>
                        <Typography variant="body2">
                          Father Name: {fee?.student?.father_name}
                        </Typography>
                      </Span>
                    }
                  />
                </Span>
              </ListItem>

              <ListItem
                sx={{
                  p: theme => theme.spacing(0.75, 1.5),
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Span sx={{ display: 'flex' }}>
                  <ListItemIcon sx={{ minWidth: 50 }}>
                    <Person />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Tooltip title="Assigned By">
                        <Typography variant="body2">Assigned By</Typography>
                      </Tooltip>
                    }
                    secondary={
                      <Span>
                        <Typography variant="body2">
                          {fee?.assigned_by?.name
                            ? fee?.assigned_by?.name
                            : fee?.assigned_by?.username}
                        </Typography>
                      </Span>
                    }
                  />
                </Span>
              </ListItem>

              <ListItem
                sx={{
                  p: theme => theme.spacing(0.75, 1.5),
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Span sx={{ display: 'flex' }}>
                  <ListItemIcon sx={{ minWidth: 50 }}>
                    <Numbers />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Tooltip title="Quantity">
                        <Typography variant="body2">Quantity</Typography>
                      </Tooltip>
                    }
                    secondary={
                      <Span>
                        <Typography variant="body2">{fee?.quantity}</Typography>
                      </Span>
                    }
                  />
                </Span>
              </ListItem>
              <ListItem
                sx={{
                  p: theme => theme.spacing(0.75, 1.5),
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Span sx={{ display: 'flex' }}>
                  <ListItemIcon sx={{ minWidth: 50 }}>
                    <MonetizationOn />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Tooltip title="Receive able Amount">
                        <Typography variant="body2">
                          Receive able Amount
                        </Typography>
                      </Tooltip>
                    }
                    secondary={
                      <Span>
                        <Tooltip title="Recommended Fee">
                          <Typography variant="body2">
                            Rs. {fee?.receivable_amount}
                          </Typography>
                        </Tooltip>
                      </Span>
                    }
                  />
                </Span>
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </JumboGridItem>
    );
  }
  return (
    <JumboListItem
      componentElement={'div'}
      itemData={fee}
      secondaryAction={
        <JumboDdMenu
          icon={<MoreHoriz />}
          menuItems={[
            { icon: <Edit />, title: 'Update', action: 'edit' },
            { icon: <Delete />, title: 'Delete', action: 'delete' },
          ]}
          onClickCallback={handleItemAction}
        />
      }
      sx={{
        cursor: 'pointer',
        borderTop: 1,
        borderColor: 'divider',
        '&:hover': {
          bgcolor: 'action.hover',
        },
      }}>
      <ListItemText
        onClick={() => {}}
        primary={
          <Typography variant={'body1'} component={'div'}>
            <Stack direction={'row'} alignItems={'center'} sx={{ minWidth: 0 }}>
              <Item
                sx={{
                  flexBasis: { xs: '100%', sm: '50%', md: '25%' },
                }}>
                <Tooltip title="Item Name">
                  <Typography
                    variant={'h5'}
                    fontSize={14}
                    lineHeight={1.25}
                    mb={0}
                    noWrap>
                    {`${fee?.item?.name}`}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { xs: '100%', sm: '50%', md: '25%' },
                }}>
                <Tooltip title="Student Name">
                  <Typography
                    variant={'h5'}
                    fontSize={14}
                    lineHeight={1.25}
                    mb={0}
                    noWrap>
                    {`${fee?.student?.first_name} ${fee?.student?.last_name}`}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { sm: '50%', md: '28%' },
                  display: { xs: 'none', sm: 'block' },
                }}>
                <Tooltip title="Father Name">
                  <Typography variant={'body1'} noWrap>
                    {fee?.student?.father_name}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title="Item Assigned At">
                  <Typography variant={'body1'} noWrap>
                    {moment(fee?.createdAt).format('YYYY-MM-DD')}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title="Quantity">
                  <Typography variant={'body1'} noWrap>
                    {fee?.quantity}
                  </Typography>
                </Tooltip>
              </Item>

              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title="Assigned By">
                  <Typography variant={'body1'} noWrap>
                    {fee?.assigned_by?.name
                      ? fee?.assigned_by?.name
                      : fee?.assigned_by?.username}
                  </Typography>
                </Tooltip>
              </Item>

              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title="Receive able Amount">
                  <Typography variant={'body1'} noWrap>
                    Rs. {fee?.receivable_amount}
                  </Typography>
                </Tooltip>
              </Item>
            </Stack>
          </Typography>
        }
      />
    </JumboListItem>
  );
};

export default StudentItem;
