import { Select, MenuItem, Typography } from '@mui/material';

import Div from '@jumbo/shared/Div';

const DropDown = ({
  options,
  label,
  value,
  onChange,
  sx = { width: '100%' },
  name,
}) => {
  return (
    <Div sx={{ ...sx, marginBottom: 2 }}>
      <Typography color="gray" sx={{ marginTop: -1 }}>
        {label}
      </Typography>
      <Select
        label={label}
        value={value}
        onChange={onChange}
        name={name}
        sx={{ width: '100%' }}>
        {options.length ? (
          options?.map((option, index) => (
            <MenuItem
              key={option + index}
              value={option.value ? option.value : option.id}>
              {option.title
                ? option.title
                : option.label
                ? option.label
                : option.username
                ? option.username
                : option.class_section_title
                ? option.class_section_title
                : option.name}
            </MenuItem>
          ))
        ) : (
          <Typography sx={{ marginLeft: 2 }}>No Options</Typography>
        )}
      </Select>
    </Div>
  );
};

export default DropDown;
