import Branches from 'app/pages/apps/branches';
import Classes from 'app/pages/apps/classes';
import Users from 'app/pages/apps/users';
import Inventory from 'app/pages/apps/inventory';
import Attendance from 'app/pages/apps/attendance';
import Fees from 'app/pages/apps/fees';
import Students from 'app/pages/apps/students';
import StudentProfile from 'app/pages/apps/StudentProfile';
import TeacherProfile from 'app/pages/apps/TeacherProfile';
import AddAttendance from 'app/pages/apps/attendance/components/AddAttendance';
import Subjects from 'app/pages/apps/subjects/subject';
import Accounts from 'app/pages/apps/accounts';
import BillForm from 'app/pages/apps/accounts/components/BillForm';
import BillDetails from 'app/pages/apps/accounts/components/BillDetail';
import StudentForm from 'app/pages/apps/students/components/StudentForm';
import ExamInitiatorForm from 'app/pages/apps/examination/components/ExamInitiatorForm';
import ExamFollowUpForm from 'app/pages/apps/examination/components/ExamFollowUpForm';
import ExamInitiator from 'app/pages/apps/examination/ExamInitiator';
import ExamFollowUp from 'app/pages/apps/examination/ExamFollowUp';
import ClassSectionSubject from 'app/pages/apps/subjects/classSectionSubject';
import NonExpensePayments from 'app/pages/apps/nonExpensePayments';
import StudentItemForm from 'app/pages/apps/studentItems/components/StudentItemForm';
import StudentItems from 'app/pages/apps/studentItems';
import Roles from 'app/pages/apps/roles';
import RoleForm from 'app/pages/apps/roles/components/RoleForm';
import ClassForm from 'app/pages/apps/classes/components/ClassForm';
import EditProfile from 'app/pages/apps/editProfile';

const user = JSON.parse(localStorage.getItem('fotanistanUser') || '{}');
const userPermissions = user?.permissions;

const hasListingPermissions = requiredPermissions => {
  if (!Object.keys(user).length) return false;
  if (user?.user_type === 'admin') return true;
  for (const permission of requiredPermissions) {
    if (!userPermissions[permission] || !userPermissions[permission]?.listing) {
      return false;
    }
  }
  return true;
};

const hasAddPermissions = requiredPermissions => {
  if (!Object.keys(user).length) return false;

  if (user?.user_type === 'admin') return true;

  for (const permission of requiredPermissions) {
    if (!userPermissions[permission] || !userPermissions[permission].add) {
      return false;
    }
  }
  return true;
};

const hasEditPermissions = requiredPermissions => {
  if (!Object.keys(user).length) return false;

  if (user?.user_type === 'admin') return true;

  for (const permission of requiredPermissions) {
    if (!userPermissions[permission] || !userPermissions[permission].edit) {
      return false;
    }
  }
  return true;
};

const appsRoutes = [
  {
    path: ['/app/accounts/:category', '/app/accounts/:category/:id'],
    element: hasListingPermissions(['bill', 'expense', 'expense_category']) ? (
      <Accounts />
    ) : null,
  },
  {
    path: ['/app/accounts/bill'],
    element: hasListingPermissions(['bill']) ? <BillDetails /> : null,
  },
  {
    path: ['app/accounts/add-bill'],
    element: hasAddPermissions(['bill']) ? <BillForm /> : null,
  },
  {
    path: ['/app/branches'],
    element: hasListingPermissions(['branch']) ? <Branches /> : null,
  },
  {
    path: ['/app/classes/:category', '/app/classes/:category/:id'],
    element: hasListingPermissions(['class_section']) ? <Classes /> : null,
  },
  {
    path: ['/app/subjects'],
    element: hasListingPermissions(['subject']) ? <Subjects /> : null,
  },
  {
    path: '/app/class-section-subjects',
    element: hasListingPermissions(['class_section_subject']) ? (
      <ClassSectionSubject />
    ) : null,
  },
  {
    path: ['/app/students/:category'],
    element: hasListingPermissions(['student']) ? <Students /> : null,
  },
  {
    path: ['/app/students/new'],
    element: hasAddPermissions(['student']) ? <StudentForm /> : null,
  },
  {
    path: ['/app/student/:id'],
    element: hasListingPermissions(['student']) ? <StudentProfile /> : null,
  },
  {
    path: ['/app/teacher/:id'],
    element: hasListingPermissions(['user']) ? <TeacherProfile /> : null,
  },
  // {
  //   path: ['/app/teachers/:category', '/app/teachers/:category/:id'],
  //   element: hasListingPermissions(['user']) ? <Teachers /> : null,
  // },
  {
    path: ['/app/users/:category', '/app/users/:category/:id'],
    element: hasListingPermissions(['user']) ? <Users /> : null,
  },
  {
    path: ['/app/inventory'],
    element: hasListingPermissions(['inventory']) ? <Inventory /> : null,
  },
  {
    path: ['/app/attendance'],
    element: hasListingPermissions(['attendance']) ? <Attendance /> : null,
  },
  {
    path: ['/app/attendance/add'],
    element: hasAddPermissions(['attendance']) ? <AddAttendance /> : null,
  },
  {
    path: ['/app/fees/:category', '/app/fees/:category/:id'],
    element: hasListingPermissions(['fees']) ? <Fees /> : null,
  },
  {
    path: ['/app/exam-init/:category'],
    element: hasListingPermissions(['exam_initiator']) ? (
      <ExamInitiator />
    ) : null,
  },
  {
    path: ['/app/exam-follow/:category'],
    element: hasListingPermissions(['exam_task_followup']) ? (
      <ExamFollowUp />
    ) : null,
  },
  {
    path: ['/app/examination/initiator'],
    element: hasAddPermissions(['exam_initiator']) ? (
      <ExamInitiatorForm />
    ) : null,
  },
  {
    path: '/app/examination/followup',
    element: hasAddPermissions(['exam_task_followup']) ? (
      <ExamFollowUpForm />
    ) : null,
  },
  {
    path: '/app/nonExpensePayments',
    element: hasListingPermissions(['non_expense_payment']) ? (
      <NonExpensePayments />
    ) : null,
  },
  {
    path: '/app/roles',
    element: user?.user_type === 'admin' ? <Roles /> : null,
  },
  {
    path: '/app/roles/form',
    element: user?.user_type === 'admin' ? <RoleForm /> : null,
  },
  {
    path: '/profile/edit',
    element: <EditProfile />,
  },
  {
    path: '/app/classes/form',
    element:
      hasAddPermissions(['class_section']) ||
      hasEditPermissions(['class_section']) ? (
        <ClassForm />
      ) : null,
  },
];

export default appsRoutes;
