import { useMemo, useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@mui/material';

import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import ClassesAppSidebar from './components/ClassesAppSidebar';
import ClassesList from './components/ClassesList';
import Card from 'app/pages/dashboards/accounts/components/BitcoinPrice';

import { getAllClasses } from 'app/store/features/classesSlice';

import {
  calculateNumberOfStudents,
  calculateSections,
  calculateFavorites,
} from './utils/calculations';

const Classes = () => {
  const dispatch = useDispatch();
  const { category } = useParams();
  const classes = useSelector(state => state.classes.classes);
  const isStoreClasses = useSelector(state => state.classes.isDataFetched);
  const [filteredData, setFilteredData] = useState(classes);
  const user = useSelector(state => state.auth.user);

  const isUserHasAddAccess = user?.permissions['class_section']?.add || false;
  const isSidebarVisibleToUser =
    isUserHasAddAccess || user?.user_type === 'admin';

  const students = useMemo(
    () => calculateNumberOfStudents(filteredData),
    [filteredData],
  );
  const sections = useMemo(
    () => calculateSections(filteredData),
    [filteredData],
  );
  const favorites = useMemo(
    () => calculateFavorites(filteredData),
    [filteredData],
  );

  const fetchClasses = useCallback(async () => {
    if (!isStoreClasses) {
      dispatch(getAllClasses());
    }
  }, [dispatch, isStoreClasses]);

  useEffect(() => {
    if (filteredData.length !== classes.length) setFilteredData(classes);
    fetchClasses();
  }, [fetchClasses, filteredData, classes]);

  useEffect(() => {
    if (category === 'all') {
      setFilteredData(classes);
    } else {
      const filteredClasses = classes?.filter(
        single => category === single.branch.name.toLowerCase(),
      );
      setFilteredData(filteredClasses);
    }
  }, [category]);

  return (
    <>
      {/* <Grid container spacing={1} sx={{ marginBottom: 5 }}>
        <Grid item xs={12} sm={6} lg={3}>
          <Card
            title={filteredData.length}
            subHeader={'Total Classes'}
            growth={filteredData.length}
            tooltip={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Card
            title={students}
            subHeader={'Total Students'}
            growth={students}
            bgColor={'#ff6f60'}
            graphStartColor={'#e53935'}
            graphEndColor={'#e53935'}
            tooltip={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Card
            title={sections}
            subHeader={'Total Sections'}
            growth={sections}
            bgColor={'#A53860'}
            graphStartColor={'#00ada9'}
            graphEndColor={'#00ada9'}
            tooltip={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Card
            title={favorites}
            subHeader={'Favorites'}
            growth={favorites}
            bgColor={'#4B4453'}
            graphStartColor={'#42a5f5'}
            graphEndColor={'#2196f3'}
            tooltip={false}
          />
        </Grid>
      </Grid> */}

      <JumboContentLayout
        sidebar={isSidebarVisibleToUser && <ClassesAppSidebar />}>
        <ClassesList />
      </JumboContentLayout>
    </>
  );
};
export default Classes;
