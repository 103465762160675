import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import SubjectsSidebar from './components/SubjectSidebar';
import SubjectsList from './components/SubjectsList';
import { getAllSubjects } from 'app/store/features/subjectsSlice';

const Subjects = () => {
  const dispatch = useDispatch();
  const isStoreSubjectsFetched = useSelector(
    state => state.subjects.isDataFetched,
  );
  const user = useSelector(state => state.auth.user);

  const isUserHasAddAccess = user?.permissions['subject']?.add || false;
  const isSidebarVisibleToUser =
    isUserHasAddAccess || user?.user_type === 'admin';

  const fetchData = useCallback(async () => {
    if (!isStoreSubjectsFetched) {
      dispatch(getAllSubjects());
    }
  }, [dispatch, isStoreSubjectsFetched]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <JumboContentLayout
        sidebar={
          isSidebarVisibleToUser && (
            <SubjectsSidebar
              isUserHasAddAccess={isUserHasAddAccess}
              isUserSuperAdmin={user?.user_type === 'admin'}
            />
          )
        }>
        <SubjectsList />
      </JumboContentLayout>
    </>
  );
};
export default Subjects;
