import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyAZPhUuoRK0S61A8M-IMuaP_FaUpS7yUHA',
  authDomain: 'fotanistanschools.firebaseapp.com',
  projectId: 'fotanistanschools',
  storageBucket: 'fotanistanschools.appspot.com',
  messagingSenderId: '11291428296',
  appId: '1:11291428296:web:5ddd0a4f60f52cc85decfd',
  measurementId: 'G-CLWJ0CBQSR',
};

const firebase = initializeApp(firebaseConfig);

export const auth = getAuth(firebase);
getAnalytics(firebase);
