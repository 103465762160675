const COLORS = ['warning', 'secondary', 'success'];

export const calculate = data => {
  const males = data.filter(
    item => item.gender.toLowerCase().trim() === 'male',
  ).length;

  const result = [
    {
      id: 1,
      label: 'Males',
      value: Math.floor(males / data.length) * 100,
      color: COLORS[2],
    },
    {
      id: 2,
      label: 'Females',
      value: 100 - Math.floor(males / data.length) * 100,
      color: COLORS[1],
    },
  ];

  return result;
};

export const calculateGraph = (data, isStaff) => {
  let results = [];
  for (let i = 0; i < data.length; i++) {
    let obj;

    if (isStaff) {
      obj = {
        id: i + 1,
        Males: 0,
        Females: 0,
        'Non w Male': 0,
        'Non Teaching Female': 0,
      };
    } else {
      obj = {
        id: i + 1,
        Males: 0,
        Females: 0,
      };
    }

    const currentBranch = results.filter(
      item => item?.name === data[i].branch,
    ).length;
    if (currentBranch) {
      const filteredItems = results.filter(
        item => item?.name !== data[i].branch,
      );
      const item = results.filter(item => item?.name === data[i].branch)[0];
      if (data[i].gender.toLowerCase().trim() === 'male') {
        item.Males++;
      } else {
        item.Females++;
      }

      results = [...filteredItems, item];
    } else {
      obj.name = data[i].branch;
      if (data[i].gender.toLowerCase().trim() === 'male') {
        obj.Males++;
      } else {
        obj.Females++;
      }

      if (isStaff) {
        obj['Non Teaching Male'] = obj['Non Teaching Male'] + 1;
        obj['Non Teaching Female'] = obj['Non Teaching Female'] + 1;
      }
      results.push(obj);
    }
  }

  return results;
};
