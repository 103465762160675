import { useCallback, useEffect } from 'react';
import { Button } from '@mui/material';

import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import InventoryList from './components/InventoryList';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import Div from '@jumbo/shared/Div';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import ItemForm from './components/ItemForm';
import { useDispatch, useSelector } from 'react-redux';
import { getAllItems } from 'app/store/features/itemSlice';

const Classes = () => {
  const { showDialog } = useJumboDialog();
  const dispatch = useDispatch();
  const isItemsFetched = useSelector(state => state.item.isDataFetched);
  const user = useSelector(state => state.auth.user);
  const isUserHasAddAccess =
    user?.user_type === 'admin' || user?.permissions['inventory']?.add;

  const showAddContactDialog = useCallback(() => {
    showDialog({
      title: 'Add New Item',
      content: <ItemForm />,
    });
  }, [showDialog]);

  const fetchData = useCallback(() => {
    if (!isItemsFetched) dispatch(getAllItems());
  }, [dispatch, isItemsFetched]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Div
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}>
        {isUserHasAddAccess && (
          <Button
            disableElevation
            variant={'contained'}
            startIcon={<PersonAddIcon />}
            sx={{
              mb: 2,
              '& .MuiSvgIcon-root': {
                fontSize: '1.5rem',
              },
            }}
            onClick={showAddContactDialog}>
            Add New Item
          </Button>
        )}
      </Div>
      <JumboContentLayout>
        <InventoryList />
      </JumboContentLayout>
    </>
  );
};
export default Classes;
