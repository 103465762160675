export const randomColorGenerator = () =>
  '#' +
  Math.floor(Math.random() * 16777215)
    .toString(16)
    .padStart(6, '0')
    .toUpperCase();

export const makeChips = branches => {
  const result = [];

  for (let i = 0; i < branches.length; i++) {
    const alreadyAdded = result?.filter(
      item => item.name.toLowerCase() === branches[i].name.toLowerCase(),
    );
    if (!alreadyAdded.length) {
      const branch = {
        id: i + 1,
        name: branches[i].name,
        label: branches[i].name,
        isSelected: false,
        color: 'gray',
        colorToShow: 'gray',
        revenueSummary: branches[i].revenueSummary,
      };

      result.push(branch);
    }
  }
  return result;
};

export const calculateExpenses = branches => {
  const result = [];
  for (let i = 0; i < branches.length; i++) {
    let expenses = 0;
    const { revenueSummary } = branches[i];
    for (let j = 0; j < revenueSummary.length; j++) {
      expenses += revenueSummary[j].expense;
    }
    let data = {
      name: branches[i].name,
      value: expenses,
      color: branches[i].colorToShow,
    };
    result.push(data);
  }
  return result;
};
export const calculateEarning = branches => {
  const result = [];
  for (let i = 0; i < branches.length; i++) {
    let earning = 0;
    const { revenueSummary } = branches[i];
    for (let j = 0; j < revenueSummary.length; j++) {
      earning += revenueSummary[j].earning;
    }
    let data = {
      name: branches[i].name,
      value: earning,
      color: branches[i].colorToShow,
    };
    result.push(data);
  }
  return result;
};

export const calculateNonExpenses = branches => {
  const result = [];
  for (let i = 0; i < branches.length; i++) {
    let earning = 0;
    const { revenueSummary } = branches[i];
    for (let j = 0; j < revenueSummary.length; j++) {
      earning += revenueSummary[j].earning;
    }
    let data = {
      name: branches[i].name,
      value: earning,
      color: branches[i].colorToShow,
    };
    result.push(data);
  }
  return result;
};
