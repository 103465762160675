import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';

import LoadingButton from '@mui/lab/LoadingButton';

import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import Div from '@jumbo/shared/Div';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { updateFee } from 'app/store/features/feesSlice';

import { feeSchema } from '../../utils/schema';
import { useCallback, useEffect } from 'react';
import { getAllStudents } from 'app/store/features/studentsSlice';
import JumboDropDown from '@jumbo/components/JumboFormik/JumboDropDown';
import { addNewFee, editFee } from 'app/store/features/feeSlice';

const FeeForm = ({ fee }) => {
  const Swal = useSwalWrapper();
  const dispatch = useDispatch();
  const students = useSelector(state => state.students.students);
  const isStudentsFetched = useSelector(state => state.students.isDataFetched);

  const { hideDialog } = useJumboDialog();

  const fetchData = useCallback(() => {
    if (!isStudentsFetched) dispatch(getAllStudents());
  }, [dispatch, isStudentsFetched]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateStudentHandler = data => {
    const payload = { ...data, id: fee.id };

    dispatch(editFee(payload));
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Fee has been updated successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
    hideDialog();
  };

  const addFeeHandler = data => {
    dispatch(addNewFee(data));
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Fee has been added successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
    hideDialog();
  };

  const handleSubmit = data => {
    fee ? updateStudentHandler(data) : addFeeHandler(data);
  };

  return (
    <>
      <Formik
        validateOnChange={true}
        initialValues={{
          student_id: fee ? fee.student?.id : '',
          month: fee ? fee.month : 1,
          year: fee ? fee.year : 2023,
          total: fee ? fee.total : 0,
          status: fee ? fee.status : 'pending',
        }}
        enableReinitialize={true}
        validationSchema={feeSchema}
        onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form noValidate autoComplete="off">
            <Div
              sx={{
                '& .MuiTextField-root': {
                  mb: 3,
                },
              }}>
              <JumboDropDown
                fullWidth
                size="small"
                variant="outlined"
                name="student_id"
                label="Student"
                options={students}
                isForFee
              />
              <JumboTextField
                fullWidth
                size="small"
                variant="outlined"
                name="month"
                label="Month"
                type="number"
              />
              <JumboTextField
                fullWidth
                size="small"
                variant="outlined"
                name="year"
                label="Year"
                type="number"
              />

              <JumboTextField
                fullWidth
                size="small"
                variant="outlined"
                name="total"
                label="Total"
                type="number"
              />

              <Div
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                }}>
                <LoadingButton
                  variant="contained"
                  size="large"
                  sx={{ mb: 3, backgroundColor: '#E44A77' }}
                  onClick={() => hideDialog()}>
                  Cancel
                </LoadingButton>

                <LoadingButton
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mb: 3 }}
                  loading={isSubmitting}>
                  Save
                </LoadingButton>
              </Div>
            </Div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FeeForm;
