import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  API_GET,
  API_POST,
  API_PUT,
  API_DELETE,
} from 'app/utils/constants/api/apiRequests';
import { setLoading, setLoadingFinished } from './loadingSlice';
import { showAlert } from './alertSlice';

const initialState = {
  users: [],
  isDataFetched: false,
  isUserModalOpen: false,
  userFormLoading: false,
};

export const getAllUsers = createAsyncThunk(
  'users/getAllUsers',
  async (_, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_GET('/user');
      if (status) {
        dispatch(
          showAlert({ type: 'success', message: 'Users Fetched Successfully' }),
        );
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const addNewUser = createAsyncThunk(
  'users/addNewUser',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, message } = await API_POST('/user', payload);
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllUsers());
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const updateUser = createAsyncThunk(
  'users/updateUser',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_PUT(
        '/user',
        payload.id,
        payload.payload,
      );
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllUsers());
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const removeUser = createAsyncThunk(
  'users/removeUser',
  async (id, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_DELETE('/user', id);
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllUsers());
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const usersSlice = createSlice({
  name: 'users',
  initialState,

  reducers: {
    openUserModal: state => {
      state.isUserModalOpen = true;
    },
    closeUserModal: state => {
      state.isUserModalOpen = false;
    },
    setUserFormLoading: state => {
      state.userFormLoading = true;
    },
    setUserFormLoadingFinished: state => {
      state.userFormLoading = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(getAllUsers.fulfilled, (state, { payload }) => {
      state.users = payload;
      state.isDataFetched = true;
      state.isUserModalOpen = false;
      state.userFormLoading = false;
    });
    builder.addCase(addNewUser.pending, state => {
      state.userFormLoading = true;
    });
    builder.addCase(updateUser.pending, state => {
      state.userFormLoading = true;
    });
  },
});

export const {
  openUserModal,
  closeUserModal,
  setUserFormLoading,
  setUserFormLoadingFinished,
} = usersSlice.actions;

export default usersSlice.reducer;
