import { useCallback, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  Card,
  CardHeader,
  CardContent,
  LinearProgress,
  Typography,
  Stack,
  Grid,
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from '@mui/material';
import TrendingDownSharpIcon from '@mui/icons-material/TrendingDownSharp';
import TrendingUpSharpIcon from '@mui/icons-material/TrendingUpSharp';

import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { getBranches, calculateProfit } from './data';

const PortfolioBalance = ({ ExpenseForm, DepositForm }) => {
  const accountDetails = useSelector(state => state.accounts.accounts);
  const result = useMemo(() => getBranches(accountDetails), [accountDetails]);
  const [branch, setBranch] = useState(result[0].value);

  const value = useMemo(
    () => calculateProfit(accountDetails, branch),
    [accountDetails, branch],
  );

  const isProfit = value.totalEarning / value.totalEarning;
  const percentage = value.totalEarning / value.totalExpense;

  const { showDialog } = useJumboDialog();

  const handleExpense = useCallback(() => {
    showDialog({
      title: 'Add New Expense',
      content: <ExpenseForm />,
    });
  }, [showDialog]);

  const handleDeposit = useCallback(() => {
    showDialog({
      title: 'Add New Deposit',
      content: <DepositForm />,
    });
  }, [showDialog]);

  return (
    <Card height={152} sx={{ height: '100%' }}>
      <CardHeader title={'Current Balance'} />
      <CardContent sx={{ pt: 2 }}>
        <FormControl sx={{ width: '100%', mt: -3, mb: 2 }} size="small">
          <InputLabel id="demo-select-small">Branch</InputLabel>
          <Select
            labelId="demo-select-small"
            id="demo-select-small"
            value={branch}
            label="Branch"
            onChange={e => setBranch(e.target.value)}>
            {result.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Stack direction={'row'} spacing={3}>
          <Grid item xs={6}>
            <Typography variant={'h2'}>
              Rs. {value.totalEarning - value.totalExpense}
              <Typography
                component={'span'}
                color={isProfit ? 'lightseagreen' : 'error'}
                sx={{
                  display: 'inline-flex',
                  ml: 1,
                }}>
                {percentage?.toFixed(2) + '%'}
                {!isProfit && (
                  <TrendingDownSharpIcon
                    fontSize={'small'}
                    sx={{ ml: '2px' }}
                  />
                )}
                {isProfit && (
                  <TrendingUpSharpIcon fontSize={'small'} sx={{ ml: '2px' }} />
                )}
              </Typography>
            </Typography>
            <Typography variant={'body1'} sx={{ mb: 3 }}>
              Cash in Hand
            </Typography>
            <Stack direction={'row'} spacing={1}>
              <Button
                variant={'contained'}
                color={'secondary'}
                onClick={handleDeposit}>
                Deposit
              </Button>
              <Button
                variant={'contained'}
                color={'inherit'}
                disableElevation
                onClick={handleExpense}>
                Withdraw
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={'h5'} color={'text.secondary'} mb={2}>
              Account Distribution
            </Typography>
            <Typography variant={'h6'} color={'text.primary'}>
              Earnings
              <Typography
                sx={{ borderLeft: 1, ml: 1, pl: 1 }}
                component={'span'}
                color={'text.secondary'}>
                Rs. {value.totalEarning}
              </Typography>
            </Typography>
            <LinearProgress
              variant={'determinate'}
              color={'success'}
              value={82}
              sx={{
                width: '75%',
                borderRadius: 4,
                height: 5,
                mb: 2,
                backgroundColor: '#E9EEEF',
              }}
            />
            <Typography variant={'h6'} color={'text.primary'}>
              Expenses
              <Typography
                sx={{ borderLeft: 1, ml: 1, pl: 1 }}
                component={'span'}
                color={'text.secondary'}>
                Rs. {value.totalExpense}
              </Typography>
            </Typography>
            <LinearProgress
              variant={'determinate'}
              color={'warning'}
              value={55}
              sx={{
                width: '75%',
                borderRadius: 4,
                height: 5,
                backgroundColor: '#E9EEEF',
              }}
            />
          </Grid>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default PortfolioBalance;
