import { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, ButtonGroup, Button } from '@mui/material';
import { List, ViewComfy } from '@mui/icons-material';

import JumboRqList from '@jumbo/components/JumboReactQuery/JumboRqList';
import JumboListToolbar from '@jumbo/components/JumboList/components/JumboListToolbar';
import JumboSearch from '@jumbo/components/JumboSearch';

import BillItem from './BillItem';
import { getAllBills } from 'app/store/features/expenseSlice';

const ExpensesList = () => {
  const dispatch = useDispatch();
  const listRef = useRef();
  const expenses = useSelector(state => state.expense.bills);
  const isExpensesFetched = useSelector(state => state.expense.isBillsFetched);

  const [view, setView] = useState('list');
  const [expensesToShow, setExpensesToShow] = useState(expenses);

  const fetchData = useCallback(() => {
    if (!isExpensesFetched) dispatch(getAllBills());
  }, [dispatch, isExpensesFetched]);

  useEffect(() => {
    setExpensesToShow(expenses);
    fetchData();
  }, [expenses, fetchData]);

  const renderClass = useCallback(
    item => {
      return <BillItem item={item} view={view} />;
    },
    [view],
  );

  const handleOnChange = useCallback(
    keyword => {
      const filteredItems = expenses?.filter(
        expense =>
          expense?.title?.toLowerCase().search(keyword?.toLowerCase()) !== -1,
      );
      setExpensesToShow(filteredItems);
    },
    [expenses],
  );

  return (
    <JumboRqList
      ref={listRef}
      data={expensesToShow}
      wrapperComponent={Card}
      primaryKey={'id'}
      itemsPerPage={8}
      itemsPerPageOptions={[8, 15, 20]}
      renderItem={renderClass}
      componentElement={'div'}
      isClasses
      sx={view === 'grid' && { p: theme => theme.spacing(1, 3, 3) }}
      wrapperSx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
      toolbar={
        <JumboListToolbar
          hideItemsPerPage={true}
          actionTail={
            <ButtonGroup
              variant="outlined"
              disableElevation
              sx={{
                '& .MuiButton-root': {
                  px: 1,
                },
              }}>
              <Button
                variant={view === 'list' ? 'contained' : 'outlined'}
                onClick={() => setView('list')}>
                <List />
              </Button>
              <Button
                variant={view === 'grid' ? 'contained' : 'outlined'}
                onClick={() => setView('grid')}>
                <ViewComfy />
              </Button>
            </ButtonGroup>
          }>
          <JumboSearch
            onChange={handleOnChange}
            sx={{
              display: { xs: 'none', md: 'block' },
            }}
          />
        </JumboListToolbar>
      }
      view={view}
    />
  );
};

export default ExpensesList;
