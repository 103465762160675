import * as Yup from 'yup';
export const examInitiatorSchema = Yup.object().shape({
  branch_id: Yup.number().required('Branch is required'),
  shift: Yup.string().required('Shift is required'),
  date_sheet_initiator_id: Yup.number().required(
    'Date sheet initiator is required',
  ),
  head_examiner_id: Yup.number().required('Head examiner is required'),
  al_final_reviewer_id: Yup.number().required('AL final reviewer is required'),
  remarks_final_reviewer_id: Yup.number().required(
    'Remarks final reviewer is required',
  ),
  is_publish: Yup.boolean().required('Publish status is required'),
  is_freeze: Yup.boolean().required('Freeze status is required'),
  is_remarks: Yup.boolean().required('Remarks status is required'),
  exam_term: Yup.string().required('Exam term is required'),
  exam_type: Yup.string().required('Exam type is required'),
  date_sheet_date: Yup.date().required('Date sheet date is required'),
  exam_start_date: Yup.date().required('Exam start date is required'),
  exam_result_date: Yup.date().required('Exam result date is required'),
  sms_flag: Yup.boolean().required('SMS flag is required'),
  test_flag: Yup.boolean().required('Test flag is required'),
  remarks_flag: Yup.boolean().required('Remarks flag is required'),
});

export const examFollowUpSchema = Yup.object().shape({
  class_section_subject_id: Yup.number().required(
    'Class section subject is required',
  ),
  exam_term: Yup.string().required('Exam term is required'),
  exam_type: Yup.string().required('Exam type is required'),
  exam_hall: Yup.string().required('Exam hall is required'),
  checker_id: Yup.number().required('Checker is required'),
  rechecker_id: Yup.number().required('Rechecker is required'),
  al_initiator_id: Yup.number().required('AL initiator is required'),
  al_second_initiator_id: Yup.number().required(
    'AL second initiator is required',
  ),
  al_verifier_id: Yup.number().required('AL verifier is required'),
  al_final_rev_id: Yup.number().required('AL final reviewer is required'),
  remarks_initiator_id: Yup.number().required('Remarks initiator is required'),
  remarks_final_rev_id: Yup.number().required(
    'Remarks final reviewer is required',
  ),
  invigilator_id: Yup.number().required('Invigilator is required'),
  exam_status: Yup.boolean().required('Exam status is required'),
  remarks_status: Yup.boolean().required('Remarks status is required'),
});
