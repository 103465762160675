import { configureStore } from '@reduxjs/toolkit';

import alertReducer from './features/alertSlice';
import accountsReducer from './features/accountsSlice';
import authReducer from './features/authSlice';
import classesReducer from './features/classesSlice';
import studentsReducer from './features/studentsSlice';
import usersReducer from './features/usersSlice';
import feeReducer from './features/feesSlice';
import inventoryReducer from './features/inventorySlice';
import branchesReducer from './features/branchSlice';
import colorReducer from './features/colorSlice';
import inactiveReducer from './features/inactiveSlice';
import occupationReducer from './features/occupationSlice';
import expenseReducer from './features/expenseSlice';
import subjectsReducer from './features/subjectsSlice';
import examinationReducer from './features/examinationSlice';
import groupReducer from './features/groupSlice';
import itemReducer from './features/itemSlice';
import nonExpensePaymentsReducer from './features/nonExpenseSlice';
import feeSlice from './features/feeSlice';
import studentItemSlice from './features/studentItemSlice';
import rolesSlice from './features/rolesSlice';

export default configureStore({
  reducer: {
    alert: alertReducer,
    accounts: accountsReducer,
    auth: authReducer,
    classes: classesReducer,
    students: studentsReducer,
    users: usersReducer,
    fees: feeReducer,
    inventory: inventoryReducer,
    branches: branchesReducer,
    color: colorReducer,
    inActiveReasons: inactiveReducer,
    occupations: occupationReducer,
    expense: expenseReducer,
    subjects: subjectsReducer,
    examination: examinationReducer,
    group: groupReducer,
    item: itemReducer,
    nonExpensePayments: nonExpensePaymentsReducer,
    fee: feeSlice,
    studentItem: studentItemSlice,
    roles: rolesSlice,
  },
});
