import { UPLOAD_IMAGE_URL } from 'app/utils/constants/api/constants';
import axios from 'axios';
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';
import '@jumbo/services/auth/firebase/firebase';

export const uploadImage = async formData => {
  try {
    const {
      data: { status, data, message },
    } = await axios.post(UPLOAD_IMAGE_URL, formData);
    if (status) {
      return { data, message };
    }
    return { message };
  } catch (error) {
    console.log(error);
    return error.response?.data;
  }
};

export const uploadImageCloudinary = async file => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', 'x4hqoyrq');
    let data = '';

    await axios
      .post('https://api.cloudinary.com/v1_1/dlvihnop1/image/upload', file)
      .then(res => {
        console.log(res.data);
        data = res.data.secure_url;
      });

    return data;
  } catch (error) {
    console.log(error.response.data.error.message);
  }
};

export const convertToBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

export async function uploadToStorage(file, bucketName = 'IMAGES') {
  const storage = getStorage();
  try {
    const storageRef = ref(storage, `${bucketName}/${bucketName}_${file.name}`);
    const snapshot = await uploadBytesResumable(storageRef, file);
    const url = await getDownloadURL(snapshot.ref);
    return url;
  } catch (error) {
    console.error(error);
    return error;
  }
}
