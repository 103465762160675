import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  API_DELETE,
  API_GET,
  API_POST,
  API_PUT,
} from 'app/utils/constants/api/apiRequests';
import { setLoading, setLoadingFinished } from './loadingSlice';
import { showAlert } from './alertSlice';

const initialState = {
  items: [],
  isDataFetched: false,
};

export const getAllItems = createAsyncThunk(
  'item/getAllItems',
  async (_, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_GET('/item');

      if (status) {
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const addNewItem = createAsyncThunk(
  'item/addNewItem',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, message } = await API_POST('/item', payload);
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllItems());
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const editItem = createAsyncThunk(
  'item/editItem',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    const dataToSend = JSON.parse(JSON.stringify(payload));
    delete dataToSend['id'];
    delete dataToSend['createdAt'];
    delete dataToSend['updatedAt'];
    delete dataToSend['deletedAt'];

    try {
      const { status, data, message } = await API_PUT(
        '/item',
        payload.id,
        dataToSend,
      );
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllItems());
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const deleteItem = createAsyncThunk(
  'item/deleteItem',
  async (id, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_DELETE('/item', id);
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllItems());
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const itemSlice = createSlice({
  name: 'item',
  initialState,

  extraReducers: builder => {
    builder.addCase(getAllItems.fulfilled, (state, { payload }) => {
      state.items = payload;
      state.isDataFetched = true;
    });
  },
});

export default itemSlice.reducer;
