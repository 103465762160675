import { useDispatch } from 'react-redux';

import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { Form, Formik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import Div from '@jumbo/shared/Div';

import * as yup from 'yup';

import {
  addNewExpenseCategory,
  updateExpenseCategory,
} from 'app/store/features/expenseSlice';

const ExpenseCategoryForm = ({ expenseData }) => {
  const dispatch = useDispatch();
  const { hideDialog } = useJumboDialog();

  const isUpdate = expenseData ? true : false;
  const initialValues = {
    title: '',
    status: true,
  };

  const validationSchema = yup.object().shape({
    title: yup.string().required('Expense Category Title is Required'),
  });
  const addExpense = data => {
    dispatch(addNewExpenseCategory(data));
    hideDialog();
  };

  const updateExpense = data => {
    dispatch(updateExpenseCategory(data));
    hideDialog();
  };

  const onClassSave = data => {
    isUpdate ? updateExpense(data) : addExpense(data);
  };
  return (
    <Formik
      validateOnChange={true}
      initialValues={expenseData?.id ? expenseData : initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={onClassSave}>
      {({ isSubmitting }) => (
        <Form noValidate autoComplete="off">
          <Div
            sx={{
              '& .MuiTextField-root': {
                mb: 3,
              },
            }}>
            <JumboTextField
              name="title"
              label="Title"
              variant="outlined"
              margin="normal"
              fullWidth
            />

            <Div
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
              }}>
              <LoadingButton
                variant="contained"
                size="large"
                sx={{
                  mb: 3,
                  backgroundColor: '#E44A77',
                  ':hover': {
                    backgroundColor: '#e44a77',
                  },
                }}
                onClick={() => hideDialog()}>
                Close
              </LoadingButton>
              <LoadingButton
                type="submit"
                variant="contained"
                size="large"
                sx={{ mb: 3 }}
                loading={isSubmitting}>
                Save
              </LoadingButton>
            </Div>
          </Div>
        </Form>
      )}
    </Formik>
  );
};

export default ExpenseCategoryForm;
