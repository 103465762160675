import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  API_DELETE,
  API_GET,
  API_POST,
  API_PUT,
} from 'app/utils/constants/api/apiRequests';
import { setLoading, setLoadingFinished } from './loadingSlice';
import { showAlert } from './alertSlice';

const initialState = {
  fee: [],
  isDataFetched: false,
};

export const getAllFee = createAsyncThunk(
  'fee/getAllFee',
  async (_, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_GET('/fee');

      if (status) {
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const addNewFee = createAsyncThunk(
  'fee/addNewFee',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, message } = await API_POST('/fee', payload);
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllFee());
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const editFee = createAsyncThunk(
  'fee/editFee',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    const dataToSend = {
      student_id: payload.student_id,
      status: payload.status,
      month: payload.month,
      year: payload.year,
      total: payload.total,
    };
    try {
      const { status, data, message } = await API_PUT(
        '/fee',
        payload.id,
        dataToSend,
      );
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllFee());
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const deleteFee = createAsyncThunk(
  'fee/deleteFee',
  async (id, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_DELETE('/fee', id);
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllFee());
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const colorSlice = createSlice({
  name: 'color',
  initialState,

  extraReducers: builder => {
    builder.addCase(getAllFee.fulfilled, (state, { payload }) => {
      state.fee = payload;
      state.isDataFetched = true;
    });
  },
});

export default colorSlice.reducer;
