import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
  Avatar,
  Stack,
  Tooltip,
} from '@mui/material';
import {
  Person,
  MoreHoriz,
  Edit,
  Delete,
  Man,
  Woman,
  LocationCity,
  Visibility,
} from '@mui/icons-material';

import styled from '@emotion/styled';

import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import JumboDdMenu from '@jumbo/components/JumboDdMenu';
import Span from '@jumbo/shared/Span';
import JumboListItem from '@jumbo/components/JumboList/components/JumboListItem';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import JumboGridItem from '@jumbo/components/JumboList/components/JumboGridItem';

import { deleteStudent } from 'app/store/features/studentsSlice';
import { titlalize } from 'app/utils/appHelpers';

const Item = styled(Span)(({ theme }) => ({
  minWidth: 0,
  flexGrow: 0,
  padding: theme.spacing(0, 1),
}));

const StudentItem = ({ contact, view }) => {
  const { showDialog, hideDialog } = useJumboDialog();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const Swal = useSwalWrapper();
  const user = useSelector(state => state.auth.user);

  const isUserHasEditAccess =
    user?.permissions['student']?.edit || user?.user_type === 'admin';
  const isUserHasDeleteAccess =
    user?.permissions['student']?.delete || user?.user_type === 'admin';
  const isUserHasListingAccess =
    user?.permissions['student']?.listing || user?.user_type === 'admin';

  const showContactDetail = React.useCallback(() => {
    navigate(`/app/student/${contact?.id}`);
  }, [navigate, contact?.id]);

  const handleDeleteStudent = contact => {
    dispatch(deleteStudent(contact));
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Class has been deleted successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
    hideDialog();
  };

  const handleItemAction = menuItem => {
    switch (menuItem.action) {
      case 'view':
        navigate(`/app/student/${contact?.id}`);
        break;
      case 'edit':
        navigate('/app/students/new', { state: contact });
        break;
      case 'delete':
        showDialog({
          variant: 'confirm',
          title: 'Are you sure about deleting this student?',
          content: "You won't be able to recover this student later",
          onYes: () => handleDeleteStudent(contact),
          onNo: hideDialog,
        });
        break;
      default:
        break;
    }
  };

  if (view === 'grid') {
    return (
      <JumboGridItem xs={12} md={6} lg={4}>
        <Card variant="outlined" elevation={0}>
          <CardHeader
            avatar={
              <Avatar
                sx={{ width: 48, height: 48 }}
                alt={titlalize(contact?.first_name)}
                src={contact.profile_pic}
              />
            }
            action={
              <>
                {(isUserHasEditAccess ||
                  isUserHasListingAccess ||
                  isUserHasDeleteAccess) && (
                  <JumboDdMenu
                    icon={<MoreHoriz />}
                    menuItems={[
                      isUserHasListingAccess && {
                        icon: <Visibility />,
                        title: 'View',
                        action: 'view',
                      },
                      isUserHasEditAccess && {
                        icon: <Edit />,
                        title: 'Edit',
                        action: 'edit',
                      },
                      isUserHasDeleteAccess && {
                        icon: <Delete />,
                        title: 'Delete',
                        action: 'delete',
                      },
                    ]}
                    onClickCallback={handleItemAction}
                  />
                )}
              </>
            }
            title={
              <Typography variant={'h6'} color={'text.primary'} mb={0.25}>
                {`${titlalize(contact?.first_name)} ${
                  contact?.last_name && titlalize(contact?.last_name)
                }`}
              </Typography>
            }
            subheader={
              <Span>
                <Typography variant={'body1'} color={'text.secondary'}>
                  {contact?.cnic}
                </Typography>
                <Typography variant={'body1'} color={'text.secondary'}>
                  Section {contact?.section?.name}, {contact?.branch?.name}
                </Typography>
              </Span>
            }
          />
          <CardContent sx={{ pt: 0 }}>
            <Divider sx={{ mb: 2 }} />
            <List disablePadding>
              <ListItem
                sx={{
                  p: theme => theme.spacing(0.75, 1.5),
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Tooltip title="Father's name, mobile number, CNIC and Qualification">
                  <Span sx={{ display: 'flex' }}>
                    <ListItemIcon sx={{ minWidth: 50 }}>
                      <Man />
                    </ListItemIcon>
                    <ListItemText
                      primary={contact?.father_name}
                      secondary={
                        <Span>
                          <Typography variant="body2">
                            {contact?.father_mobile_number}
                          </Typography>
                          <Typography variant="body2">
                            {contact.father_cnic}
                          </Typography>
                          <Typography variant="body2">
                            {contact.father_qualification}
                          </Typography>
                        </Span>
                      }
                    />
                  </Span>
                </Tooltip>
              </ListItem>

              <ListItem
                sx={{
                  p: theme => theme.spacing(0.75, 1.5),
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Tooltip title="Mother's name, mobile number, CNIC and Qualification">
                  <Span sx={{ display: 'flex' }}>
                    <ListItemIcon sx={{ minWidth: 50 }}>
                      <Woman />
                    </ListItemIcon>
                    <ListItemText
                      primary={contact?.mother_name}
                      secondary={
                        <Span>
                          <Typography variant="body2">
                            {contact?.mother_mobile_number}
                          </Typography>
                          <Typography variant="body2">
                            {contact.mother_cnic}
                          </Typography>
                          <Typography variant="body2">
                            {contact.mother_qualification}
                          </Typography>
                        </Span>
                      }
                    />
                  </Span>
                </Tooltip>
              </ListItem>

              <ListItem
                sx={{
                  p: theme => theme.spacing(0.75, 1.5),
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Tooltip title="Address Tehsil, District">
                  <Span sx={{ display: 'flex' }}>
                    <ListItemIcon sx={{ minWidth: 50 }}>
                      <LocationCity />
                    </ListItemIcon>
                    <ListItemText
                      primary={contact.address}
                      secondary={
                        <Typography variant="body2" color="gray">
                          {contact?.tehsil}, {contact?.district}
                        </Typography>
                      }
                    />
                  </Span>
                </Tooltip>
              </ListItem>

              <Tooltip title="Class Section">
                <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                  <ListItemIcon sx={{ minWidth: 50 }}>
                    <Person />
                  </ListItemIcon>
                  <ListItemText
                    primary={contact?.class_section?.class_section_title}
                  />
                </ListItem>
              </Tooltip>
            </List>
          </CardContent>
        </Card>
      </JumboGridItem>
    );
  }
  return (
    <JumboListItem
      componentElement={'div'}
      itemData={contact}
      secondaryAction={
        <>
          {(isUserHasDeleteAccess ||
            isUserHasEditAccess ||
            isUserHasListingAccess) && (
            <JumboDdMenu
              icon={<MoreHoriz />}
              menuItems={[
                isUserHasListingAccess && {
                  icon: <Visibility />,
                  title: 'View',
                  action: 'view',
                },
                isUserHasEditAccess && {
                  icon: <Edit />,
                  title: 'Edit',
                  action: 'edit',
                },
                isUserHasDeleteAccess && {
                  icon: <Delete />,
                  title: 'Delete',
                  action: 'delete',
                },
              ]}
              onClickCallback={handleItemAction}
            />
          )}
        </>
      }
      sx={{
        cursor: 'pointer',
        borderTop: 1,
        borderColor: 'divider',
        '&:hover': {
          bgcolor: 'action.hover',
        },
      }}>
      <ListItemText
        onClick={showContactDetail}
        primary={
          <Stack direction={'row'} alignItems={'center'} sx={{ minWidth: 0 }}>
            <Item
              sx={{
                flexBasis: { xs: '100%', sm: '50%', md: '25%' },
              }}>
              <Tooltip title="Student's Name">
                <Typography
                  variant={'h5'}
                  fontSize={14}
                  lineHeight={1.25}
                  mb={0}
                  noWrap>
                  {`${contact?.first_name} ${contact?.last_name}`}
                </Typography>
              </Tooltip>
            </Item>
            <Item
              sx={{
                flexBasis: { xs: '100%', sm: '50%', md: '25%' },
              }}>
              <Tooltip title="Student's Gender">
                <Typography
                  variant={'h5'}
                  fontSize={14}
                  lineHeight={1.25}
                  mb={0}
                  noWrap>
                  {contact?.gender === '0' ? 'Male' : 'Female'}
                </Typography>
              </Tooltip>
            </Item>
            <Item
              sx={{
                flexBasis: { sm: '50%', md: '28%' },
                display: { xs: 'none', sm: 'block' },
              }}>
              <Tooltip title="Father's Name & Mother's Name">
                <Typography variant={'body1'} noWrap>
                  {contact?.father_name} & {contact?.mother_name}
                </Typography>
              </Tooltip>
            </Item>
            <Item
              sx={{
                flexBasis: { md: '25%' },
                display: { xs: 'none', md: 'block' },
              }}>
              <Tooltip title="Class Section Title">
                <Typography variant={'body1'} noWrap>
                  {contact?.class_section?.class_section_title}
                </Typography>
              </Tooltip>
            </Item>
            <Item
              sx={{
                flexBasis: { md: '25%' },
                display: { xs: 'none', md: 'block' },
              }}>
              <Tooltip title="Date of Birth">
                <Typography variant={'body1'} noWrap>
                  {contact?.date_of_birth}
                </Typography>
              </Tooltip>
            </Item>
            <Item
              sx={{
                flexBasis: { md: '25%' },
                display: { xs: 'none', md: 'block' },
              }}>
              <Tooltip title="Medium">
                <Typography variant={'body1'} noWrap>
                  {contact?.medium[0].toUpperCase() + contact?.medium.slice(1)}
                </Typography>
              </Tooltip>
            </Item>
          </Stack>
        }
      />
    </JumboListItem>
  );
};

export default StudentItem;
