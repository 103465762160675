import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { Form, Formik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import Div from '@jumbo/shared/Div';
import JumboDropDown from '@jumbo/components/JumboFormik/JumboDropDown';

import { nonExpensePaymentSchema } from '../../utils/schema';
import {
  addNewNonExpensePayment,
  editNonExpensePayment,
} from 'app/store/features/nonExpenseSlice';
import { getAllUsers } from 'app/store/features/usersSlice';
import { FormControlLabel, Switch } from '@mui/material';

const NonExpensePaymentForm = ({ item }) => {
  const Swal = useSwalWrapper();
  const dispatch = useDispatch();
  const storeUsers = useSelector(state => state.users.users);
  const isUsersFetched = useSelector(state => state.users.isDataFetched);
  const { user } = useSelector(state => state.auth);

  const fetchData = useCallback(async () => {
    if (!isUsersFetched) dispatch(getAllUsers());
  }, [dispatch, isUsersFetched]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const { hideDialog } = useJumboDialog();

  const isUpdate = item ? true : false;
  const initialValues = {
    paid_by_id: item ? item.paid_by.name : user?.name,
    paid_to: item ? item.paid_to : '',
    paid_amount: item ? item.paid_amount : '',
    payment_mode: item ? item.payment_mode : '',
    description: item ? item.description : '',
    is_approved: item ? item.is_approved : false,
  };

  const addPaymentHandler = data => {
    const payload = {
      ...data,
    };

    if (typeof payload.paid_by_id === 'string') {
      payload['paid_by_id'] = user?.id;
    }
    dispatch(addNewNonExpensePayment(payload));
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Non Expense Payment has been added successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
    hideDialog();
  };

  const updatePaymentHandler = data => {
    if (typeof data.paid_by_id === 'string' || !data.paid_by_id) {
      data['paid_by_id'] = user?.id;
    }

    dispatch(editNonExpensePayment(data));
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Non Expense Payment has been updated successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
    hideDialog();
  };

  const onItemSave = (data, { setSubmitting }) => {
    setSubmitting(true);
    isUpdate ? updatePaymentHandler(data) : addPaymentHandler(data);
    setSubmitting(false);
  };
  return (
    <Formik
      validateOnChange={true}
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={nonExpensePaymentSchema}
      onSubmit={onItemSave}>
      {({ isSubmitting, values, setFieldValue }) => (
        <Form noValidate autoComplete="off">
          <Div
            sx={{
              '& .MuiTextField-root': {
                mb: 3,
              },
            }}>
            {/* {user?.user_type === 'admin' ? (
              <JumboDropDown
                fullWidth
                size="small"
                variant="outlined"
                name="paid_by_id"
                label="Paid By?*"
                options={storeUsers}
              />
            ) : (
              <JumboTextField
                fullWidth
                size="small"
                variant="outlined"
                name="paid_by_id"
                label={`Paid By: ${user?.name}`}
                disabled={true}
                value={user?.name}
              />
            )} */}
            <JumboTextField
              fullWidth
              size="small"
              variant="outlined"
              name="paid_by_id"
              label={`Paid By*`}
              disabled={true}
              value={user?.name}
            />
            <JumboTextField
              fullWidth
              size="small"
              variant="outlined"
              name="paid_to"
              label="Paid To*"
            />
            <JumboTextField
              fullWidth
              type="number"
              size="small"
              variant="outlined"
              name="paid_amount"
              label="Paid Amount*"
            />

            <JumboDropDown
              fullWidth
              size="small"
              variant="outlined"
              name="payment_mode"
              label="Payment Mode?*"
              options={[
                { label: 'Cash', value: 'cash' },
                { label: 'Cheque', value: 'cheque' },
                { label: 'Others', value: 'others' },
              ]}
            />
            <JumboTextField
              fullWidth
              size="small"
              variant="outlined"
              name="description"
              label="Description*"
            />

            <FormControlLabel
              control={
                <Switch
                  checked={values.is_approved}
                  onChange={e => setFieldValue('is_approved', e.target.checked)}
                />
              }
              label={'Is Payment Approved?'}
              sx={{ mb: 2 }}
            />

            <Div
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
              }}>
              <LoadingButton
                type="submit"
                variant="contained"
                size="large"
                sx={{
                  mb: 3,
                  backgroundColor: '#E44A77',
                  ':hover': {
                    backgroundColor: '#e44a77',
                  },
                }}
                onClick={() => hideDialog()}>
                Close
              </LoadingButton>
              <LoadingButton
                type="submit"
                variant="contained"
                size="large"
                sx={{ mb: 3 }}
                loading={isSubmitting}>
                {item ? 'Update' : 'Save'}
              </LoadingButton>
            </Div>
          </Div>
        </Form>
      )}
    </Formik>
  );
};

export default NonExpensePaymentForm;
