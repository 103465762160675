import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { useJumboApp } from '@jumbo/hooks';
import Div from '@jumbo/shared/Div';
import { LAYOUT_NAMES } from '../../../layouts/layouts';

import FeesAnalytics from './components/FeesAnalytics';
import BranchesAnalytics from './components/BranchesAnalytics';
import Summary from './components/Summary';
import Header from './components/Header';

const AnalyticsDashboard = () => {
  const students = useSelector(state => state.students.students);
  const users = useSelector(state => state.users.users);
  const { setActiveLayout } = useJumboApp();

  useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
    <Grid container spacing={3.75}>
      <Typography variant="h3">Analytics</Typography>
      <Grid item xs={12}>
        <Header title="Students Analytics" link="/app/students/all" />
        <Summary data={students} />
      </Grid>
      <Grid item xs={12}>
        <Header title="Staff Analytics" link="/app/users/all" />
        <Summary data={users} isStaff />
      </Grid>

      <Grid item xs={12} marginY={2}>
        <Header title="Fees Analytics" link="/app/fees/all" />
        <FeesAnalytics />
      </Grid>
      <Grid item xs={12} marginY={2}>
        <Div
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <Typography variant="h3">Top Performing Branch</Typography>
          <Link to="/dashboards/accounts">View Details</Link>
        </Div>
        <BranchesAnalytics />
      </Grid>
    </Grid>
  );
};

export default AnalyticsDashboard;
