import {
  Typography,
  Tooltip,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import { AccountBalance } from '@mui/icons-material';
import styled from '@emotion/styled';

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 24,
  height: 48,
  width: 48,
  borderRadius: '50%',
  minWidth: 42,
  marginRight: 16,
  padding: theme.spacing(1),
  alignItems: 'center',
  justifyContent: 'center',
  border: `solid 1px ${theme.palette.divider}`,
}));

const FeesOverview = ({ student: { feeHistory = [] } }) => {
  return (
    <>
      {feeHistory?.map(item => (
        <ListItem
          sx={{
            width: { xs: '100%', sm: '50%', xl: '33.33%' },
          }}>
          <StyledListItemIcon>
            <AccountBalance fontSize={'inherit'} />
          </StyledListItemIcon>
          <ListItemText
            primary={
              <Tooltip title="Month">
                <Typography
                  fontSize={'12px'}
                  variant="h6"
                  color="text.secondary"
                  mb={0.5}>
                  {item.month}
                </Typography>
              </Tooltip>
            }
            secondary={
              <Tooltip title="Paid Fee">
                <Typography variant="body1" color="text.primary">
                  Rs. {item.fee}
                </Typography>
              </Tooltip>
            }
          />
        </ListItem>
      ))}
    </>
  );
};

export default FeesOverview;
