import React, { useCallback } from 'react';

import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import Div from '@jumbo/shared/Div';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import SubjectForm from '../SubjectForm';
import { useSelector } from 'react-redux';

const SubjectSidebar = () => {
  const user = useSelector(state => state.auth.user);
  const isUserHasAddAccess =
    user?.permissions['subject']?.add || user?.user_type === 'admin';
  const { showDialog } = useJumboDialog();

  const showAddContactDialog = useCallback(() => {
    showDialog({
      title: 'Add New Subject',
      content: <SubjectForm />,
    });
  }, [showDialog]);

  return (
    <>
      <Div
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: 3,
        }}>
        <Div sx={{ flex: '1 1 auto' }}>
          <Typography variant={'h2'} mb={0.35}>
            Subjects
          </Typography>
        </Div>
      </Div>
      {isUserHasAddAccess && (
        <Button
          fullWidth
          disableElevation
          variant={'contained'}
          startIcon={<PersonAddIcon />}
          sx={{
            mb: 2,
            '& .MuiSvgIcon-root': {
              fontSize: '1.5rem',
            },
          }}
          onClick={showAddContactDialog}>
          Add New Subject
        </Button>
      )}
    </>
  );
};
export default React.memo(SubjectSidebar);
