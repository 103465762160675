import { Avatar, Divider, Typography, Stack, Tooltip } from '@mui/material';
import ContentHeader from 'app/layouts/shared/headers/ContentHeader';
import styled from '@emotion/styled';
import { ASSET_AVATARS } from 'app/utils/constants/paths';
import { getAssetPath } from 'app/utils/appHelpers';

const Item = styled('div')({
  textAlign: 'center',
});

const Header = ({ teacher }) => {
  return (
    <ContentHeader
      avatar={
        <Avatar
          sx={{ width: 72, height: 72 }}
          alt={`${teacher?.name}`}
          src={getAssetPath(`${ASSET_AVATARS}/avatar3.jpg`, '72x72')}
        />
      }
      title={`${teacher?.name}`}
      subheader={
        <Typography fontSize={12} variant={'body1'} color={'inherit'} mt={0.5}>
          <Tooltip title="Address">
            <>{teacher?.fatherName}</>
          </Tooltip>
        </Typography>
      }
      children={
        <Stack
          direction={'row'}
          justifyContent={'space-evenly'}
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
          sx={{
            mx: 1,
          }}>
          <Item>
            <Typography variant={'h6'} color={'inherit'} mb={0}>
              {teacher?.isFavorite === 'Yes' ? 'Yes' : 'No'}
            </Typography>
            <Typography variant={'body1'} fontSize={12}>
              Favorite
            </Typography>
          </Item>
          <Item>
            <Typography variant={'h6'} color={'inherit'} mb={0}>
              {teacher?.branch}
            </Typography>
            <Typography variant={'body1'} fontSize={12}>
              Branch
            </Typography>
          </Item>
          <Item>
            <Typography variant={'h6'} color={'inherit'} mb={0}>
              {teacher?.gender}
            </Typography>
            <Typography variant={'body1'} fontSize={12}>
              Gender
            </Typography>
          </Item>
        </Stack>
      }
      sx={{
        position: 'relative',
        zIndex: 1,

        '& .MuiCardHeader-action': {
          alignSelf: 'center',
        },
      }}
    />
  );
};

export default Header;
