import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  API_DELETE,
  API_GET,
  API_POST,
  API_PUT,
} from 'app/utils/constants/api/apiRequests';
import { setLoading, setLoadingFinished } from './loadingSlice';
import { showAlert } from './alertSlice';

const initialState = {
  examInitiators: [],
  isDataFetched: false,
  examFollowUps: [],
  isExamFollowUpsFetched: false,
};

// exam initiators
export const getExamInitiators = createAsyncThunk(
  'examination/getExamInitiators',
  async (_, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_GET('/exam-initiator');

      if (status) {
        dispatch(
          showAlert({
            type: 'success',
            message: 'Exam Initiators Fetched Successfully',
          }),
        );
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const addExamInitiator = createAsyncThunk(
  'examination/addExamInitiator',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, message } = await API_POST('/exam-initiator', payload);
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getExamInitiators());
        return true;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const editInitiator = createAsyncThunk(
  'examination/editInitiator',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    const dataToSend = JSON.parse(JSON.stringify(payload));
    delete dataToSend['id'];
    try {
      const { status, data, message } = await API_PUT(
        '/exam-initiator',
        payload.id,
        dataToSend,
      );
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getExamInitiators());
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const deleteInitiator = createAsyncThunk(
  'examination/deleteInitiator',
  async (id, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_DELETE('/exam-initiator', id);
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getExamInitiators());
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

// exam follow ups
export const getExamFollowUps = createAsyncThunk(
  'examination/getExamFollowUps',
  async (_, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_GET('/exam-task-followup');

      if (status) {
        dispatch(
          showAlert({
            type: 'success',
            message: 'Exam Follow Ups Fetched Successfully',
          }),
        );
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const addExamFollowUp = createAsyncThunk(
  'examination/addExamFollowUp',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, message } = await API_POST(
        '/exam-task-followup',
        payload,
      );
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getExamFollowUps());
        return true;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const editExamFollowUp = createAsyncThunk(
  'examination/editExamFollowUp',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    const dataToSend = JSON.parse(JSON.stringify(payload));
    delete dataToSend['id'];
    try {
      const { status, data, message } = await API_PUT(
        '/exam-task-followup',
        payload.id,
        dataToSend,
      );
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getExamFollowUps());
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const deleteExamFollowUp = createAsyncThunk(
  'examination/deleteExamFollowUp',
  async (id, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_DELETE(
        '/exam-task-followup',
        id,
      );
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getExamFollowUps());
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const examinationSlice = createSlice({
  name: 'examination',
  initialState,

  extraReducers: builder => {
    builder.addCase(getExamInitiators.fulfilled, (state, { payload }) => {
      state.examInitiators = payload;
      state.isDataFetched = true;
    });
    builder.addCase(getExamFollowUps.fulfilled, (state, { payload }) => {
      state.examFollowUps = payload;
      state.isExamFollowUpsFetched = true;
    });
  },
});

export default examinationSlice.reducer;
