import { Navigate } from 'react-router-dom';
import dashboardRoutes from './dashboardRoutes';
import appsRoutes from './appsRoutes';
import authRoutes from './authRoutes';

import Auth from './middleware/Auth';

const user = JSON.parse(localStorage.getItem('fotanistanUser') || '{}');

const routes = [
  {
    path: '/',
    element:
      user?.user_type === 'admin' ? (
        <Navigate to={'/dashboards/analytics'} />
      ) : (
        <Navigate to={'/welcome'} />
      ),
  },
  {
    middleware: [{ element: Auth, fallbackUI: '/auth/login' }],
    routes: dashboardRoutes,
  },
  {
    middleware: [{ element: Auth, fallbackUI: '/auth/login' }],
    routes: appsRoutes,
  },
  ...authRoutes,
];

export default routes;
