import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  API_DELETE,
  API_GET,
  API_POST,
  API_PUT,
} from 'app/utils/constants/api/apiRequests';
import { setLoading, setLoadingFinished } from './loadingSlice';
import { showAlert } from './alertSlice';

const initialState = {
  students: [],
  isDataFetched: false,
  attendances: [],
  isAttendancesFetched: false,
};

// student
export const getAllStudents = createAsyncThunk(
  'students/getAllStudents',
  async (_, { dispatch, getState }) => {
    dispatch(setLoading());
    try {
      const { data, status } = await API_GET('/student');
      if (status) {
        dispatch(
          showAlert({
            type: 'success',
            message: 'Students Fetched Successfully',
          }),
        );
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const addNewStudent = createAsyncThunk(
  'students/addNewStudent',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, message } = await API_POST('/student', payload);
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllStudents());
        window.location.replace('/app/students/all');
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const updateStudent = createAsyncThunk(
  'students/updateStudent',
  async (payload, { dispatch }) => {
    const dataToSend = { ...payload };
    delete dataToSend['id'];
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_PUT(
        '/student',
        payload.id,
        dataToSend,
      );
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        window.location.replace('/app/students/all');
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const deleteStudent = createAsyncThunk(
  'students//deleteStudent',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_DELETE(
        '/student',
        payload.id,
      );
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllStudents());
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

// attendance
export const getAttendance = createAsyncThunk(
  'students/getAttendance',
  async (_, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { data, status } = await API_GET('/attendence');
      if (status) {
        dispatch(
          showAlert({
            type: 'success',
            message: 'Attendance Fetched Successfully',
          }),
        );
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);
export const addAttendance = createAsyncThunk(
  'students/addAttendance',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, message } = await API_POST('/attendence', payload);
      if (status) {
        dispatch(
          showAlert({
            type: 'success',
            message: 'Attendance Added Successfully',
          }),
        );
        dispatch(getAttendance());
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const studentsSlice = createSlice({
  name: 'students',
  initialState,
  extraReducers: builder => {
    builder.addCase(getAllStudents.fulfilled, (state, { payload }) => {
      state.students = payload;
      state.isDataFetched = true;
    });
    builder.addCase(getAttendance.fulfilled, (state, { payload }) => {
      state.attendances = payload;
      state.isAttendancesFetched = true;
    });
  },
});

export default studentsSlice.reducer;
