import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from 'recharts';

import { calculateGraph } from '../utils/calculations';

const TinyBarChart = ({ data, isStaff }) => {
  let results = [];

  if (isStaff) {
    results = calculateGraph(data, isStaff);
  } else {
    results = calculateGraph(data);
  }

  return (
    <>
      <ResponsiveContainer width="100%" height={200}>
        <BarChart
          data={results}
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
          <XAxis dataKey="name" />

          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip
            labelStyle={{ color: 'black' }}
            itemStyle={{ color: 'black' }}
            cursor={false}
          />
          <Legend />
          <defs>
            <linearGradient x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#6200EE" stopOpacity={1} />
              <stop offset="95%" stopColor="#B819D2" stopOpacity={1} />
            </linearGradient>
          </defs>
          <defs>
            <linearGradient x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#1ABBDE" stopOpacity={1} />
              <stop offset="95%" stopColor="#09BCA7" stopOpacity={1} />
            </linearGradient>
          </defs>
          <Bar dataKey="Males" barSize={20} fill={'#3BD2A2'} />
          <Bar dataKey="Females" barSize={20} fill={'#e91e63'} />
          {isStaff ? (
            <>
              <Bar dataKey="Non Teaching Male" barSize={20} fill={'#2FA881'} />
              <Bar
                dataKey={'Non Teaching Female'}
                barSize={20}
                fill={'#c0134e'}
              />
            </>
          ) : null}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default TinyBarChart;
