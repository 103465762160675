import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import Div from '@jumbo/shared/Div';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';

import ExpenseForm from '../ExpenseForm';
import ExpenseCategoryForm from '../ExpenseCategoryForm';

const AccountsSidebar = () => {
  const navigate = useNavigate('');
  const { showDialog } = useJumboDialog();
  const user = useSelector(state => state.auth.user);
  const isUserHasAddBillPermission =
    user?.user_type === 'admin' || user.permissions['bill']?.add || false;
  const isUserHasAddExpensePermission =
    user?.user_type === 'admin' || user.permissions['expense']?.add || false;
  const isUserHasAddExpenseCategoryPermission =
    user?.user_type === 'admin' ||
    user.permissions['expense_category']?.add ||
    false;

  const showAddExpense = useCallback(() => {
    showDialog({
      title: 'Add New Expense',
      content: <ExpenseForm />,
    });
  }, [showDialog]);

  const showAddExpenseCategory = useCallback(() => {
    showDialog({
      title: 'Add New Expense Category',
      content: <ExpenseCategoryForm />,
    });
  }, [showDialog]);

  return (
    <>
      <Div
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: 3,
        }}>
        <Div sx={{ flex: '1 1 auto' }}>
          <Typography variant={'h2'} mb={0.35}>
            Expenses
          </Typography>
        </Div>
      </Div>

      {isUserHasAddBillPermission && (
        <Button
          fullWidth
          disableElevation
          variant={'contained'}
          startIcon={<PersonAddIcon />}
          sx={{
            mb: 2,
            '& .MuiSvgIcon-root': {
              fontSize: '1.5rem',
            },
          }}
          onClick={() => navigate('/app/accounts/add-bill')}>
          Add New Bill
        </Button>
      )}

      {isUserHasAddExpensePermission && (
        <Button
          fullWidth
          disableElevation
          variant={'contained'}
          startIcon={<PersonAddIcon />}
          sx={{
            mb: 2,
            '& .MuiSvgIcon-root': {
              fontSize: '1.5rem',
            },
          }}
          onClick={showAddExpense}>
          Add New Expense
        </Button>
      )}
      {isUserHasAddExpenseCategoryPermission && (
        <Button
          fullWidth
          disableElevation
          variant={'contained'}
          sx={{
            mb: 2,
            '& .MuiSvgIcon-root': {
              fontSize: '1.5rem',
            },
          }}
          onClick={showAddExpenseCategory}>
          + Expense Category
        </Button>
      )}
    </>
  );
};
export default memo(AccountsSidebar);
