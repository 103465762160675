export const months = [
  {
    id: 1,
    label: 'January',
    value: 'Jan',
  },
  {
    id: 2,
    label: 'February',
    value: 'Feb',
  },
  {
    id: 3,
    label: 'March',
    value: 'Mar',
  },
  {
    id: 4,
    label: 'April',
    value: 'Apr',
  },
  {
    id: 5,
    label: 'May',
    value: 'May',
  },
  {
    id: 6,
    label: 'June',
    value: 'Jun',
  },
  {
    id: 7,
    label: 'July',
    value: 'Jul',
  },
  {
    id: 8,
    label: 'August',
    value: 'Aug',
  },
  {
    id: 9,
    label: 'September',
    value: 'Sep',
  },
  {
    id: 10,
    label: 'October',
    value: 'Oct',
  },
  {
    id: 11,
    label: 'November',
    value: 'Nov',
  },
  {
    id: 12,
    label: 'December',
    value: 'Dec',
  },
];

export const tempBranches = [
  {
    id: 1,
    label: 'Lahore',
    value: 'Lahore',
  },
  {
    id: 2,
    label: 'Karachi',
    value: 'Karachi',
  },
  {
    id: 3,
    label: 'Gujranwala',
    value: 'Gujranwala',
  },
];

export const IMAGE_SUPPORTED_FORMATS = [
  'image/jpg',
  'image/png',
  'image/jpeg',
  'image/svg',
];

export const COLORS = [
  {
    id: 1,
    title: 'Favorite',
    description: '',
    color: '#16a34a',
    createdAt: '2022-11-05T19:10:31.000Z',
    updatedAt: '2022-11-05T19:10:31.000Z',
    deletedAt: null,
  },
  {
    id: 2,
    title: 'Rare Favorite',
    description: '',
    color: '#a21caf',
    createdAt: '2022-11-05T19:10:53.000Z',
    updatedAt: '2022-11-05T19:10:53.000Z',
    deletedAt: null,
  },
  {
    id: 3,
    title: 'Common',
    description: '',
    color: '#fda4af',
    createdAt: '2022-11-05T19:11:05.000Z',
    updatedAt: '2022-11-05T19:11:05.000Z',
    deletedAt: null,
  },
];

export const STATUS = [
  {
    label: 'Yes',
    value: 'Yes',
  },
  {
    label: 'No',
    value: 'No',
  },
];

export const CLASSES = [
  {
    id: 7,
    name: 'Nersary',
    user_class_code: 1,
    status: true,
    createdAt: '2022-11-05T19:05:39.000Z',
    updatedAt: '2022-11-05T19:05:39.000Z',
    deletedAt: null,
  },
  {
    id: 8,
    name: 'Prep',
    user_class_code: 1,
    status: true,
    createdAt: '2022-11-05T19:05:46.000Z',
    updatedAt: '2022-11-05T19:05:46.000Z',
    deletedAt: null,
  },
  {
    id: 9,
    name: 'One',
    user_class_code: 1,
    status: true,
    createdAt: '2022-11-05T19:05:51.000Z',
    updatedAt: '2022-11-05T19:05:51.000Z',
    deletedAt: null,
  },
  {
    id: 10,
    name: 'Two',
    user_class_code: 1,
    status: true,
    createdAt: '2022-11-05T19:05:56.000Z',
    updatedAt: '2022-11-05T19:05:56.000Z',
    deletedAt: null,
  },
  {
    id: 11,
    name: 'Three',
    user_class_code: 1,
    status: true,
    createdAt: '2022-11-05T19:06:02.000Z',
    updatedAt: '2022-11-05T19:06:02.000Z',
    deletedAt: null,
  },
  {
    id: 12,
    name: 'Four',
    user_class_code: 1,
    status: true,
    createdAt: '2022-11-05T19:06:09.000Z',
    updatedAt: '2022-11-05T19:06:09.000Z',
    deletedAt: null,
  },
  {
    id: 14,
    name: 'Five',
    user_class_code: 1,
    status: true,
    createdAt: '2022-11-05T19:06:18.000Z',
    updatedAt: '2022-11-05T19:06:18.000Z',
    deletedAt: null,
  },
  {
    id: 15,
    name: 'Six',
    user_class_code: 1,
    status: true,
    createdAt: '2022-11-05T19:06:34.000Z',
    updatedAt: '2022-11-05T19:06:34.000Z',
    deletedAt: null,
  },
  {
    id: 16,
    name: 'Seven',
    user_class_code: 1,
    status: true,
    createdAt: '2022-11-05T19:06:40.000Z',
    updatedAt: '2022-11-05T19:06:40.000Z',
    deletedAt: null,
  },
  {
    id: 17,
    name: 'Eight',
    user_class_code: 1,
    status: true,
    createdAt: '2022-11-05T19:06:47.000Z',
    updatedAt: '2022-11-05T19:06:47.000Z',
    deletedAt: null,
  },
  {
    id: 18,
    name: 'Nine',
    user_class_code: 1,
    status: true,
    createdAt: '2022-11-05T19:06:55.000Z',
    updatedAt: '2022-11-05T19:06:55.000Z',
    deletedAt: null,
  },
  {
    id: 19,
    name: 'Ten',
    user_class_code: 1,
    status: true,
    createdAt: '2022-11-05T19:07:00.000Z',
    updatedAt: '2022-11-05T19:07:00.000Z',
    deletedAt: null,
  },
  {
    id: 20,
    name: 'F.Sc Part 1',
    user_class_code: 1,
    status: true,
    createdAt: '2022-11-13T13:44:54.000Z',
    updatedAt: '2022-11-13T13:44:54.000Z',
    deletedAt: null,
  },
  {
    id: 21,
    name: 'F.Sc Part 2',
    user_class_code: 1,
    status: true,
    createdAt: '2022-11-13T13:44:59.000Z',
    updatedAt: '2022-11-13T13:44:59.000Z',
    deletedAt: null,
  },
];

export const SECTIONS = [
  {
    id: 1,
    name: 'A',
    user_section_code: 1,
    status: true,
    createdAt: '2022-11-05T19:09:04.000Z',
    updatedAt: '2022-11-05T19:09:04.000Z',
    deletedAt: null,
  },
  {
    id: 2,
    name: 'B',
    user_section_code: 1,
    status: true,
    createdAt: '2022-11-05T19:09:09.000Z',
    updatedAt: '2022-11-05T19:09:09.000Z',
    deletedAt: null,
  },
  {
    id: 3,
    name: 'C',
    user_section_code: 1,
    status: true,
    createdAt: '2022-11-05T19:09:13.000Z',
    updatedAt: '2022-11-05T19:09:13.000Z',
    deletedAt: null,
  },
  {
    id: 4,
    name: 'D',
    user_section_code: 1,
    status: true,
    createdAt: '2022-11-05T19:09:19.000Z',
    updatedAt: '2022-11-05T19:09:19.000Z',
    deletedAt: null,
  },
];
export const GENDERS = [
  {
    id: 1,
    label: 'Male',
    value: 'Male',
  },
  {
    id: 2,
    label: 'Female',
    value: 'Female',
  },
];

export const USER_ROLES = [
  {
    label: 'Vendor',
    value: 'vendor',
  },
  {
    label: 'Branch Admin',
    value: 'branch-admin',
  },
  {
    label: 'Teacher',
    value: 'teacher',
  },
  {
    label: 'Account Officer',
    value: 'account-officer',
  },
];

export const SUBJECT_GROUPS = [
  {
    label: 'Science',
    value: 'Science',
  },
  {
    label: 'Arts',
    value: 'Arts',
  },
];

export const SHIFTS = [
  {
    label: 'Morning',
    value: 'morning',
  },
  {
    label: 'Evening',
    value: 'evening',
  },
];

export const EXAM_TERMS = [
  {
    label: 'Mid-term',
    value: 'mid-term',
  },
  {
    label: 'Finals',
    value: 'finals',
  },
];

export const EXAM_TYPES = [
  {
    label: 'Written',
    value: 'written',
  },
  {
    label: 'Verbal',
    value: 'verbal',
  },
];

export const FEE_TYPES = [
  {
    label: 'Tution',
    value: 'tution',
  },
  {
    label: 'Full Time',
    value: 'full-time',
  },
];

export const FEE_FREQUENCY = [
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: '3 Months',
    value: '3 months',
  },
  {
    label: 'Yearly',
    value: 'yearly',
  },
];

export const GENERIC_YES_NO = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
];

export const BILL_STATUS = [
  {
    label: 'Pending',
    value: 'pending',
  },
  {
    label: 'Paid',
    value: 'paid',
  },
];

export const BLOOD_GROUPS = [
  {
    label: "Don't know",
    value: "Don't know",
  },
  {
    label: 'O-',
    value: 'O-',
  },
  {
    label: 'O+',
    value: 'O+',
  },
  {
    label: 'A-',
    value: 'A-',
  },
  {
    label: 'A+',
    value: 'A+',
  },
  {
    label: 'B-',
    value: 'B-',
  },
  {
    label: 'B+',
    value: 'B+',
  },
  {
    label: 'AB-',
    value: 'AB-',
  },
  {
    label: 'AB+',
    value: 'AB+',
  },
];

export const RELIGIONS = [
  {
    label: 'Islam',
    value: 'Islam',
  },
  {
    label: 'Christian',
    value: 'Christian',
  },
  {
    label: 'Sikh',
    value: 'Sikh',
  },
  {
    label: 'Hindu',
    value: 'Hindu',
  },
];

export const MEDIUM = [
  {
    label: 'Urdu',
    value: 'urdu',
  },
  {
    label: 'English',
    value: 'english',
  },
];

export const VILLAGES = [
  {
    id: 1,
    name: 'Lahore',
    user_village_code: 1,
    status: true,
    createdAt: '2022-11-21T06:12:43.000Z',
    updatedAt: '2022-11-21T06:12:43.000Z',
    deletedAt: null,
  },
  {
    id: 2,
    name: 'Gujranwala',
    user_village_code: 2,
    status: true,
    createdAt: '2022-11-21T06:12:52.000Z',
    updatedAt: '2022-11-21T06:12:52.000Z',
    deletedAt: null,
  },
  {
    id: 3,
    name: 'Pattoki',
    user_village_code: 2,
    status: true,
    createdAt: '2022-11-21T06:12:57.000Z',
    updatedAt: '2022-11-21T06:12:57.000Z',
    deletedAt: null,
  },
];

export const INACTIVE_REASONS = [
  {
    id: 1,
    name: 'Can not afford',
    status: true,
    createdAt: '2022-11-21T06:31:32.000Z',
    updatedAt: '2022-11-21T06:31:32.000Z',
    deletedAt: null,
  },
];

export const OCCUPATIONS = [
  {
    id: 1,
    name: 'Teacher',
    status: true,
    createdAt: '2022-11-21T06:35:48.000Z',
    updatedAt: '2022-11-21T06:35:48.000Z',
    deletedAt: null,
  },
  {
    id: 2,
    name: 'Plumber',
    status: true,
    createdAt: '2022-11-21T06:35:54.000Z',
    updatedAt: '2022-11-21T06:35:54.000Z',
    deletedAt: null,
  },
  {
    id: 3,
    name: 'Shopkeeper',
    status: true,
    createdAt: '2022-11-21T06:36:02.000Z',
    updatedAt: '2022-11-21T06:36:02.000Z',
    deletedAt: null,
  },
  {
    id: 4,
    name: 'Businessman',
    status: true,
    createdAt: '2022-11-21T06:36:11.000Z',
    updatedAt: '2022-11-21T06:36:11.000Z',
    deletedAt: null,
  },
  {
    id: 5,
    name: 'Lawyer',
    status: true,
    createdAt: '2022-11-21T06:36:20.000Z',
    updatedAt: '2022-11-21T06:36:20.000Z',
    deletedAt: null,
  },
  {
    id: 6,
    name: 'Doctor',
    status: true,
    createdAt: '2022-11-21T06:36:25.000Z',
    updatedAt: '2022-11-21T06:36:25.000Z',
    deletedAt: null,
  },
  {
    id: 7,
    name: 'Farmer',
    status: true,
    createdAt: '2022-11-21T06:36:36.000Z',
    updatedAt: '2022-11-21T06:36:36.000Z',
    deletedAt: null,
  },
  {
    id: 8,
    name: 'Others',
    status: true,
    createdAt: '2022-11-21T06:36:41.000Z',
    updatedAt: '2022-11-21T06:36:41.000Z',
    deletedAt: null,
  },
];

export const LANGUAGES = [
  {
    label: 'Urdu',
    value: 'Urdu',
  },
  {
    label: 'English',
    value: 'English',
  },
  {
    label: 'Punjabi',
    value: 'Punjabi',
  },
  {
    label: 'Pashtow',
    value: 'Pashtow',
  },
  {
    label: 'Balochi',
    value: 'Balochi',
  },
  {
    label: 'Siraiki',
    value: 'Sikraiki',
  },
  {
    label: 'Kashmiri',
    value: 'Kashmiri',
  },
  {
    label: 'Sindhi',
    value: 'Sindhi',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];
