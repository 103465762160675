import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const Auth = ({ fallbackPath }) => {
  const authUser = localStorage.getItem('fotanistanAccessToken');

  if (authUser) {
    return <Outlet />;
  }

  return <Navigate to={'/auth/login'} />;
};

export default Auth;
