import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  API_DELETE,
  API_GET,
  API_POST,
  API_PUT,
} from 'app/utils/constants/api/apiRequests';
import { setLoading, setLoadingFinished } from './loadingSlice';
import { showAlert } from './alertSlice';

const initialState = {
  branches: [],
  isDataChange: false,
  isDataFetched: false,
};

export const getAllBranches = createAsyncThunk(
  'branches/getAllBranches',
  async (_, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_GET('/branch');

      if (status) {
        dispatch(
          showAlert({
            type: 'success',
            message: 'Branches Fetched Successfully',
          }),
        );
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const addNewBranch = createAsyncThunk(
  'branches/addNewBranch',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, message } = await API_POST('/branch', payload);
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllBranches());
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const editBranch = createAsyncThunk(
  'branches/editBranch',
  async (payload, { dispatch }) => {
    dispatch(setLoading());

    const dataToSend = JSON.parse(JSON.stringify(payload));
    delete dataToSend['id'];
    console.log(payload, dataToSend);
    try {
      const { status, data, message } = await API_PUT(
        '/branch',
        payload.id,
        dataToSend,
      );
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllBranches());
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const deleteBranch = createAsyncThunk(
  'branches/deleteBranch',
  async (id, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_DELETE('/branch', id);
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllBranches());
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const branchesSlice = createSlice({
  name: 'branches',
  initialState,

  extraReducers: builder => {
    builder.addCase(getAllBranches.fulfilled, (state, { payload }) => {
      state.branches = payload;
      state.isDataFetched = true;
    });
  },
});

export default branchesSlice.reducer;
