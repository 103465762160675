import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import {
  ContactPhone,
  DirectionsBus,
  Apartment,
  CakeOutlined,
  Badge,
  SchoolOutlined,
  CottageOutlined,
  WhereToVote,
  Wc,
  AccountBalance,
  FmdBad,
  ChatBubbleOutline,
} from '@mui/icons-material';

import styled from '@emotion/styled';

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 24,
  height: 48,
  width: 48,
  borderRadius: '50%',
  minWidth: 42,
  marginRight: 16,
  padding: theme.spacing(1),
  alignItems: 'center',
  justifyContent: 'center',
  border: `solid 1px ${theme.palette.divider}`,
}));

const StudentOverview = ({ student }) => {
  return (
    <>
      <ListItem
        sx={{
          width: { xs: '100%', sm: '50%', xl: '33.33%' },
        }}>
        <StyledListItemIcon>
          <Apartment fontSize={'inherit'} />
        </StyledListItemIcon>
        <ListItemText
          primary={
            <Typography
              fontSize={'12px'}
              variant="h6"
              color="text.secondary"
              mb={0.5}>
              Branch
            </Typography>
          }
          secondary={
            <Typography variant="body1" color="text.primary">
              {student?.branch?.name}
            </Typography>
          }
        />
      </ListItem>
      <ListItem
        sx={{
          width: { xs: '100%', sm: '50%', xl: '33.33%' },
        }}>
        <StyledListItemIcon>
          <CakeOutlined fontSize={'inherit'} />
        </StyledListItemIcon>
        <ListItemText
          primary={
            <Typography
              fontSize={'12px'}
              variant="h6"
              color="text.secondary"
              mb={0.5}>
              Birthday
            </Typography>
          }
          secondary={
            <Typography variant="body1" color="text.primary">
              {student?.date_of_birth}
            </Typography>
          }
        />
      </ListItem>
      <ListItem
        sx={{
          width: { xs: '100%', sm: '50%', xl: '33.33%' },
        }}>
        <StyledListItemIcon>
          <Wc fontSize={'inherit'} />
        </StyledListItemIcon>
        <ListItemText
          primary={
            <Typography
              fontSize={'12px'}
              variant="h6"
              color="text.secondary"
              mb={0.5}>
              Gender
            </Typography>
          }
          secondary={
            <Typography variant="body1" color="text.primary">
              {!student?.gender ? 'Male' : 'Female'}
            </Typography>
          }
        />
      </ListItem>
      <ListItem
        sx={{
          width: { xs: '100%', sm: '50%', xl: '33.33%' },
        }}>
        <StyledListItemIcon>
          <DirectionsBus fontSize={'inherit'} />
        </StyledListItemIcon>
        <ListItemText
          primary={
            <Typography
              fontSize={'12px'}
              variant="h6"
              color="text.secondary"
              mb={0.5}>
              School Transport
            </Typography>
          }
          secondary={
            <Typography variant="body1" color="text.primary">
              {student?.schoolTransport ? 'Yes' : 'No'}
            </Typography>
          }
        />
      </ListItem>
      <ListItem
        sx={{
          width: { xs: '100%', sm: '50%', xl: '33.33%' },
        }}>
        <StyledListItemIcon>
          <Badge fontSize={'inherit'} />
        </StyledListItemIcon>
        <ListItemText
          primary={
            <Typography
              fontSize={'12px'}
              variant="h6"
              color="text.secondary"
              mb={0.5}>
              Employee Child
            </Typography>
          }
          secondary={
            <Typography variant="body1" color="text.primary">
              {student?.employeeChild ? 'Yes' : 'No'}
            </Typography>
          }
        />
      </ListItem>
      <ListItem
        sx={{
          width: { xs: '100%', sm: '50%', xl: '33.33%' },
        }}>
        <StyledListItemIcon>
          <ContactPhone fontSize={'inherit'} />
        </StyledListItemIcon>
        <ListItemText
          primary={
            <Typography
              fontSize={'12px'}
              variant="h6"
              color="text.secondary"
              mb={0.5}>
              Primary Contact
            </Typography>
          }
          secondary={
            <Typography variant="body1" color="text.primary">
              {student?.primary_contact[0].toUpperCase() +
                student?.primary_contact.slice(1)}
            </Typography>
          }
        />
      </ListItem>
      {!!student?.prev_no_of_schools && (
        <ListItem
          sx={{
            width: { xs: '100%', sm: '50%', xl: '33.33%' },
          }}>
          <StyledListItemIcon>
            <SchoolOutlined fontSize={'inherit'} />
          </StyledListItemIcon>
          <ListItemText
            primary={
              <Typography
                fontSize={'12px'}
                variant="h6"
                color="text.secondary"
                mb={0.5}>
                Went to
              </Typography>
            }
            secondary={
              <Typography variant="body1" color="text.primary">
                {student?.prev_no_of_schools} Schools
              </Typography>
            }
          />
        </ListItem>
      )}
      <ListItem
        sx={{
          width: { xs: '100%', sm: '50%', xl: '33.33%' },
        }}>
        <StyledListItemIcon>
          <WhereToVote fontSize={'inherit'} />
        </StyledListItemIcon>
        <ListItemText
          primary={
            <Typography
              fontSize={'12px'}
              variant="h6"
              color="text.secondary"
              mb={0.5}>
              Nationality
            </Typography>
          }
          secondary={
            <Typography variant="body1" color="text.primary">
              {student?.nationality}
            </Typography>
          }
        />
      </ListItem>
      <ListItem
        sx={{
          width: { xs: '100%', sm: '50%', xl: '33.33%' },
        }}>
        <StyledListItemIcon>
          <AccountBalance fontSize={'inherit'} />
        </StyledListItemIcon>
        <ListItemText
          primary={
            <Typography
              fontSize={'12px'}
              variant="h6"
              color="text.secondary"
              mb={0.5}>
              Status of Student
            </Typography>
          }
          secondary={
            <Typography variant="body1" color="text.primary">
              {student?.status ? 'Active' : 'Inactive'}
            </Typography>
          }
        />
      </ListItem>
      <ListItem
        sx={{
          width: { xs: '100%', sm: '50%', xl: '33.33%' },
        }}>
        <StyledListItemIcon>
          <FmdBad fontSize={'inherit'} />
        </StyledListItemIcon>
        <ListItemText
          primary={
            <Typography
              fontSize={'12px'}
              variant="h6"
              color="text.secondary"
              mb={0.5}>
              Caution
            </Typography>
          }
          secondary={
            <Typography variant="body1" color="text.primary">
              {student?.caution ? student.caution : 'No'}
            </Typography>
          }
        />
      </ListItem>
      <ListItem
        sx={{
          width: { xs: '100%', sm: '50%', xl: '33.33%' },
        }}>
        <StyledListItemIcon>
          <CottageOutlined fontSize={'inherit'} />
        </StyledListItemIcon>
        <ListItemText
          primary={
            <Typography
              fontSize={'12px'}
              variant="h6"
              color="text.secondary"
              mb={0.5}>
              Lives In {student?.village?.name}
            </Typography>
          }
          secondary={
            <Typography variant="body1" color="text.primary">
              {student?.address}
            </Typography>
          }
        />
      </ListItem>

      {student?.special_remarks && (
        <ListItem
          sx={{
            width: { xs: '100%', sm: '50%', xl: '33.33%' },
          }}>
          <StyledListItemIcon>
            <ChatBubbleOutline fontSize={'inherit'} />
          </StyledListItemIcon>
          <ListItemText
            primary={
              <Typography
                fontSize={'12px'}
                variant="h6"
                color="text.secondary"
                mb={0.5}>
                Special Remarks
              </Typography>
            }
            secondary={
              <Typography variant="body1" color="text.primary">
                {student?.special_remarks}
              </Typography>
            }
          />
        </ListItem>
      )}
    </>
  );
};

export default StudentOverview;
