import * as yup from 'yup';

import { IMAGE_SUPPORTED_FORMATS } from 'app/shared/constants';

export const branchSchema = yup.object().shape({
  name: yup
    .string()
    .max(50, 'Branch Name should be less than 50 characters')
    .required('Branch Name is Required'),
  logo: yup
    .mixed()
    .nullable()
    .test(
      'FILE_SIZE',
      'Uploaded File is too big',
      value => !value || (value && value.size <= 1024 * 1024),
    )
    .test(
      'FILE_FORMAT',
      'Uploaded file has unsupported format',
      value =>
        !value || (value && IMAGE_SUPPORTED_FORMATS.includes(value?.type)),
    ),
  incharge_name: yup
    .string()
    .max(50, 'Name should be less than 50 characters')
    .required('Branch InCharge is Required'),
  contact_number: yup.string().required('Contact Number is Required'),
  email: yup
    .string()
    .email('Please enter correct Email')
    .required('Email is Required'),
  address: yup.string().required('Address is Required'),
  details: yup.string().required('Branch Details are required'),
  code: yup.string().required('Branch Code is Required'),
});
