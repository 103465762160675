import React from 'react';
import { useDispatch } from 'react-redux';

import { deleteClass } from 'app/store/features/classesSlice';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemAvatar,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Tooltip,
  Typography,
  Avatar,
  Stack,
} from '@mui/material';
import {
  Person,
  MoreHoriz,
  Edit,
  Delete,
  Money,
  FilterTiltShift,
} from '@mui/icons-material';
import styled from '@emotion/styled';

import JumboDdMenu from '@jumbo/components/JumboDdMenu';
import Span from '@jumbo/shared/Span';
import JumboListItem from '@jumbo/components/JumboList/components/JumboListItem';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import JumboGridItem from '@jumbo/components/JumboList/components/JumboGridItem';

import SubjectDetail from '../SubjectDetail';
import SubjectForm from '../ClassSectionSubjectForm';

const Item = styled(Span)(({ theme }) => ({
  minWidth: 0,
  flexGrow: 0,
  padding: theme.spacing(0, 1),
}));

const SubjectItem = ({ item, view }) => {
  const { showDialog, hideDialog } = useJumboDialog();

  const dispatch = useDispatch();

  const showContactDetail = React.useCallback(() => {
    showDialog({
      content: <SubjectDetail data={item} onClose={hideDialog} />,
    });
  }, [showDialog, item, hideDialog]);

  const handleDeleteSubject = data => {
    dispatch(deleteClass(data.id));
    hideDialog();
  };

  const handleItemAction = menuItem => {
    switch (menuItem.action) {
      case 'edit':
        showDialog({
          title: 'Update Class Section Subject',
          content: <SubjectForm payload={item} />,
        });
        break;
      case 'delete':
        showDialog({
          variant: 'confirm',
          title: 'Are you sure about deleting this class?',
          content: "You won't be able to recover this class later",
          onYes: () => handleDeleteSubject(item),
          onNo: hideDialog,
        });
        break;
      default:
        break;
    }
  };

  if (view === 'grid') {
    return (
      <JumboGridItem xs={12} md={6} lg={4}>
        <Card variant="outlined" elevation={0}>
          <CardHeader
            avatar={
              <Avatar
                sx={{ width: 48, height: 48 }}
                alt={item?.class_section?.class_section_title}
                src={item?.profile_pic}
              />
            }
            action={
              <>
                <JumboDdMenu
                  icon={<MoreHoriz />}
                  menuItems={[
                    { icon: <Edit />, title: 'Edit', action: 'edit' },
                    { icon: <Delete />, title: 'Delete', action: 'delete' },
                  ]}
                  onClickCallback={handleItemAction}
                />
              </>
            }
            title={
              <Tooltip title="Class Section Title">
                <Typography variant={'h6'} color={'text.primary'} mb={0.25}>
                  {item?.class_section?.class_section_title}
                </Typography>
              </Tooltip>
            }
            subheader={
              <Tooltip title="Subject">
                <Typography variant={'h6'} color={'text.primary'} mb={0.25}>
                  {item?.subject?.name}
                </Typography>
              </Tooltip>
            }
          />
          <CardContent sx={{ pt: 0 }}>
            <Divider sx={{ mb: 2 }} />
            <List disablePadding>
              <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                <ListItemIcon sx={{ minWidth: 50 }}>
                  <Money />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Tooltip title="Max Marks">
                      <Typography variant={'body1'}>
                        {item?.max_marks}
                      </Typography>
                    </Tooltip>
                  }
                />
              </ListItem>

              <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                <ListItemIcon sx={{ minWidth: 50 }}>
                  <Money />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Tooltip title="Passing Percentage">
                      <Typography variant={'body1'}>
                        {item?.passing_percentage} %
                      </Typography>
                    </Tooltip>
                  }
                />
              </ListItem>

              <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                <ListItemIcon sx={{ minWidth: 50 }}>
                  <Person />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Tooltip title="Teacher">
                      <Typography variant={'body1'}>
                        {item?.teacher.username}
                      </Typography>
                    </Tooltip>
                  }
                />
              </ListItem>
              <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                <ListItemIcon sx={{ minWidth: 50 }}>
                  <FilterTiltShift />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Tooltip title="Is Student Paper Review Allowed?">
                      <Typography variant={'body1'}>
                        {item?.student_paper_review ? 'Yes' : 'No'}
                      </Typography>
                    </Tooltip>
                  }
                />
              </ListItem>
              <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                <ListItemIcon sx={{ minWidth: 50 }}>
                  <FilterTiltShift />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Tooltip title="Is it Optional?">
                      <Typography variant={'body1'}>
                        {item?.is_optional ? 'Yes' : 'No'}
                      </Typography>
                    </Tooltip>
                  }
                />
              </ListItem>

              <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                <ListItemIcon sx={{ minWidth: 50 }}>
                  <FilterTiltShift />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Tooltip title="Is Subject Active(Status)?">
                      <Typography variant={'body1'}>
                        {item?.status ? 'Yes' : 'No'}
                      </Typography>
                    </Tooltip>
                  }
                />
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </JumboGridItem>
    );
  }
  return (
    <JumboListItem
      componentElement={'div'}
      itemData={item}
      secondaryAction={
        <JumboDdMenu
          icon={<MoreHoriz />}
          menuItems={[
            { icon: <Edit />, title: 'Edit', action: 'edit' },
            { icon: <Delete />, title: 'Delete', action: 'delete' },
          ]}
          onClickCallback={handleItemAction}
        />
      }
      sx={{
        cursor: 'pointer',
        borderTop: 1,
        borderColor: 'divider',
        '&:hover': {
          bgcolor: 'action.hover',
        },
      }}>
      <ListItemAvatar onClick={showContactDetail}>
        <Avatar
          alt={item?.class_section?.class_section_title}
          src={item?.profile_pic}
        />
      </ListItemAvatar>
      <ListItemText
        onClick={showContactDetail}
        primary={
          <Typography variant={'body1'} component={'div'}>
            <Stack direction={'row'} alignItems={'center'} sx={{ minWidth: 0 }}>
              <Item
                sx={{
                  flexBasis: { xs: '100%', sm: '50%', md: '25%' },
                }}>
                <Tooltip title="Class Section Title">
                  <Typography
                    variant={'h5'}
                    fontSize={14}
                    lineHeight={1.25}
                    mb={0}
                    noWrap>
                    {item?.class_section?.class_section_title}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { sm: '50%', md: '28%' },
                  display: { xs: 'none', sm: 'block' },
                }}>
                <Tooltip title={'Subject'}>
                  <Typography variant={'body1'} noWrap>
                    {item?.subject?.name}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { sm: '50%', md: '28%' },
                  display: { xs: 'none', sm: 'block' },
                }}>
                <Tooltip title={'Teacher'}>
                  <Typography variant={'body1'} noWrap>
                    {item?.teacher?.username}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title="Max marks">
                  <Typography variant={'body1'} noWrap>
                    {item?.max_marks}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title="Passing Percentage">
                  <Typography variant={'body1'} noWrap>
                    {item?.passing_percentage}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title="Is Subject Active(status)?">
                  <Typography variant={'body1'} noWrap>
                    {item?.status ? 'Yes' : 'No'}
                  </Typography>
                </Tooltip>
              </Item>
            </Stack>
          </Typography>
        }
      />
    </JumboListItem>
  );
};

export default SubjectItem;
