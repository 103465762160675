import React from 'react';
import { Formik, Form } from 'formik';
import {
  Grid,
  FormControlLabel,
  Switch,
  Typography,
  Button,
  TableContainer,
  TableCell,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  CircularProgress,
} from '@mui/material';
import schemaRole from '../../utils/schema';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import Div from '@jumbo/shared/Div/Div';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import {
  addNewRole,
  editRole,
  getAllResourcePermissions,
  getRole,
} from 'app/store/features/rolesSlice';
import { useLocation } from 'react-router-dom';
import { formatResourcePermissions } from 'app/utils/appHelpers';

const RoleForm = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const resourcePermissions = useSelector(
    state => state.roles.resourcePermissions,
  );
  const isResourcePermissionsFetched = useSelector(
    state => state.roles.isResourcePermissionsFetched,
  );
  const isRoleFetching = useSelector(state => state.roles.isRoleFetching);
  const role = useSelector(state => state.roles.role);
  const fetchData = useCallback(() => {
    if (!isResourcePermissionsFetched) dispatch(getAllResourcePermissions());
    if (state?.id) dispatch(getRole(state.id));
  }, [dispatch, isResourcePermissionsFetched, state]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const addRoleHandler = data => {
    dispatch(addNewRole(data));
  };

  const editRoleHandler = data => {
    const payload = { ...data };
    payload.id = state.id;

    dispatch(editRole(payload));
  };
  const onSubmit = data => {
    state?.id ? editRoleHandler(data) : addRoleHandler(data);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  let initialResources = [];

  if (resourcePermissions?.resources && resourcePermissions?.permissions) {
    initialResources = resourcePermissions?.resources?.map(resource => {
      return {
        resource_id: resource.id,
        permissions: resourcePermissions?.permissions?.map(permission => ({
          permission_id: permission.id,
          allowed: false,
        })),
      };
    });
  }

  const getResourceDisplayTitle = useCallback(
    id => {
      const resource = resourcePermissions?.resources?.find(
        resource => resource.id === id,
      );
      return resource?.display_title;
    },
    [resourcePermissions?.resources],
  );

  if (state?.id && isRoleFetching) {
    return (
      <Div
        sx={{
          display: 'flex',
          minWidth: 0,
          alignItems: 'center',
          alignContent: 'center',
          height: '100%',
        }}>
        <CircularProgress sx={{ m: '-40px auto 0' }} />
      </Div>
    );
  }

  return (
    <>
      <Typography variant="h3">Add New Role</Typography>
      <Formik
        validateOnChange={true}
        initialValues={{
          title: role?.title ? role.title : '',
          display_title: role?.title ? role.display_title : '',
          comments: role?.title ? role.comments : '',
          status: role?.title ? role.status || true : true,
          resource_permissions: role?.title
            ? formatResourcePermissions(role.resource_permissions)
            : initialResources,
        }}
        enableReinitialize={true}
        validationSchema={schemaRole}
        onSubmit={onSubmit}>
        {({ values, errors, touched, setFieldValue, resetForm }) => (
          <Form noValidate autoComplete="off">
            <Grid container spacing={2} rowSpacing={1}>
              <Grid item xs={12} md={6}>
                <JumboTextField
                  fullWidth
                  name="title"
                  size="small"
                  label="Title*"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <JumboTextField
                  fullWidth
                  name="display_title"
                  size="small"
                  label="Display Title*"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <JumboTextField
                  fullWidth
                  name="comments"
                  size="small"
                  label="Comments"
                  minRows={4}
                  multiline
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.status}
                      onChange={e => setFieldValue('status', e.target.checked)}
                    />
                  }
                  label={'Status of Role?'}
                  sx={{ mb: 2 }}
                />
              </Grid>

              <Grid item xs={12} mt={3}>
                <Div
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                  <Typography variant="h4">Resources</Typography>
                </Div>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Resource Name</TableCell>
                        {resourcePermissions?.permissions?.map(permission => (
                          <TableCell key={permission.title}>
                            {permission.display_title}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {initialResources?.map((resource, index) => (
                        <TableRow key={resource.resource_id}>
                          <TableCell>
                            {getResourceDisplayTitle(resource.resource_id)}
                          </TableCell>
                          {resource?.permissions?.map(
                            (permission, permIndex) => (
                              <TableCell key={permIndex}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        values?.resource_permissions[index]
                                          ?.permissions[permIndex]?.allowed ||
                                        false
                                      }
                                      onChange={e =>
                                        setFieldValue(
                                          `resource_permissions[${index}].permissions[${permIndex}].allowed`,
                                          e.target.checked,
                                        )
                                      }
                                    />
                                  }
                                />
                              </TableCell>
                            ),
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            <Div
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                mt: 5,
              }}>
              <Button
                variant="contained"
                sx={{ px: 3 }}
                type="submit"
                size="large">
                Submit
              </Button>
            </Div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default RoleForm;
