import React from 'react';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CardHeader from '@mui/material/CardHeader';
import {
  alpha,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Tooltip,
} from '@mui/material';

import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import Divider from '@mui/material/Divider';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import List from '@mui/material/List';
import Div from '@jumbo/shared/Div';

const ClassesDetail = ({ data, onClose }) => {
  return (
    <Div sx={{ m: theme => theme.spacing(-2.5, -3) }}>
      <CardHeader
        title={data?.name}
        subheader={
          <Tooltip title={'Group'}>
            <Typography variant={'body1'} noWrap>
              {data?.group}
            </Typography>
          </Tooltip>
        }
        avatar={<Avatar src={data?.profile_pic} />}
        action={
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        }
      />
      <List disablePadding>
        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
              }}>
              <CorporateFareIcon sx={{ color: 'primary.light' }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                Subject Group
              </Typography>
            }
            secondary={
              <Typography variant={'body2'} mb={0}>
                {data?.group}
              </Typography>
            }
          />
        </ListItem>
        <Divider component={'li'} />
        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
              }}>
              <MailOutlineIcon sx={{ color: 'primary.light' }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                Fee Amount
              </Typography>
            }
            secondary={
              <Typography variant={'body2'} mb={0}>
                {data?.fee_amount}
              </Typography>
            }
          />
        </ListItem>
        <Divider component={'li'} />

        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
              }}>
              <LabelOutlinedIcon sx={{ color: 'primary.light' }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                User Subject Code
              </Typography>
            }
            secondary={
              <Typography variant={'body2'}>
                {data?.user_subject_code}
              </Typography>
            }
          />
        </ListItem>
        <Divider component={'li'} />

        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
              }}>
              <LabelOutlinedIcon sx={{ color: 'primary.light' }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                Is Subject Active(status)
              </Typography>
            }
            secondary={
              <Typography variant={'body2'}>
                {data?.status ? 'Yes' : 'No'}
              </Typography>
            }
          />
        </ListItem>
      </List>
    </Div>
  );
};

export default ClassesDetail;
