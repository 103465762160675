export const getBranches = data => {
  const { branches } = data;
  const results = [
    {
      id: 999,
      label: 'All Branches',
      value: 'All Branches',
    },
  ];
  for (let i = 0; i < branches.length; i++) {
    let push = true;
    for (let j = 0; j < results.length; j++) {
      if (results[j].value.toLowerCase() === branches[i].name.toLowerCase()) {
        push = false;
        break;
      }
    }

    if (push) {
      const obj = {
        id: i + 1,
        label: branches[i].name,
        value: branches[i].name,
      };
      results.push(obj);
    }
  }
  return results;
};

export const calculateProfit = (data, branch) => {
  const { branches } = data;
  let totalExpense = 0,
    totalEarning = 0;
  if (branch === 'All Branches') {
    for (let i = 0; i < branches.length; i++) {
      for (let j = 0; j < branches[i].revenueSummary.length; j++) {
        totalExpense += branches[i].revenueSummary[j].expense;
        totalEarning += branches[i].revenueSummary[j].earning;
      }
    }
  } else {
    const selectedBranch = branches.filter(
      item => item?.name?.toLowerCase() === branch.toLowerCase(),
    )[0];

    for (let i = 0; i < selectedBranch.revenueSummary.length; i++) {
      totalExpense += selectedBranch.revenueSummary[i].expense;
      totalEarning += selectedBranch.revenueSummary[i].earning;
    }
  }
  return {
    totalExpense,
    totalEarning,
  };
};
