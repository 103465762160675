import React from 'react';
import { Dialog, DialogActions, DialogContent, Zoom } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CardHeader from '@mui/material/CardHeader';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';

const DialogBase = ({
  content,
  actions,
  title,
  subheader,
  contentProps,
  onClose,
  TransitionComponent,
  disableDefaultClose,
  ...restProps
}) => {
  const { open, hideDialog } = useJumboDialog();

  const theme = createTheme({
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '*::-webkit-scrollbar': {
            width: '10px',
          },
          '*::-webkit-scrollbar-track': {
            background: '#E4EFEF',
          },
          '*::-webkit-scrollbar-thumb': {
            background: '#1D388F61',
            borderRadius: '2px',
          },
        },
      },
    },
  });

  const handleClose = () => {
    onClose();
    if (!disableDefaultClose) hideDialog();
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={open}
        {...restProps}
        onClose={handleClose}
        TransitionComponent={TransitionComponent}>
        {title && (
          <CardHeader title={title} subheader={subheader} sx={{ pb: 0 }} />
        )}
        <DialogContent {...contentProps}>{content}</DialogContent>
        {actions && <DialogActions>{actions}</DialogActions>}
      </Dialog>
    </ThemeProvider>
  );
};

DialogBase.defaultProps = {
  onClose: () => {},
  TransitionComponent: Zoom,
  disableDefaultClose: false,
};

export default DialogBase;
