import { useState } from 'react';

import Div from '@jumbo/shared/Div';

const PreviewImage = ({ file, alt = 'preview', width = 150, height = 150 }) => {
  const [preview, setPreview] = useState(null);

  const border = preview ? '' : '2px solid gray';

  if (file && typeof file !== 'string') {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setPreview(reader.result);
    };
  }
  return (
    <Div
      sx={{
        border: border,
        borderRadius: '100%',
        width: 150,
        height: 150,
        marginBottom: 2,
        overflow: 'hidden',
      }}>
      {preview ? (
        <img
          src={preview}
          alt={alt}
          width="100%"
          height="100%"
          style={{
            objectFit: 'cover',
          }}
        />
      ) : (
        <img
          src="https://media.istockphoto.com/vectors/male-profile-flat-blue-simple-icon-with-long-shadow-vector-id522855255?k=20&m=522855255&s=612x612&w=0&h=fLLvwEbgOmSzk1_jQ0MgDATEVcVOh_kqEe0rqi7aM5A="
          alt="default"
          width="100%"
          height="100%"
          style={{ objectFit: 'cover' }}
        />
      )}
    </Div>
  );
};

export default PreviewImage;
