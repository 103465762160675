import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import Div from '@jumbo/shared/Div';
import BranchesList from 'app/shared/components/BranchesList';
import { useNavigate } from 'react-router-dom';

const ClassesAppSidebar = () => {
  const navigate = useNavigate();

  const user = useSelector(state => state.auth.user);

  const isUserHasAddAccess =
    user?.permissions['class_section']?.add || user?.user_type === 'admin';

  const showAddContactDialog = useCallback(() => {
    navigate('/app/classes/form');
  }, [navigate]);

  return (
    <>
      <Div
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: 3,
        }}>
        <Div sx={{ flex: '1 1 auto' }}>
          <Typography variant={'h2'} mb={0.35}>
            Classes
          </Typography>
        </Div>
      </Div>
      {isUserHasAddAccess && (
        <Button
          fullWidth
          disableElevation
          variant={'contained'}
          startIcon={<PersonAddIcon />}
          sx={{
            mb: 2,
            '& .MuiSvgIcon-root': {
              fontSize: '1.5rem',
            },
          }}
          onClick={showAddContactDialog}>
          Add New Class
        </Button>
      )}
      <BranchesList title="All Classes" identifier="classes" />
    </>
  );
};
export default React.memo(ClassesAppSidebar);
