import React from 'react';

const WelcomePage = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>WELCOME TO FOTAN PORTAL</h1>
      <p style={styles.text}>
        Thank you for joining our portal. We are excited to have you as part of
        our community.
      </p>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh',
  },
  heading: {
    fontSize: '36px',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  text: {
    fontSize: '20px',
    textAlign: 'center',
  },
};

export default WelcomePage;
