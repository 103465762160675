import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  API_DELETE,
  API_GET,
  API_POST,
  API_PUT,
} from 'app/utils/constants/api/apiRequests';
import { setLoading, setLoadingFinished } from './loadingSlice';
import { showAlert } from './alertSlice';

const initialState = {
  nonExpensePayments: [],
  isDataFetched: false,
};

export const getAllNonExpensePayments = createAsyncThunk(
  'nonExpensePayments/getAllNonExpensePayments',
  async (_, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_GET('/non-expense-payment');

      if (status) {
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const addNewNonExpensePayment = createAsyncThunk(
  'nonExpensePayments/addNewNonExpensePayment',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, message } = await API_POST(
        '/non-expense-payment',
        payload,
      );
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllNonExpensePayments());
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const editNonExpensePayment = createAsyncThunk(
  'nonExpensePayments/editNonExpensePayment',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    const dataToSend = JSON.parse(JSON.stringify(payload));
    delete dataToSend['id'];
    delete dataToSend['createdAt'];
    delete dataToSend['updatedAt'];
    delete dataToSend['deletedAt'];
    delete dataToSend['paid_by'];

    try {
      const { status, data, message } = await API_PUT(
        '/non-expense-payment',
        payload.id,
        dataToSend,
      );
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllNonExpensePayments());
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const deleteNonExpensePayment = createAsyncThunk(
  'nonExpensePayments/deleteNonExpensePayment',
  async (id, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_DELETE(
        '/non-expense-payment',
        id,
      );
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllNonExpensePayments());
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const nonExpensePaymentsSlice = createSlice({
  name: 'nonExpensePayments',
  initialState,

  extraReducers: builder => {
    builder.addCase(
      getAllNonExpensePayments.fulfilled,
      (state, { payload }) => {
        state.nonExpensePayments = payload;
        state.isDataFetched = true;
      },
    );
  },
});

export default nonExpensePaymentsSlice.reducer;
