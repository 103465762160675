import { useMemo } from 'react';
import { Grid } from '@mui/material';

import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import {
  calculateDailyAttendance,
  calculateWeeklyAttendance,
  calculateMonthlyAttendance,
  calculateAllAttendance,
} from '../../utils/calculate';
import StackedChart from 'app/pages/dashboards/analytics/components/Summary/components/StackedChart';

const AttendanceCard = ({ title, data, flag, selectedBranch }) => {
  const results = useMemo(() => {
    if (flag === 'daily') return calculateDailyAttendance(data, selectedBranch);
    else if (flag === 'weekly')
      return calculateWeeklyAttendance(data, selectedBranch);
    else if (flag === 'monthly')
      return calculateMonthlyAttendance(data, selectedBranch);
    else if (flag === 'all')
      return calculateAllAttendance(data, selectedBranch);
  }, [data, flag, selectedBranch]);

  return (
    <JumboCardQuick title={title} wrapperSx={title ? { pt: 1 } : { pt: 4 }}>
      <Grid container spacing={3.75} sx={{ width: '100%' }}>
        <Grid item xs={12}>
          <StackedChart payload={results} />
        </Grid>
      </Grid>
    </JumboCardQuick>
  );
};

export default AttendanceCard;
