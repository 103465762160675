import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@mui/material';

import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import Card from 'app/pages/dashboards/accounts/components/BitcoinPrice';

import UsersSidebar from './components/UsersSidebar';
import UsersList from './components/UsersList';

import { getAllUsers } from 'app/store/features/usersSlice';

const Users = () => {
  const { category } = useParams();
  const dispatch = useDispatch();

  const users = useSelector(state => state.users.users);
  // const nonTeachers = users.filter(user => user.user_type !== 'teacher');
  const [filteredData, setFilteredData] = useState(users);
  const isUsersFetched = useSelector(state => state.users.isDataFetched);
  const user = useSelector(state => state.auth.user);
  const isUserHasAddPermission = user.permissions['user']?.add || false;

  const isSidebarVisibleToUser =
    user?.user_type === 'admin' ? true : isUserHasAddPermission ? true : false;

  const fetchUsers = useCallback(async () => {
    if (!isUsersFetched) {
      dispatch(getAllUsers());
    }
  }, [dispatch, isUsersFetched]);

  // const badTeachers = nonTeachers.filter(user => user?.color?.title === 'Bad');
  // const excellentTeachers = nonTeachers.filter(
  //   user => user?.color?.title === 'Excellent',
  // );
  // const goodTeachers = nonTeachers.filter(
  //   user => user?.color?.title === 'Best',
  // );
  // const admins = nonTeachers.filter(
  //   user => user?.user_type === 'branch-admin' || user?.user_type === 'admin',
  // );

  useEffect(() => {
    if (
      filteredData.length !== users.length ||
      JSON.stringify(filteredData) !== JSON.stringify(users)
    ) {
      setFilteredData(users);
    }
    fetchUsers();
  }, [fetchUsers, filteredData, users]);

  useEffect(() => {
    if (category === 'all') {
      setFilteredData(users);
    } else {
      const filteredUsers = users?.filter(
        single => category === single?.branch?.name?.toLowerCase(),
      );
      const nonTeachers = filteredUsers.filter(
        user => user?.role !== 'teacher',
      );
      setFilteredData(nonTeachers);
    }
  }, [category]);

  return (
    <>
      {/* <Grid container spacing={1} sx={{ marginBottom: 5 }}>
        <Grid item xs={12} sm={6} lg={3}>
          <Card
            title={filteredData.length}
            subHeader={'Total Users'}
            growth={filteredData.length}
            tooltip={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Card
            title={goodTeachers.length + excellentTeachers.length}
            subHeader={'Users with Status Best/Excellent'}
            growth={goodTeachers.length + excellentTeachers.length}
            bgColor={'#A53860'}
            graphStartColor={'#00ada9'}
            graphEndColor={'#00ada9'}
            tooltip={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Card
            title={badTeachers.length}
            subHeader={'Users with Status Bad'}
            growth={badTeachers.length}
            bgColor={'#ff6f60'}
            graphStartColor={'#e53935'}
            graphEndColor={'#e53935'}
            tooltip={false}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={3}>
          <Card
            title={admins.length}
            subHeader={'Total Admins'}
            growth={admins.length}
            bgColor={'#4B4453'}
            graphStartColor={'#42a5f5'}
            graphEndColor={'#2196f3'}
            tooltip={false}
          />
        </Grid>
      </Grid> */}
      <JumboContentLayout
        sidebar={
          isSidebarVisibleToUser && (
            <UsersSidebar isUserHasAddPermission={isUserHasAddPermission} />
          )
        }>
        <UsersList users={filteredData} />
      </JumboContentLayout>
    </>
  );
};

export default Users;
