import axios from 'axios';

import { baseURL } from 'app/utils/constants/api/constants';
import {
  onRequestSuccess,
  onRequestFail,
  onResponseSuccess,
  onResponseFail,
} from 'app/utils/constants/api/interceptorMethods';

const API = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('fotanistanAccessToken')}`,
  },
});

API.interceptors.request.use(onRequestSuccess, onRequestFail);
API.interceptors.response.use(onResponseSuccess, onResponseFail);

export default API;
