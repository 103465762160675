import * as yup from 'yup';

export const feeSchema = yup.object().shape({
  student_id: yup.number().required('Student is Required'),
  month: yup
    .number()
    .min(1, 'Month cannot be less than 1')
    .max(12, 'Month cannot be greater than 12')
    .required('Month is Required'),
  year: yup
    .number()
    .min(2000, 'Year cannot be less than 2000')
    .max(3000, 'Year cannot be greater than 3000')
    .required('Year is Required'),
  total: yup
    .number()
    .min(100, 'Total Fee cannot be less than 100')
    .required('Total Fee is Required'),
});

export const addFeeSchema = yup.object().shape({
  student: yup.string().required('Student is Required'),
  fee: yup
    .number()
    .positive('Fee cannot be Negative')
    .required('Fee is Required'),
});
