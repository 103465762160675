import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

import { Scrollbars } from 'react-custom-scrollbars-2';

const SalaryHistory = ({ teacher: { salaryHistory } }) => {
  return (
    <Scrollbars style={{ height: 300 }}>
      <div style={{ width: '100%' }}>
        {salaryHistory?.map((item, index) => (
          <div key={index} style={{ marginBottom: 20 }}>
            <Typography variant="h4">Year: {item.year}</Typography>
            <TableContainer component={Paper} sx={{ width: '100%' }}>
              <Table aria-label="result table">
                <TableHead>
                  <TableRow>
                    <TableCell>Month</TableCell>
                    <TableCell>Total Salary</TableCell>
                    <TableCell>Paid Salary</TableCell>
                    <TableCell>Remaining Salary</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {item?.salary?.map((salaryItem, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}>
                      <TableCell component="th" scope="row">
                        {salaryItem.month}
                      </TableCell>
                      <TableCell>Rs. {salaryItem.totalSalary}</TableCell>
                      <TableCell>Rs. {salaryItem.paidSalary}</TableCell>
                      <TableCell>
                        Rs. {salaryItem.totalSalary - salaryItem.paidSalary}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Typography marginTop={1}>
              Status:{' '}
              {item.status === 'Pending' ? (
                <span style={{ color: 'orange' }}>{item.status}</span>
              ) : (
                <span style={{ color: 'green' }}>{item.status}</span>
              )}
            </Typography>
          </div>
        ))}
      </div>
    </Scrollbars>
  );
};

export default SalaryHistory;
