import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  API_DELETE,
  API_GET,
  API_POST,
  API_PUT,
} from 'app/utils/constants/api/apiRequests';
import { setLoading, setLoadingFinished } from './loadingSlice';
import { showAlert } from './alertSlice';

const initialState = {
  roles: [],
  role: {},
  isRoleFetching: true,
  isRolesFetched: false,
  resourcePermissions: [],
  isResourcePermissionsFetched: false,
};

export const getAllResourcePermissions = createAsyncThunk(
  'roles/getAllResourcePermissions',
  async (_, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_GET('/resource-permission');
      if (status) {
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const getAllRoles = createAsyncThunk(
  'roles/getAllRoles',
  async (_, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_GET('/role');

      if (status) {
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const getRole = createAsyncThunk(
  'roles/getRole',
  async (id, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_GET(`/role/${id}`);

      if (status) {
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const addNewRole = createAsyncThunk(
  'roles/addNewRole',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, message } = await API_POST('/role', payload);
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllRoles());
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const editRole = createAsyncThunk(
  'roles/editRole',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    try {
      const res = { ...payload };
      delete res['id'];
      const { status, data, message } = await API_PUT('/role', payload.id, res);
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllRoles());
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const deleteRole = createAsyncThunk(
  'roles/deleteRole',
  async (id, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, message } = await API_DELETE('/role', id);
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        return id;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const rolesSlice = createSlice({
  name: 'roles',
  initialState,

  extraReducers: builder => {
    builder.addCase(getAllRoles.fulfilled, (state, { payload }) => {
      state.roles = payload;
      state.isRolesFetched = true;
    });
    builder.addCase(
      getAllResourcePermissions.fulfilled,
      (state, { payload }) => {
        state.resourcePermissions = payload;
        state.isResourcePermissionsFetched = true;
      },
    );
    builder.addCase(deleteRole.fulfilled, (state, { payload }) => {
      const previousRoles = state.roles;
      state.roles = previousRoles.filter(role => role.id !== payload);
    });
    builder.addCase(getRole.fulfilled, (state, { payload }) => {
      state.role = payload;
      state.isRoleFetching = false;
    });
  },
});

export default rolesSlice.reducer;
