import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
  Avatar,
  Stack,
  Tooltip,
} from '@mui/material';
import {
  MoreHoriz,
  Edit,
  Delete,
  Man,
  PhoneAndroid,
  Category,
  ColorLens,
  AccountTree,
  ToggleOn,
  ToggleOff,
  SettingsAccessibility,
} from '@mui/icons-material';
import styled from '@emotion/styled';

import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import JumboDdMenu from '@jumbo/components/JumboDdMenu';
import Span from '@jumbo/shared/Span';
import JumboListItem from '@jumbo/components/JumboList/components/JumboListItem';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import JumboGridItem from '@jumbo/components/JumboList/components/JumboGridItem';

import UserForm from '../UserForm';
import UserDetail from '../UserDetail';
import { openUserModal, removeUser } from 'app/store/features/usersSlice';
import { titlalize } from 'app/utils/appHelpers';

const Item = styled(Span)(({ theme }) => ({
  minWidth: 0,
  flexGrow: 0,
  padding: theme.spacing(0, 1),
}));

const StudentItem = ({ user, view }) => {
  const { showDialog, hideDialog } = useJumboDialog();
  const Swal = useSwalWrapper();
  const loggedInUser = useSelector(state => state.auth.user);
  const isLoggedInUserHasEditPermission =
    loggedInUser?.user_type === 'admin' ||
    loggedInUser.permissions['user']?.edit;
  const isLoggedInUserHasDeletePermission =
    loggedInUser?.user_type === 'admin' ||
    loggedInUser.permissions['user']?.delete;

  const dispatch = useDispatch();
  const showUserDetail = React.useCallback(() => {
    showDialog({
      content: <UserDetail teacher={user} onClose={hideDialog} />,
    });
  }, [showDialog, user, hideDialog]);

  const handleDeleteTeacher = async user => {
    await dispatch(removeUser(user.id)).unwrap();
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'User has been deleted successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
    hideDialog();
  };

  const handleItemAction = menuItem => {
    switch (menuItem.action) {
      case 'edit':
        dispatch(openUserModal());
        showDialog({
          title: 'Update User detail',
          content: <UserForm userData={user} />,
        });
        break;
      case 'delete':
        showDialog({
          variant: 'confirm',
          title: 'Are you sure about deleting this User?',
          content: "You won't be able to recover this user later",
          onYes: () => handleDeleteTeacher(user),
          onNo: hideDialog,
        });
        break;
      default:
        break;
    }
  };

  if (!user?.branch) {
    return <></>;
  }

  if (view === 'grid') {
    return (
      <JumboGridItem xs={12} md={6} lg={4}>
        <Card variant="outlined" elevation={0}>
          <CardHeader
            avatar={
              <Avatar
                sx={{ width: 48, height: 48 }}
                alt={user?.name}
                src={user?.image}
              />
            }
            action={
              <>
                {(isLoggedInUserHasDeletePermission ||
                  isLoggedInUserHasEditPermission) && (
                  <>
                    <JumboDdMenu
                      icon={<MoreHoriz />}
                      menuItems={[
                        isLoggedInUserHasEditPermission && {
                          icon: <Edit />,
                          title: 'Edit',
                          action: 'edit',
                        },
                        isLoggedInUserHasDeletePermission && {
                          icon: <Delete />,
                          title: 'Delete',
                          action: 'delete',
                        },
                      ]}
                      onClickCallback={handleItemAction}
                    />
                  </>
                )}
              </>
            }
            title={
              <Tooltip title="User's Name">
                <Typography variant={'h6'} color={'text.primary'} mb={0.25}>
                  {user?.name}
                </Typography>
              </Tooltip>
            }
            subheader={
              <Span>
                <Tooltip title="User's Role">
                  <Typography variant={'body1'} color={'text.secondary'}>
                    {user?.role?.display_title}
                  </Typography>
                </Tooltip>
              </Span>
            }
          />
          <CardContent sx={{ pt: 0 }}>
            <Divider sx={{ mb: 2 }} />
            <List disablePadding>
              <Tooltip title="User's Type">
                <ListItem
                  sx={{
                    p: theme => theme.spacing(0.75, 1.5),
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}>
                  <Span sx={{ display: 'flex' }}>
                    <ListItemIcon sx={{ minWidth: 50 }}>
                      <Category />
                    </ListItemIcon>
                    <ListItemText primary={titlalize(user?.user_type)} />
                  </Span>
                </ListItem>
              </Tooltip>

              <Tooltip title="User's Username">
                <ListItem
                  sx={{
                    p: theme => theme.spacing(0.75, 1.5),
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}>
                  <Span sx={{ display: 'flex' }}>
                    <ListItemIcon sx={{ minWidth: 50 }}>
                      <Man />
                    </ListItemIcon>
                    <ListItemText primary={user?.username} />
                  </Span>
                </ListItem>
              </Tooltip>

              <Tooltip title="User's Assigned Color">
                <ListItem
                  sx={{
                    p: theme => theme.spacing(0.75, 1.5),
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}>
                  <Span sx={{ display: 'flex' }}>
                    <ListItemIcon sx={{ minWidth: 50 }}>
                      <ColorLens />
                    </ListItemIcon>
                    <ListItemText
                      primary={user?.status ? 'Active' : 'InActive'}
                    />
                  </Span>
                </ListItem>
              </Tooltip>

              <Tooltip title="User's Phone Number">
                <ListItem
                  sx={{
                    p: theme => theme.spacing(0.75, 1.5),
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}>
                  <Span sx={{ display: 'flex' }}>
                    <ListItemIcon sx={{ minWidth: 50 }}>
                      <PhoneAndroid />
                    </ListItemIcon>
                    <ListItemText primary={user?.phone_no} />
                  </Span>
                </ListItem>
              </Tooltip>

              {user?.phone_no_2 && (
                <Tooltip title="User's Secondary Phone Number">
                  <ListItem
                    sx={{
                      p: theme => theme.spacing(0.75, 1.5),
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}>
                    <Span sx={{ display: 'flex' }}>
                      <ListItemIcon sx={{ minWidth: 50 }}>
                        <PhoneAndroid />
                      </ListItemIcon>
                      <ListItemText primary={user?.phone_no_2} />
                    </Span>
                  </ListItem>
                </Tooltip>
              )}

              {user?.reference && (
                <Tooltip title="User's Secondary Phone Number">
                  <ListItem
                    sx={{
                      p: theme => theme.spacing(0.75, 1.5),
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}>
                    <Span sx={{ display: 'flex' }}>
                      <ListItemIcon sx={{ minWidth: 50 }}>
                        <SettingsAccessibility />
                      </ListItemIcon>
                      <ListItemText primary={user?.reference} />
                    </Span>
                  </ListItem>
                </Tooltip>
              )}

              <Tooltip title="User's Branch Name">
                <ListItem
                  sx={{
                    p: theme => theme.spacing(0.75, 1.5),
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}>
                  <ListItemIcon sx={{ minWidth: 50 }}>
                    <AccountTree />
                  </ListItemIcon>

                  <ListItemText primary={user?.branch?.name} />
                </ListItem>
              </Tooltip>

              <Tooltip title="User's Status">
                <ListItem
                  sx={{
                    p: theme => theme.spacing(0.75, 1.5),
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}>
                  <ListItemIcon sx={{ minWidth: 50 }}>
                    {user.status ? <ToggleOn /> : <ToggleOff />}
                  </ListItemIcon>

                  <ListItemText primary={user?.branch?.name} />
                </ListItem>
              </Tooltip>
            </List>
          </CardContent>
        </Card>
      </JumboGridItem>
    );
  }
  return (
    <JumboListItem
      componentElement={'div'}
      itemData={user}
      secondaryAction={
        (isLoggedInUserHasDeletePermission ||
          isLoggedInUserHasEditPermission) && (
          <JumboDdMenu
            icon={<MoreHoriz />}
            menuItems={[
              isLoggedInUserHasEditPermission && {
                icon: <Edit />,
                title: 'Edit',
                action: 'edit',
              },
              isLoggedInUserHasDeletePermission && {
                icon: <Delete />,
                title: 'Delete',
                action: 'delete',
              },
            ]}
            onClickCallback={handleItemAction}
          />
        )
      }
      sx={{
        cursor: 'pointer',
        borderTop: 1,
        borderColor: 'divider',
        '&:hover': {
          bgcolor: 'action.hover',
        },
      }}>
      <ListItemText
        onClick={showUserDetail}
        primary={
          <Typography variant={'body1'} component={'div'}>
            <Stack direction={'row'} alignItems={'center'} sx={{ minWidth: 0 }}>
              <Item
                sx={{
                  flexBasis: { md: '25%' },
                }}>
                <Tooltip title="User's Name">
                  <Typography
                    variant={'h5'}
                    fontSize={14}
                    lineHeight={1.25}
                    mb={0}
                    noWrap>
                    {user?.name}
                  </Typography>
                </Tooltip>
              </Item>

              <Item
                sx={{
                  flexBasis: { md: '25%' },
                }}>
                <Tooltip title="User's Username">
                  <Typography
                    variant={'h5'}
                    fontSize={14}
                    lineHeight={1.25}
                    mb={0}
                    noWrap>
                    {user?.username}
                  </Typography>
                </Tooltip>
              </Item>

              <Item
                sx={{
                  flexBasis: { md: '25%' },
                }}>
                <Tooltip title="User's Role">
                  <Typography
                    variant={'h5'}
                    fontSize={14}
                    lineHeight={1.25}
                    mb={0}
                    noWrap>
                    {user?.role?.display_title}
                  </Typography>
                </Tooltip>
              </Item>

              <Item
                sx={{
                  flexBasis: { md: '25%' },
                }}>
                <Tooltip title="User's Type">
                  <Typography
                    variant={'h5'}
                    fontSize={14}
                    lineHeight={1.25}
                    mb={0}
                    noWrap>
                    {titlalize(user?.user_type)}
                  </Typography>
                </Tooltip>
              </Item>

              {user.cnic && (
                <Item
                  sx={{
                    flexBasis: { md: '25%' },
                    display: { xs: 'none', md: 'block' },
                  }}>
                  <Tooltip title="User's CNIC">
                    <Typography variant={'body1'} noWrap>
                      {user.cnic}
                    </Typography>
                  </Tooltip>
                </Item>
              )}

              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title="User Phone Number">
                  <Typography variant={'body1'} noWrap>
                    {user?.phone_no}
                  </Typography>
                </Tooltip>
              </Item>

              {user.phone_no_2 && (
                <Item
                  sx={{
                    flexBasis: { md: '25%' },
                    display: { xs: 'none', md: 'block' },
                  }}>
                  <Tooltip title="User Phone Number">
                    <Typography variant={'body1'} noWrap>
                      {user?.phone_no}
                    </Typography>
                  </Tooltip>
                </Item>
              )}

              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title="User's Branch">
                  <Typography variant={'body1'} noWrap>
                    {user?.branch?.name}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title="User's Assigned Color">
                  <Typography variant={'body1'} noWrap>
                    {user?.color?.title}
                  </Typography>
                </Tooltip>
              </Item>

              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title="User's Status">
                  <Typography variant={'body1'} noWrap>
                    {user?.status ? 'Active' : 'InActive'}
                  </Typography>
                </Tooltip>
              </Item>
            </Stack>
          </Typography>
        }
      />
    </JumboListItem>
  );
};

export default StudentItem;
