import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  accounts: {
    currentBalance: 9500000,
    earnings: 500000,
    expenses: 9000000,
    revenueSummary: [
      { month: 'Jan', earning: 500000, expense: 120000 },
      { month: 'Feb', earning: 520000, expense: 125000 },
      { month: 'Mar', earning: 520000, expense: 300000 },
      { month: 'Apr', earning: 600000, expense: 399000 },
      { month: 'May', earning: 570000, expense: 350000 },
      { month: 'Jun', earning: 700000, expense: 670000 },
      { month: 'Jul', earning: 570000, expense: 350000 },
      { month: 'Aug', earning: 570000, expense: 350000 },
      { month: 'Sep', earning: 570000, expense: 350000 },
      { month: 'Oct', earning: 0, expense: 0 },
      { month: 'Nov', earning: 0, expense: 0 },
      { month: 'Dec', earning: 0, expense: 0 },
    ],
    branches: [
      {
        name: 'Lahore',
        revenue: 50000,
        revenueSummary: [
          { month: 'Jan', earning: 5000000, expense: 120000 },
          { month: 'Feb', earning: 520000, expense: 125000 },
          { month: 'Mar', earning: 520000, expense: 300000 },
          { month: 'Apr', earning: 600000, expense: 399000 },
          { month: 'May', earning: 570000, expense: 350000 },
          { month: 'Jun', earning: 700000, expense: 670000 },
          { month: 'Jul', earning: 570000, expense: 350000 },
          { month: 'Aug', earning: 570000, expense: 350000 },
          { month: 'Sep', earning: 570000, expense: 350000 },
          { month: 'Oct', earning: 0, expense: 0 },
          { month: 'Nov', earning: 0, expense: 0 },
          { month: 'Dec', earning: 0, expense: 0 },
        ],
      },
      {
        name: 'Gujranwala',
        revenue: 900000,
        revenueSummary: [
          { month: 'Jan', earning: 500000, expense: 120000 },
          { month: 'Feb', earning: 5200000, expense: 125000 },
          { month: 'Mar', earning: 520000, expense: 300000 },
          { month: 'Apr', earning: 600000, expense: 399000 },
          { month: 'May', earning: 570000, expense: 350000 },
          { month: 'Jun', earning: 700000, expense: 670000 },
          { month: 'Jul', earning: 570000, expense: 350000 },
          { month: 'Aug', earning: 570000, expense: 350000 },
          { month: 'Sep', earning: 570000, expense: 350000 },
          { month: 'Oct', earning: 900000, expense: 0 },
          { month: 'Nov', earning: 0, expense: 0 },
          { month: 'Dec', earning: 0, expense: 0 },
        ],
      },
      {
        name: 'Islamabad',
        revenue: 9000000,
        revenueSummary: [
          { month: 'Jan', earning: 500000, expense: 120000 },
          { month: 'Feb', earning: 520000, expense: 125000 },
          { month: 'Mar', earning: 520000, expense: 300000 },
          { month: 'Apr', earning: 600000, expense: 399000 },
          { month: 'May', earning: 570000, expense: 350000 },
          { month: 'Jun', earning: 700000, expense: 670000 },
          { month: 'Jul', earning: 570000, expense: 350000 },
          { month: 'Aug', earning: 570000, expense: 350000 },
          { month: 'Sep', earning: 570000, expense: 350000 },
          { month: 'Oct', earning: 0, expense: 900000 },
          { month: 'Nov', earning: 10000, expense: 50000 },
          { month: 'Dec', earning: 123210, expense: 1000 },
        ],
      },
      {
        name: 'Islamabad',
        revenue: 9000000,
        revenueSummary: [
          { month: 'Jan', earning: 500000, expense: 120000 },
          { month: 'Feb', earning: 520000, expense: 125000 },
          { month: 'Mar', earning: 520000, expense: 300000 },
          { month: 'Apr', earning: 600000, expense: 399000 },
          { month: 'May', earning: 570000, expense: 350000 },
          { month: 'Jun', earning: 700000, expense: 670000 },
          { month: 'Jul', earning: 570000, expense: 350000 },
          { month: 'Aug', earning: 570000, expense: 350000 },
          { month: 'Sep', earning: 570000, expense: 350000 },
          { month: 'Oct', earning: 0, expense: 900000 },
          { month: 'Nov', earning: 10000, expense: 50000 },
          { month: 'Dec', earning: 123210, expense: 1000 },
        ],
      },
      {
        name: 'Islamabad',
        revenue: 9000000,
        revenueSummary: [
          { month: 'Jan', earning: 500000, expense: 120000 },
          { month: 'Feb', earning: 520000, expense: 125000 },
          { month: 'Mar', earning: 520000, expense: 300000 },
          { month: 'Apr', earning: 600000, expense: 399000 },
          { month: 'May', earning: 570000, expense: 350000 },
          { month: 'Jun', earning: 700000, expense: 670000 },
          { month: 'Jul', earning: 570000, expense: 350000 },
          { month: 'Aug', earning: 570000, expense: 350000 },
          { month: 'Sep', earning: 570000, expense: 350000 },
          { month: 'Oct', earning: 0, expense: 900000 },
          { month: 'Nov', earning: 10000, expense: 50000 },
          { month: 'Dec', earning: 123210, expense: 1000 },
        ],
      },
      {
        name: 'Islamabad',
        revenue: 9000000,
        revenueSummary: [
          { month: 'Jan', earning: 500000, expense: 120000 },
          { month: 'Feb', earning: 520000, expense: 125000 },
          { month: 'Mar', earning: 520000, expense: 300000 },
          { month: 'Apr', earning: 600000, expense: 399000 },
          { month: 'May', earning: 570000, expense: 350000 },
          { month: 'Jun', earning: 700000, expense: 670000 },
          { month: 'Jul', earning: 570000, expense: 350000 },
          { month: 'Aug', earning: 570000, expense: 350000 },
          { month: 'Sep', earning: 570000, expense: 350000 },
          { month: 'Oct', earning: 0, expense: 900000 },
          { month: 'Nov', earning: 10000, expense: 50000 },
          { month: 'Dec', earning: 123210, expense: 1000 },
        ],
      },
    ],
  },
};

export const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    addExpense: (state, { payload }) => {
      state.accounts.expenses += payload.expense;
      state.accounts.currentBalance -= payload.expense;
    },
    addEarning: (state, { payload }) => {
      state.accounts.earnings += payload.deposit;
      state.accounts.currentBalance += payload.deposit;
    },
  },
});

export const { addEarning, addExpense } = accountsSlice.actions;

export default accountsSlice.reducer;
