import { makeStyles } from '@mui/styles';

export default makeStyles({
  newBranchWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  form: {
    width: '100%',
  },

  imageWrapper: {
    marginBottom: 2,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
  },
  inputWrapper: {
    marginBottom: 10,
  },
  submitButtonWrapper: {
    marginTop: 15,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
});
