import { useState, forwardRef, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import JumboList from '@jumbo/components/JumboList';

const JumboRqList = forwardRef((props, ref) => {
  const {
    itemsPerPage,
    primaryKey,
    itemsPerPageOptions,
    toolbar,
    multiSelectOptions,
    onSelectionChange,
    renderItem,
    noDataPlaceholder,
    wrapperComponent,
    wrapperSx,
    component,
    sx,
    componentElement,
    itemSx,
    transition,
    view,
    data,
  } = props;

  const listRef = useRef();
  const itemsPerPageRef = useRef(itemsPerPage);

  const [loading, setLoading] = useState(false);
  const [itemsToShow, setItemsToShow] = useState(data);

  useEffect(() => {
    setItemsToShow(data.slice(0, itemsPerPageRef.current));
  }, [data, itemsPerPageRef]);

  const handleItemsPerPageChange = useCallback(
    newValue => {
      itemsPerPageRef.current = newValue;
      setItemsToShow(data.slice(0, newValue));
    },
    [data],
  );

  const handlePageChange = useCallback(
    page => {
      const start = page * itemsPerPageRef.current;
      const end = start + itemsPerPageRef.current;

      setItemsToShow(data.slice(start, end));
    },
    [data, itemsPerPageRef],
  );

  return (
    <JumboList
      ref={listRef}
      data={itemsToShow}
      primaryKey={primaryKey}
      renderItem={renderItem}
      itemsPerPage={itemsPerPageRef.current}
      totalCount={data?.length}
      onPageChange={handlePageChange}
      onItemsPerPageChange={handleItemsPerPageChange}
      itemsPerPageOptions={itemsPerPageOptions}
      toolbar={toolbar}
      onSelectionChange={onSelectionChange}
      multiSelectOptions={multiSelectOptions}
      noDataPlaceholder={noDataPlaceholder}
      isLoading={loading}
      wrapperComponent={wrapperComponent}
      wrapperSx={wrapperSx}
      componentElement={componentElement}
      itemSx={itemSx}
      component={component}
      sx={sx}
      transition={transition}
      view={view}
    />
  );
});

JumboRqList.defaultProps = {
  onRefresh: () => {},
};

JumboRqList.propTypes = {
  // service: PropTypes.func.isRequired,
  multiSelectOptions: PropTypes.array,
  itemsPerPage: PropTypes.number,
  noDataPlaceholder: PropTypes.node,
  renderItem: PropTypes.func,
  header: PropTypes.node,
  footer: PropTypes.node,
  toolbar: PropTypes.node,
  onSelectionChange: PropTypes.func,
  onRefresh: PropTypes.func,
  primaryKey: PropTypes.string.isRequired,
  // queryOptions: PropTypes.shape({
  //   queryKey: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
  //     .isRequired,
  //   queryParams: PropTypes.object,
  //   countKey: PropTypes.string,
  //   dataKey: PropTypes.string.isRequired,
  // }).isRequired,
  wrapperComponent: PropTypes.object,
  wrapperSx: PropTypes.object,
  component: PropTypes.object,
  componentElement: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  sx: PropTypes.object,
  itemSx: PropTypes.object,
  disableTransition: PropTypes.bool,
};

export default JumboRqList;
