import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';

import JumboButton from '@jumbo/components/JumboButton';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import JumboSwitch from '@jumbo/components/JumboFormik/JumboSwitch';
import Div from '@jumbo/shared/Div';
import JumboDropDown from '@jumbo/components/JumboFormik/JumboDropDown';

import { getAllBranches } from 'app/store/features/branchSlice';
import { getAllUsers } from 'app/store/features/usersSlice';
import { examInitiatorSchema } from '../../utils/schema';
import { SHIFTS, EXAM_TERMS, EXAM_TYPES } from 'app/shared/constants';
import { Typography } from '@mui/material';
import {
  addExamInitiator,
  editInitiator,
} from 'app/store/features/examinationSlice';

const ExamInitiatorForm = () => {
  const dispatch = useDispatch();
  const branches = useSelector(state => state.branches.branches);
  const users = useSelector(state => state.users.users);
  const isBranchesFetched = useSelector(state => state.branches.isDataFetched);
  const isUsersFetched = useSelector(state => state.users.isDataFetched);

  const navigate = useNavigate();
  const { state } = useLocation();

  const fetchData = useCallback(async () => {
    if (!isBranchesFetched) dispatch(getAllBranches());
    if (!isUsersFetched) dispatch(getAllUsers());
  }, [dispatch, isBranchesFetched, isUsersFetched]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const initialValues = {
    branch_id: state ? state?.branch?.id : '',
    shift: state ? state?.shift : '',
    date_sheet_initiator_id: state ? state?.date_sheet_initiator?.id : '',
    head_examiner_id: state ? state?.head_examiner?.id : '',
    al_final_reviewer_id: state ? state?.al_final_reviewer?.id : '',
    remarks_final_reviewer_id: state ? state?.remarks_final_reviewer?.id : '',
    is_publish: state ? state.is_publish : false,
    is_freeze: state ? state.is_freeze : false,
    is_remarks: state ? state.is_remarks : false,
    exam_term: state ? state.exam_term : '',
    exam_type: state ? state.exam_type : '',
    date_sheet_date: state ? state.date_sheet_date : '',
    exam_start_date: state ? state.exam_start_date : '',
    exam_result_date: state ? state.exam_result_date : '',
    sms_flag: state ? state.sms_flag : false,
    test_flag: state ? state.test_flag : false,
    remarks_flag: state ? state.remarks_flag : false,
  };

  const handleSubmit = data => {
    if (state) {
      data.id = state.id;
      dispatch(editInitiator(data));
    } else {
      dispatch(addExamInitiator(data));
    }
    navigate('/app/exam-init/all');
  };

  return (
    <>
      <Typography variant="h3" my={3} textAlign={'center'}>
        {state && 'Update '}Exam Initiator Form
      </Typography>
      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={examInitiatorSchema}
        onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form noValidate autoComplete="off">
            <Div
              sx={{
                '& .MuiTextField-root': {
                  mb: 3,
                },
              }}>
              <JumboDropDown
                fullWidth
                size="small"
                variant="outlined"
                name="branch_id"
                label="Branch"
                options={branches}
              />
              <JumboDropDown
                fullWidth
                size="small"
                variant="outlined"
                name="shift"
                label="Shift"
                options={SHIFTS}
              />

              <JumboDropDown
                fullWidth
                size="small"
                variant="outlined"
                name="date_sheet_initiator_id"
                label="Date Sheet Initiator"
                options={users}
              />
              <JumboDropDown
                fullWidth
                size="small"
                variant="outlined"
                name="head_examiner_id"
                label="Head Examiner"
                options={users}
              />
              <JumboDropDown
                fullWidth
                size="small"
                variant="outlined"
                name="al_final_reviewer_id"
                label="AL Final Reviewer"
                options={users}
              />
              <JumboDropDown
                fullWidth
                size="small"
                variant="outlined"
                name="remarks_final_reviewer_id"
                label="Remarks Final Reviewer"
                options={users}
              />
              <JumboSwitch name="is_publish" label="Publish" color="primary" />
              <JumboSwitch name="is_freeze" label="Freeze" color="primary" />
              <JumboSwitch name="is_remarks" label="Remarks" color="primary" />

              <JumboDropDown
                fullWidth
                size="small"
                variant="outlined"
                name="exam_term"
                label="Exam Term"
                options={EXAM_TERMS}
              />

              <JumboDropDown
                fullWidth
                size="small"
                variant="outlined"
                name="exam_type"
                label="Exam Type"
                options={EXAM_TYPES}
              />

              <JumboTextField
                name="date_sheet_date"
                label="Date Sheet Date"
                variant="outlined"
                margin="normal"
                InputLabelProps={{ shrink: true }}
                fullWidth
                type="date"
              />
              <JumboTextField
                name="exam_start_date"
                label="Exam Start Date"
                variant="outlined"
                margin="normal"
                InputLabelProps={{ shrink: true }}
                fullWidth
                type="date"
              />
              <JumboTextField
                name="exam_result_date"
                label="Exam Result Date"
                variant="outlined"
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="date"
              />

              <Div>
                <JumboSwitch name="sms_flag" label="Send SMS" color="primary" />
                <JumboSwitch name="test_flag" label="Test" color="primary" />
                <JumboSwitch
                  name="remarks_flag"
                  label="Remarks Flag"
                  color="primary"
                />
              </Div>
              <Div
                sx={{
                  margin: 2,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <JumboButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  size="large">
                  {state ? 'Update' : 'Submit'}
                </JumboButton>
              </Div>
            </Div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ExamInitiatorForm;
