import { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';

import { Card, ButtonGroup, Button } from '@mui/material';
import { List, ViewComfy } from '@mui/icons-material';

import JumboRqList from '@jumbo/components/JumboReactQuery/JumboRqList';
import JumboListToolbar from '@jumbo/components/JumboList/components/JumboListToolbar';
import JumboSearch from '@jumbo/components/JumboSearch';

import SubjectItem from './SubjectItem';

const SubjectsList = () => {
  const listRef = useRef();
  const subjects = useSelector(state => state.subjects.subjects);

  const [view, setView] = useState('list');
  const [dataToShow, setDataToShow] = useState(subjects);

  useEffect(() => {
    setDataToShow(subjects);
  }, [subjects]);

  const renderSubject = useCallback(
    subject => {
      return <SubjectItem item={subject} view={view} />;
    },
    [view],
  );

  const handleOnChange = useCallback(
    keyword => {
      let filteredData = [];
      filteredData = subjects?.filter(singleClass => {
        return (
          singleClass?.name?.toLowerCase().search(keyword?.toLowerCase()) !== -1
        );
      });
      setDataToShow(filteredData);
    },
    [subjects],
  );

  return (
    <JumboRqList
      ref={listRef}
      data={dataToShow}
      wrapperComponent={Card}
      primaryKey={'id'}
      itemsPerPage={8}
      itemsPerPageOptions={[8, 15, 20]}
      renderItem={renderSubject}
      componentElement={'div'}
      isClasses
      sx={view === 'grid' && { p: theme => theme.spacing(1, 3, 3) }}
      wrapperSx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
      toolbar={
        <JumboListToolbar
          hideItemsPerPage={true}
          actionTail={
            <ButtonGroup
              variant="outlined"
              disableElevation
              sx={{
                '& .MuiButton-root': {
                  px: 1,
                },
              }}>
              <Button
                variant={view === 'list' ? 'contained' : 'outlined'}
                onClick={() => setView('list')}>
                <List />
              </Button>
              <Button
                variant={view === 'grid' ? 'contained' : 'outlined'}
                onClick={() => setView('grid')}>
                <ViewComfy />
              </Button>
            </ButtonGroup>
          }>
          <JumboSearch
            onChange={handleOnChange}
            sx={{
              display: { xs: 'none', md: 'block' },
            }}
          />
        </JumboListToolbar>
      }
      view={view}
    />
  );
};

export default SubjectsList;
