import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FieldArray, Form, Formik, useFormikContext } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import Div from '@jumbo/shared/Div';
import JumboDropDown from '@jumbo/components/JumboFormik/JumboDropDown';

import { classSchema } from '../../utils/schema';
import { getAllBranches } from 'app/store/features/branchSlice';
import { getAllUsers } from 'app/store/features/usersSlice';

import {
  addNewClass,
  getClass,
  updateClass,
} from 'app/store/features/classesSlice';

import { SHIFTS, FEE_TYPES, FEE_FREQUENCY } from 'app/shared/constants';
import {
  Button,
  Card,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { getAllSubjects } from 'app/store/features/subjectsSlice';

const ClassForm = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();

  const storeBranches = useSelector(state => state.branches.branches);
  const isStoreBranchesFetched = useSelector(
    state => state.branches.isDataFetched,
  );
  const storeUsers = useSelector(state => state.users.users);
  const isStoreUsersFetched = useSelector(state => state.users.isDataFetched);
  const isClassFetching = useSelector(state => state.classes.isClassFetching);
  const storeClass = useSelector(state => state.classes.class);
  const subjects = useSelector(state => state.subjects.subjects);
  const isSubjectsFetched = useSelector(state => state.subjects.isDataFetched);

  const [teachers, setTeachers] = useState(storeUsers);

  const fetchData = useCallback(async () => {
    if (!isStoreBranchesFetched) dispatch(getAllBranches());
    if (!isStoreUsersFetched) dispatch(getAllUsers());
    if (!isSubjectsFetched) dispatch(getAllSubjects());

    if (state?.id) dispatch(getClass(state.id));
  }, [
    dispatch,
    isStoreBranchesFetched,
    isStoreUsersFetched,
    isSubjectsFetched,
    state,
  ]);

  useEffect(() => {
    fetchData();
    const filtered = storeUsers.filter(user => user.user_type === 'teacher');
    setTeachers(filtered);
  }, [fetchData, storeBranches, storeUsers]);

  const addClassHandler = data => {
    dispatch(addNewClass(data));
  };

  const updateClassHandler = data => {
    const payload = {
      id: state.id,
      payload: {
        ...data,
        class_section_subject: data.class_section_subject.map(subject => ({
          subject_id: subject.subject_id,
          teacher_id: subject.teacher_id,
          max_marks: subject.max_marks,
          passing_percentage: subject.passing_percentage,
          is_optional: subject.is_optional,
          student_paper_review: subject.student_paper_review,
          status: subject.status,
        })),
      },
    };

    dispatch(updateClass(payload));
  };
  const onClassSave = data => {
    state?.id ? updateClassHandler(data) : addClassHandler(data);
  };

  // useEffect(() => {
  //   setFieldValue('class_section_title', `${values.class} (${values.section})`);
  // }, [setFieldValue, values.class, values.section]);

  if (state?.id && isClassFetching) {
    return (
      <Div
        sx={{
          display: 'flex',
          minWidth: 0,
          alignItems: 'center',
          alignContent: 'center',
          height: '100%',
        }}>
        <CircularProgress sx={{ m: '-40px auto 0' }} />
      </Div>
    );
  }

  return (
    <Div
      sx={{
        backgroundColor: 'white',
        padding: 3,
        borderRadius: 5,
      }}>
      <Typography variant="h3">
        {state?.id ? 'Update' : 'Add New'} Class
      </Typography>
      <Formik
        validateOnChange={true}
        initialValues={{
          class_section_title: storeClass?.branch?.id
            ? storeClass.class_section_title
            : '',
          class: storeClass?.branch?.id ? storeClass.class : '',
          section: storeClass?.branch?.id ? storeClass.section : '',
          max_strength: storeClass?.branch?.id ? storeClass?.max_strength : 20,
          min_strength: storeClass?.branch?.id ? storeClass?.min_strength : 10,
          fee_amount: storeClass?.branch?.id ? storeClass?.fee_amount : 1000,
          branch_id: storeClass?.branch?.id ? storeClass.branch?.id : '',
          shift: storeClass?.branch?.id ? storeClass.shift : SHIFTS[0].value,
          incharge_id: storeClass?.branch?.id ? storeClass?.incharge?.id : '',
          fee_type: storeClass?.fee_type
            ? storeClass.fee_type
            : FEE_TYPES[0].value,
          fee_frequency: storeClass?.branch?.id
            ? storeClass?.fee_frequency
            : FEE_FREQUENCY[0].value,
          is_pre_admission: storeClass?.branch?.id
            ? storeClass.is_pre_admission
            : false,
          is_refundable: storeClass?.branch?.id
            ? storeClass.is_refundable
            : false,
          is_full_day: storeClass?.branch?.id ? storeClass.is_full_day : true,
          status: storeClass?.branch?.id ? storeClass.status : true,
          class_section_subject: storeClass?.branch?.id
            ? storeClass?.class_section_subject
            : [
                {
                  subject_id: '',
                  teacher_id: '',
                  max_marks: '',
                  passing_percentage: '',
                  is_optional: false,
                  student_paper_review: true,
                  status: true,
                },
              ],
        }}
        enableReinitialize={true}
        validationSchema={classSchema}
        onSubmit={onClassSave}>
        {({ values, setFieldValue }) => (
          <Form noValidate autoComplete="off">
            <Div
              sx={{
                '& .MuiTextField-root': {
                  mb: 3,
                },
              }}>
              <Grid container spacing={2} rowSpacing={1} mt={1}>
                <Grid item x={12} md={6}>
                  <JumboTextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    name="class_section_title"
                    label="Class Section Title*"
                  />
                </Grid>

                <Grid item x={12} md={6}>
                  <JumboTextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    name="class"
                    label="Class*"
                  />
                </Grid>

                <Grid item x={12} md={6}>
                  <JumboTextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    name="section"
                    label="Section*"
                  />
                </Grid>

                <Grid item x={12} md={6}>
                  <JumboTextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    name="max_strength"
                    label="Maximum Strength"
                    type="number"
                  />
                </Grid>

                <Grid item x={12} md={6}>
                  <JumboTextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    name="min_strength"
                    label="Minimum Strength"
                    type="number"
                  />
                </Grid>

                <Grid item x={12} md={6}>
                  <JumboTextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    name="fee_amount"
                    label="Fee Amount"
                    type="number"
                  />
                </Grid>

                <Grid item x={12} md={6}>
                  <JumboDropDown
                    fullWidth
                    size="small"
                    variant="outlined"
                    name="branch_id"
                    label="Please Select Branch*"
                    options={storeBranches}
                  />
                </Grid>

                <Grid item x={12} md={6}>
                  <JumboDropDown
                    fullWidth
                    size="small"
                    variant="outlined"
                    name="shift"
                    label="Please Select Shift*"
                    options={SHIFTS}
                  />
                </Grid>

                <Grid item x={12} md={6}>
                  <JumboDropDown
                    fullWidth
                    size="small"
                    variant="outlined"
                    name="incharge_id"
                    label="Please Select InCharge of the Class*"
                    options={teachers}
                  />
                </Grid>

                <Grid item x={12} md={6}>
                  <JumboDropDown
                    fullWidth
                    size="small"
                    variant="outlined"
                    name="fee_type"
                    label="Please Select Fee Type of the Class"
                    options={FEE_TYPES}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <JumboDropDown
                    fullWidth
                    size="small"
                    variant="outlined"
                    name="fee_frequency"
                    label="Please Select Fee Frequency of the Class"
                    options={FEE_FREQUENCY}
                  />
                </Grid>

                <Grid item x={12}>
                  <Div
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.is_pre_admission}
                          onChange={e =>
                            setFieldValue('is_pre_admission', e.target.checked)
                          }
                        />
                      }
                      label={'Is Class Pre Admission?'}
                      sx={{ mb: 2 }}
                    />

                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.is_refundable}
                          onChange={e =>
                            setFieldValue('is_refundable', e.target.checked)
                          }
                        />
                      }
                      label={'Is Fee Refundable?'}
                      sx={{ mb: 2 }}
                    />

                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.is_full_day}
                          onChange={e =>
                            setFieldValue('is_full_day', e.target.checked)
                          }
                        />
                      }
                      label={'Is Class Full Day?'}
                      sx={{ mb: 2 }}
                    />

                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.status}
                          onChange={e =>
                            setFieldValue('status', e.target.checked)
                          }
                        />
                      }
                      label={'Status?'}
                      sx={{ mb: 2 }}
                    />
                  </Div>
                </Grid>

                <Grid item xs={12} mt={3}>
                  <Div
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}>
                    <Typography variant="h4">Class Section Subjects</Typography>
                  </Div>
                  <FieldArray name="class_section_subject">
                    {({ push, remove }) => (
                      <div>
                        {values?.class_section_subject?.map((_, index) => (
                          <Card key={index} sx={{ mb: 1, p: 3 }}>
                            {values.class_section_subject?.length > 1 && (
                              <Div
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}>
                                <Typography>Subject #{index + 1}</Typography>
                                <Button
                                  type="button"
                                  variant="outlined"
                                  onClick={() => remove(index)}>
                                  Remove Subject
                                </Button>
                              </Div>
                            )}

                            <Grid container rowSpacing={1} columnSpacing={2}>
                              <Grid item xs={12} md={6}>
                                <JumboDropDown
                                  fullWidth
                                  name={`class_section_subject[${index}].subject_id`}
                                  size="small"
                                  label="Subject*"
                                  options={subjects}
                                />
                              </Grid>

                              <Grid item xs={12} md={6}>
                                <JumboDropDown
                                  fullWidth
                                  name={`class_section_subject[${index}].teacher_id`}
                                  size="small"
                                  label="Teacher*"
                                  options={teachers}
                                />
                              </Grid>

                              <Grid item xs={12} md={6}>
                                <JumboTextField
                                  fullWidth
                                  name={`class_section_subject[${index}].max_marks`}
                                  size="small"
                                  label="Max Marks*"
                                />
                              </Grid>

                              <Grid item xs={12} md={6}>
                                <JumboTextField
                                  fullWidth
                                  name={`class_section_subject[${index}].passing_percentage`}
                                  size="small"
                                  label="Passing Percentage*"
                                />
                              </Grid>

                              <Grid item xs={12}>
                                <Div
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                  }}>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={
                                          values.class_section_subject[index]
                                            ?.is_optional
                                        }
                                        onChange={e =>
                                          setFieldValue(
                                            `class_section_subject[${index}].is_optional`,
                                            e.target.checked,
                                          )
                                        }
                                      />
                                    }
                                    label={'Is Optional?'}
                                    sx={{ mb: 2 }}
                                  />

                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={
                                          values.class_section_subject[index]
                                            ?.student_paper_review
                                        }
                                        onChange={e =>
                                          setFieldValue(
                                            `class_section_subject[${index}].student_paper_review`,
                                            e.target.checked,
                                          )
                                        }
                                      />
                                    }
                                    label={'Student Paper Review?'}
                                    sx={{ mb: 2 }}
                                  />

                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={
                                          values.class_section_subject[index]
                                            ?.status
                                        }
                                        onChange={e =>
                                          setFieldValue(
                                            `class_section_subject[${index}].status`,
                                            e.target.checked,
                                          )
                                        }
                                      />
                                    }
                                    label={'Status?'}
                                    sx={{ mb: 2 }}
                                  />
                                </Div>
                              </Grid>
                            </Grid>
                          </Card>
                        ))}
                        <Button
                          type="button"
                          variant="outlined"
                          onClick={() =>
                            push({
                              subject_id: '',
                              teacher_id: '',
                              max_marks: '',
                              passing_percentage: '',
                              is_optional: false,
                              student_paper_review: true,
                              status: true,
                            })
                          }
                          sx={{
                            mb: 2,
                          }}>
                          Add New Subject
                        </Button>
                      </div>
                    )}
                  </FieldArray>
                </Grid>
              </Grid>

              <Div
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}>
                <Button variant="contained" sx={{ px: 3 }} type="submit">
                  Submit
                </Button>
              </Div>
            </Div>
          </Form>
        )}
      </Formik>
    </Div>
  );
};
ClassForm.defaultProps = {
  onSave: () => {},
};
export default ClassForm;
