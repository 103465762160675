import { Typography } from '@mui/material';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { calculateGraph } from '../utils/calculations';

const TinyBarChart = ({ data }) => {
  const results = calculateGraph(data);

  return (
    <>
      <Typography>Branch Comparison</Typography>
      <ResponsiveContainer width="100%" height={200}>
        <BarChart
          data={results}
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
          <XAxis dataKey="name" />

          <YAxis
            yAxisId={'left'}
            orientation="left"
            dataKey="Expenses"
            stroke="#e91e63"
          />
          <YAxis
            yAxisId={'right'}
            orientation="right"
            dataKey="Earning"
            stroke="#3BD2A2"
          />

          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip
            labelStyle={{ color: 'black' }}
            itemStyle={{ color: 'black' }}
            cursor={false}
          />
          <Legend />
          <defs>
            <linearGradient x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#6200EE" stopOpacity={1} />
              <stop offset="95%" stopColor="#B819D2" stopOpacity={1} />
            </linearGradient>
          </defs>
          <defs>
            <linearGradient x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#1ABBDE" stopOpacity={1} />
              <stop offset="95%" stopColor="#09BCA7" stopOpacity={1} />
            </linearGradient>
          </defs>
          <Bar
            yAxisId={'left'}
            dataKey="Expenses"
            barSize={10}
            fill={'#e91e63'}
          />
          <Bar
            yAxisId={'right'}
            dataKey="Earning"
            barSize={10}
            fill={'#3BD2A2'}
          />
          <Bar
            yAxisId={'left'}
            dataKey="Cash In Hand"
            barSize={10}
            fill={'#b819d2'}
          />
          <Bar
            yAxisId={'left'}
            dataKey={'Non Expense Payments'}
            barSize={10}
            fill={'#6200ee'}
          />
          <Bar
            yAxisId={'right'}
            dataKey={'Profit Loss'}
            barSize={10}
            fill={'#1abbde'}
          />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default TinyBarChart;
