import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';

const EarningExpensesChart = ({ activeChart, revenueSummary }) => {
  const topChart = activeChart;
  const bottomChart = activeChart === 'earning' ? 'expense' : 'earning';
  const topChartColor = activeChart === 'earning' ? '#3BD2A2' : '#DF295E';
  const bottomChartColor = activeChart === 'earning' ? '#DF295E' : '#3BD2A2';

  return (
    <ResponsiveContainer height={152}>
      <AreaChart
        data={revenueSummary}
        margin={{ top: 0, right: 20, left: 20, bottom: 0 }}>
        <Tooltip
          content={({ active, label, payload }) => {
            return active ? (
              <div style={{ color: '#fff' }}>
                {payload.map((row, index) => {
                  return (
                    <div key={index} className={'mb-1'}>
                      <div
                        style={{
                          color: row.color,
                          fontSize: 8,
                          letterSpacing: 2,
                          textTransform: 'uppercase',
                        }}>
                        {row.name}
                      </div>
                      <div
                        style={{
                          color: row.color,
                        }}>
                        Rs {row.value}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null;
          }}
          wrapperStyle={{
            background: 'rgba(255,255,255,0.9)',
            borderRadius: 4,
            padding: '5px 8px',
            fontWeight: 500,
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        />
        <XAxis tickLine={false} dataKey="month" axisLine={true} />
        <Area
          dataKey={bottomChart}
          stackId="2"
          stroke={bottomChartColor}
          strokeOpacity={1}
          strokeWidth={2}
          fill={'transparent'}
        />
        <Area
          dataKey={topChart}
          stackId="1"
          stroke={topChartColor}
          strokeOpacity={1}
          strokeWidth={2}
          fill={'transparent'}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default EarningExpensesChart;
