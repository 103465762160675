import { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import {
  Grid,
  FormControlLabel,
  Switch,
  Button,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import UploadFileIcon from '@mui/icons-material/UploadFile';

import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import JumboDropDown from '@jumbo/components/JumboFormik/JumboDropDown';
import Div from '@jumbo/shared/Div';
import {
  addNewUser,
  setUserFormLoading,
  setUserFormLoadingFinished,
  updateUser,
} from 'app/store/features/usersSlice';
import { getAllBranches } from 'app/store/features/branchSlice';
import { userSchema } from '../../utils/schema';

import { USER_ROLES } from 'app/shared/constants';
import { getAllColors } from 'app/store/features/colorSlice';
import JumboPhoneNumber from '@jumbo/components/JumboFormik/JumboPhoneNumberField';
import JumboCNICField from '@jumbo/components/JumboFormik/JumboCNICField';
import { CameraAlt, FileUpload } from '@mui/icons-material';
import { getAllRoles } from 'app/store/features/rolesSlice';
import { uploadToStorage } from 'app/services/uploadImage';

const TeacherForm = ({ userData, isFromTeacher }) => {
  const dispatch = useDispatch();
  const { hideDialog } = useJumboDialog();
  const inputRef = useRef(null);
  const contractRef = useRef(null);
  const cvRef = useRef(null);
  const [branches, setBranches] = useState([]);

  const storeBranches = useSelector(state => state.branches.branches);
  const isFormLoading = useSelector(state => state.users.userFormLoading);
  const isStoreBranchesFetched = useSelector(
    state => state.branches.isDataFetched,
  );
  const roles = useSelector(state => state.roles.roles);
  const isRolesFetched = useSelector(state => state.roles.isRolesFetched);
  const storeColors = useSelector(state => state.color.colors);
  const isStoreColorsFetched = useSelector(state => state.color.isDataFetched);
  const isUserModalOpen = useSelector(state => state.users.isUserModalOpen);

  const fetchData = useCallback(async () => {
    if (!isStoreBranchesFetched) dispatch(getAllBranches());
    if (!isStoreColorsFetched) dispatch(getAllColors());
    if (!isRolesFetched) dispatch(getAllRoles());
  }, [dispatch, isRolesFetched, isStoreBranchesFetched, isStoreColorsFetched]);

  const isUpdate = userData ? true : false;
  const initialValues = {
    id: isUpdate ? true : false,
    branch_id: isUpdate ? userData.branch.id : '',
    color_id: isUpdate ? userData.color.id : 1,
    role_id: isUpdate ? userData.role.id : '',
    phone_no: isUpdate ? userData.phone_no : '',
    phone_no_2: isUpdate ? userData.phone_no_2 : '',
    contract: isUpdate ? userData.contract : '',
    cv: isUpdate ? userData.cv : '',
    image: isUpdate ? userData.image : '',
    cnic: isUpdate ? userData.cnic : '',
    reference: isUpdate ? userData.reference : '',
    recommended_salary: isUpdate ? Number(userData.recommended_salary) : 0,
    name: isUpdate ? userData.name : '',
    username: isUpdate ? userData.username : '',
    password: '',
    user_type: isUpdate ? userData.user_type : isFromTeacher ? 'teacher' : '',
    status: isUpdate ? userData.status : true,
  };

  const addUserHandler = async data => {
    const payload = { ...data };
    if (!data.recommended_salary) delete payload['recommended_salary'];
    delete payload['id'];

    // if (data?.cv) {
    //   const url = await uploadToStorage(data.cv, 'CVs');
    //   payload.cv = url;
    // }
    // if (data?.image) {
    //   const url = await uploadToStorage(data.image);
    //   payload.image = url;
    // }
    // if (data?.contract) {
    //   const url = await uploadToStorage(data.contract, 'Contracts');
    //   payload.contract = url;
    // }

    dispatch(setUserFormLoading());
    const promises = [];

    if (data?.cv) {
      promises.push(uploadToStorage(data.cv, 'CVs'));
    }
    if (data?.image) {
      promises.push(uploadToStorage(data.image));
    }
    if (data?.contract) {
      promises.push(uploadToStorage(data.contract, 'Contracts'));
    }

    if (promises.length > 0) {
      const results = await Promise.all(promises);

      if (data?.cv) {
        payload.cv = results.shift();
      }
      if (data?.image) {
        payload.image = results.shift();
      }
      if (data?.contract) {
        payload.contract = results.shift();
      }
    }

    dispatch(addNewUser(payload));
  };

  const updateUserHandler = async data => {
    const payload = { ...data };
    const id = userData.id;
    if (!data.recommended_salary) delete payload['recommended_salary'];
    if (!data.password) delete payload['password'];
    delete payload['id'];

    dispatch(setUserFormLoading());

    const promises = [];

    if (data?.cv && typeof data?.cv !== 'string') {
      promises.push(uploadToStorage(data.cv, 'CVs'));
    }
    if (data?.image && typeof data?.image !== 'string') {
      promises.push(uploadToStorage(data.image));
    }
    if (data?.contract && typeof data?.contract !== 'string') {
      promises.push(uploadToStorage(data.contract, 'Contracts'));
    }

    if (promises.length > 0) {
      const results = await Promise.all(promises);

      if (data?.cv) {
        payload.cv = results.shift();
      }
      if (data?.image) {
        payload.image = results.shift();
      }
      if (data?.contract) {
        payload.contract = results.shift();
      }
    }

    dispatch(updateUser({ id, payload }));
  };

  const onUserSave = data => {
    isUpdate ? updateUserHandler(data) : addUserHandler(data);
  };

  useEffect(() => {
    if (storeBranches.length !== branches.length) setBranches(storeBranches);
    fetchData();

    if (!isUserModalOpen) hideDialog();

    return () => {
      dispatch(setUserFormLoadingFinished());
    };
  }, [
    fetchData,
    branches,
    storeBranches,
    isUserModalOpen,
    hideDialog,
    dispatch,
  ]);

  return (
    <>
      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={userSchema}
        onSubmit={onUserSave}>
        {({ isSubmitting, values, setFieldValue, touched, errors }) => (
          <Form noValidate autoComplete="off">
            <Grid
              container
              columnSpacing={2}
              sx={{
                '& .MuiTextField-root': {
                  mb: 3,
                },
              }}>
              <Grid item xs={12} sm={6}>
                <JumboTextField
                  size="small"
                  variant="outlined"
                  name="name"
                  label="Name*"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <JumboTextField
                  size="small"
                  variant="outlined"
                  name="username"
                  label="Username*"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <JumboPhoneNumber
                  size="small"
                  variant="outlined"
                  name="phone_no"
                  label="Phone Number*"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <JumboPhoneNumber
                  size="small"
                  variant="outlined"
                  name="phone_no_2"
                  label="Phone Number 2"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <JumboTextField
                  size="small"
                  variant="outlined"
                  name="password"
                  label={isUpdate ? 'Update Password' : 'Password*'}
                  type="password"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <JumboCNICField
                  size="small"
                  variant="outlined"
                  name="cnic"
                  label="User's CNIC"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <JumboTextField
                  size="small"
                  variant="outlined"
                  name="recommended_salary"
                  label="Recommended Salary"
                  type="number"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <JumboTextField
                  size="small"
                  variant="outlined"
                  name="reference"
                  label="User's Reference"
                  fullWidth
                />
              </Grid>

              {!isFromTeacher && (
                <Grid item xs={12} sm={6}>
                  <JumboDropDown
                    size="small"
                    variant="outlined"
                    name="user_type"
                    label="User Type*"
                    options={USER_ROLES}
                    fullWidth
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={6}>
                <JumboDropDown
                  size="small"
                  variant="outlined"
                  name="role_id"
                  label="User's Role*"
                  options={roles}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <JumboDropDown
                  size="small"
                  variant="outlined"
                  name="branch_id"
                  label="Branch Name*"
                  options={storeBranches}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <JumboDropDown
                  size="small"
                  variant="outlined"
                  name="color_id"
                  label="User's Color*"
                  options={storeColors}
                  colors
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.status}
                      onChange={e => setFieldValue('status', e.target.checked)}
                    />
                  }
                  label={'Status of User?'}
                  sx={{ mb: 2 }}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1">Upload Documents/Images</Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <input
                  onChange={e => {
                    setFieldValue('image', e.target.files[0]);
                  }}
                  type="file"
                  hidden
                  ref={inputRef}
                />
                <Typography variant="body2" textAlign={'left'} color={'red'}>
                  {touched.image && errors.image ? errors.image : ''}
                </Typography>
                <Button
                  onClick={() => inputRef.current.click()}
                  variant="contained"
                  startIcon={<CameraAlt />}>
                  {values.image
                    ? values.image.name?.slice(0, 10)
                    : 'Upload Image'}
                </Button>
              </Grid>

              <Grid item xs={12} sm={4}>
                <input
                  onChange={e => {
                    setFieldValue('contract', e.target.files[0]);
                  }}
                  type="file"
                  hidden
                  ref={contractRef}
                />
                <Typography variant="body2" textAlign={'left'} color={'red'}>
                  {touched.contract && errors.contract ? errors.contract : ''}
                </Typography>
                <Button
                  onClick={() => contractRef.current.click()}
                  variant="contained"
                  startIcon={<UploadFileIcon />}>
                  {values.contract
                    ? values.contract.name?.slice(0, 10)
                    : 'Upload Contract'}
                </Button>
              </Grid>

              <Grid item xs={12} sm={4}>
                <input
                  onChange={e => {
                    setFieldValue('cv', e.target.files[0]);
                  }}
                  type="file"
                  hidden
                  ref={cvRef}
                />
                <Typography variant="body2" textAlign={'left'} color={'red'}>
                  {touched.cv && errors.cv ? errors.cv : ''}
                </Typography>
                <Button
                  onClick={() => cvRef.current.click()}
                  variant="contained"
                  startIcon={<FileUpload />}>
                  {values.cv ? values.cv.name?.slice(0, 10) : 'Upload CV'}
                </Button>
              </Grid>
            </Grid>
            <Div
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                marginTop: 3,
              }}>
              <LoadingButton
                type="submit"
                variant="outlined"
                size="large"
                sx={{ mr: 3 }}
                onClick={() => hideDialog()}>
                Close
              </LoadingButton>
              <LoadingButton
                type="submit"
                variant="contained"
                size="large"
                loading={isFormLoading}>
                Save
              </LoadingButton>
            </Div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default TeacherForm;
