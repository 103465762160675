import { IMAGE_SUPPORTED_FORMATS } from 'app/shared/constants';
import * as yup from 'yup';

const phoneNumberRegEx = /^\+92-[0-9]{3}\s[0-9]{7}$/;
const cnicRegEx = /^[0-9]{5}-[0-9]{7}-[0-9]$/;

export const personalInfoSchema = yup.object().shape({
  image: yup
    .mixed()
    .nullable()
    .test(
      'FILE_SIZE',
      'Uploaded File is too big, max 400MB image can be uploaded',
      value => !value || (value && value.size <= 1024 * 4000),
    )
    .test(
      'FILE_FORMAT',
      'Uploaded file has unsupported format',
      value =>
        !value || (value && IMAGE_SUPPORTED_FORMATS.includes(value?.type)),
    ),
  first_name: yup.string().required('Student First name is Required'),
  last_name: yup.string().nullable(),
  nick_name: yup.string().nullable(),
  cnic: yup
    .string()
    .matches(cnicRegEx, 'Enter a valid CNIC in format xxxxx-xxxxxxx-x')
    .nullable(),
  gender: yup.number().required('Please Select gender of Student'),
  date_of_birth: yup
    .date()
    .max(new Date(), 'Date of Birth cannot be in the future')
    .required('Date of Birth is Required')
    .test('age', 'Student must be at least 3 years old', function (value) {
      const minAgeDate = new Date();
      minAgeDate.setFullYear(minAgeDate.getFullYear() - 3);
      return new Date(value) <= minAgeDate;
    }),
  district: yup.string().required('District is Required'),
  tehsil: yup.string().required('Tehsil is Required'),
  village_id: yup.number().required('Village is Required'),
  address: yup.string().required('Address is Required'),
  blood_group: yup.string().required('Blood Group of Student is Required'),
  religion: yup.string().required('Enter Religion of Student'),
  home_language: yup.string().required('Home Language is Required'),
  nationality: yup.string().required("Student's Nationality is Required"),
});

export const parentalInfoSchema = yup.object().shape({
  father_name: yup.string().required('Father"s Name is Required'),
  father_cnic: yup
    .string()
    .matches(cnicRegEx, 'Enter a valid CNIC in format xxxxx-xxxxxxx-x')
    .required("Father's CNIC Number is Required"),
  father_occupation_id: yup
    .number()
    .positive()
    .required('Father Occupation is Required'),
  father_qualification: yup.string().nullable(),
  father_mobile_number: yup
    .string()
    .matches(
      phoneNumberRegEx,
      'Enter a valid phone number in the format +92-xxx xxxxxxx',
    )
    .nullable(),

  mother_name: yup.string().required('Mother"s Name is Required'),
  mother_qualification: yup.string().nullable(),
  mother_occupation_id: yup
    .number()
    .positive()
    .required('Mother Occupation is Required'),
  mother_mobile_number: yup
    .string()
    .matches(
      phoneNumberRegEx,
      'Enter a valid phone number in the format +92-xxx xxxxxxx',
    )
    .nullable(),
  other_mobile_number: yup
    .string()
    .matches(
      phoneNumberRegEx,
      'Enter a valid phone number in the format +92-xxx xxxxxx',
    )
    .nullable(),

  mother_cnic: yup
    .string()
    .matches(cnicRegEx, 'Enter a valid CNIC in format xxxxx-xxxxxxx-x')
    .required(),

  primary_contact: yup.string().required('Primary Contact is Required'),

  family_code: yup.string('Family Code should be a number').nullable(),
  caste: yup.string().nullable(),
});

export const schoolInfoSchema = yup.object().shape({
  branch_id: yup
    .number()
    .positive('Branch ID cannot be negative')
    .required('Branch Name is Required'),
  class_section_id: yup.string().required('Class Name is Required'),
  status: yup.boolean().required('Status is Required'),
  roll_no: yup
    .number()
    .positive('Roll Number Cannot be Negative')
    .required('Roll Number is Required'),
  pre_school_name: yup.string(),
  prev_no_of_schools: yup
    .number()
    .min(0, 'Schools cannot be Negative')
    .default(0),
  prev_no_of_failures: yup
    .number()
    .min(0, 'Failures cannot be Negative')
    .default(0),
  caution: yup.string().nullable(),
  medium: yup.string().required('Medium is Required'),
  inactive_from: yup.string(),
  inactive_reason_id: yup.string().nullable(),
  is_special_case: yup.boolean().required('Is student Special Case?'),
  special_remarks: yup.string().nullable(),
  first_month_fee: yup.number().positive('Fee cannot be Negative').nullable(),
  register_fee_discount: yup
    .number()
    .positive('Discount cannot be Negative')
    .nullable(),
  school_transport: yup.boolean().required('Please Check for school Transport'),
  is_employee_child: yup.boolean().required('Is student Employee"s child?'),
  first_school: yup.boolean().required('Is FOTAN student"s First School?'),
});
