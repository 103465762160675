import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';

import JumboButton from '@jumbo/components/JumboButton';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import JumboSwitch from '@jumbo/components/JumboFormik/JumboSwitch';
import JumboDropDown from '@jumbo/components/JumboFormik/JumboDropDown';

import { getAllUsers } from 'app/store/features/usersSlice';
import { EXAM_TERMS, EXAM_TYPES } from 'app/shared/constants';
import { Typography } from '@mui/material';
import Div from '@jumbo/shared/Div';
import { examFollowUpSchema } from '../../utils/schema';
import { getAllClassSectionSubjects } from 'app/store/features/subjectsSlice';
import {
  addExamFollowUp,
  editExamFollowUp,
} from 'app/store/features/examinationSlice';
import { useLocation, useNavigate } from 'react-router-dom';

const ExamFollowUpForm = () => {
  const dispatch = useDispatch();
  const subjects = useSelector(state => state.subjects.classSectionSubjects);
  const users = useSelector(state => state.users.users);
  const isSubjectsFetched = useSelector(
    state => state.subjects.isClassSectionSubjectsFetched,
  );
  const isUsersFetched = useSelector(state => state.users.isDataFetched);
  const navigate = useNavigate();
  const { state } = useLocation();

  const fetchData = useCallback(async () => {
    if (!isSubjectsFetched) dispatch(getAllClassSectionSubjects());
    if (!isUsersFetched) dispatch(getAllUsers());
  }, [dispatch, isSubjectsFetched, isUsersFetched]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const initialValues = {
    class_section_subject_id: state ? state?.class_section_subject?.id : 1,
    exam_term: state ? state.exam_term : '',
    exam_type: state ? state.exam_type : '',
    exam_hall: state ? state.exam_hall : '',
    checker_id: state ? state?.checker?.id : '',
    rechecker_id: state ? state?.rechecker?.id : '',
    al_initiator_id: state ? state?.al_initiator?.id : '',
    al_second_initiator_id: state ? state?.al_second_initiator?.id : '',
    al_verifier_id: state ? state?.al_verifier?.id : '',
    al_final_rev_id: state ? state?.al_final_rev?.id : '',
    remarks_initiator_id: state ? state?.remarks_initiator?.id : '',
    remarks_final_rev_id: state ? state.remarks_final_rev?.id : '',
    invigilator_id: state ? state?.invigilator?.id : 1,
    exam_status: state ? state?.exam_status : true,
    remarks_status: state ? state?.remarks_status : true,
  };

  const handleSubmit = async data => {
    if (state) {
      data.id = state.id;
      await dispatch(editExamFollowUp(data)).unwrap();
    } else {
      await dispatch(addExamFollowUp(data)).unwrap();
    }
    navigate('/app/exam-follow/all');
  };

  return (
    <>
      <Typography variant="h3" my={3} textAlign={'center'}>
        {state && 'Update '}Exam Follow Up Form
      </Typography>
      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={examFollowUpSchema}
        onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form noValidate autoComplete="off">
            <JumboDropDown
              fullWidth
              size="small"
              variant="outlined"
              name="class_section_subject_id"
              label="Class Section Subject"
              options={subjects}
              isExamFollowUp={true}
            />
            <JumboDropDown
              fullWidth
              size="small"
              variant="outlined"
              name="exam_term"
              label="Exam Term"
              options={EXAM_TERMS}
            />

            <JumboDropDown
              fullWidth
              size="small"
              variant="outlined"
              name="exam_type"
              label="Exam Type"
              options={EXAM_TYPES}
            />

            <JumboTextField
              fullWidth
              size="small"
              variant="outlined"
              name="exam_hall"
              label="Exam Hall"
            />
            <JumboDropDown
              fullWidth
              size="small"
              variant="outlined"
              name="checker_id"
              label="Checker"
              options={users}
            />

            <JumboDropDown
              fullWidth
              size="small"
              variant="outlined"
              name="rechecker_id"
              label="Rechecker"
              options={users}
            />

            <JumboDropDown
              fullWidth
              size="small"
              variant="outlined"
              name="al_initiator_id"
              label="AL Initiator"
              options={users}
            />

            <JumboDropDown
              fullWidth
              size="small"
              variant="outlined"
              name="al_second_initiator_id"
              label="AL Second Initiator"
              options={users}
            />

            <JumboDropDown
              fullWidth
              size="small"
              variant="outlined"
              name="al_verifier_id"
              label="AL Verifier"
              options={users}
            />

            <JumboDropDown
              fullWidth
              size="small"
              variant="outlined"
              name="al_final_rev_id"
              label="AL Final Review"
              options={users}
            />

            <JumboDropDown
              fullWidth
              size="small"
              variant="outlined"
              name="remarks_initiator_id"
              label="Remarks Initiator"
              options={users}
            />

            <JumboDropDown
              fullWidth
              size="small"
              variant="outlined"
              name="remarks_final_rev_id"
              label="Remarks Final Review"
              options={users}
            />
            <Div>
              <JumboSwitch
                fullWidth
                size="small"
                name="exam_status"
                label="Exam Status"
              />
              <JumboSwitch
                fullWidth
                size="small"
                name="remarks_status"
                label="Remarks Status"
              />
            </Div>

            <Div
              sx={{
                margin: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <JumboButton
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                size="large">
                {state ? 'Update' : 'Submit'}
              </JumboButton>
            </Div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ExamFollowUpForm;
