import { Grid } from '@mui/material';

import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import GenderSeparation from './components/GenderSeparation';
import BranchesGraph from './components/BranchesGraph';
import GenderComparison from './components/GenderComparison';

const Summary = ({ title, data, isStaff }) => {
  return (
    <JumboCardQuick title={title} wrapperSx={title ? { pt: 1 } : { pt: 4 }}>
      <Grid container spacing={3.75}>
        <Grid item xs={12} sm={6} lg={3}>
          <GenderSeparation data={data} />
        </Grid>
        <Grid item xs={12} sm={6} lg={3} sx={{ order: { lg: 3 } }}>
          <GenderComparison data={data} isStaff={isStaff} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <BranchesGraph data={data} isStaff={isStaff} />
        </Grid>
      </Grid>
    </JumboCardQuick>
  );
};

export default Summary;
