import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import BranchesRevenue from './components/BranchesRevenue';
import BranchesGraph from './components/BranchesGraph';
import BranchesComparison from './components/BranchesComparison';
import DropDown from 'app/shared/components/DropDown';

import { branchRevenue } from './utils/calculations';

const options = [
  {
    key: 1,
    label: 'Last Month',
    value: 'Last Month',
  },
  {
    key: 2,
    label: 'Last 3 Months',
    value: 'Last 3 Months',
  },
  {
    key: 3,
    label: 'Last 6 Months',
    value: 'Last 6 Months',
  },
  {
    key: 4,
    label: 'Whole year',
    value: 'Whole year',
  },
];

const BranchesSummary = ({ title = 'Branches Statistics' }) => {
  const { branches } = useSelector(state => state.accounts.accounts);
  const [dataToShow, setDataToShow] = useState(options[0].value);

  const value = useMemo(
    () => branchRevenue(branches, dataToShow),
    [dataToShow, branches],
  );
  return (
    <JumboCardQuick title={title} wrapperSx={{ pt: 1 }}>
      <DropDown
        options={options}
        label="Select Span of Time"
        value={dataToShow}
        onChange={e => setDataToShow(e.target.value)}
      />
      <Grid container spacing={3.75}>
        <Grid item xs={12} sm={6} lg={3}>
          <BranchesRevenue data={value} />
        </Grid>
        <Grid item xs={12} sm={6} lg={3} sx={{ order: { lg: 3 } }}>
          <BranchesComparison data={value} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <BranchesGraph data={value} />
        </Grid>
      </Grid>
    </JumboCardQuick>
  );
};

export default BranchesSummary;
