import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  API_DELETE,
  API_GET,
  API_POST,
  API_PUT,
} from 'app/utils/constants/api/apiRequests';
import { setLoading, setLoadingFinished } from './loadingSlice';
import { showAlert } from './alertSlice';

const initialState = {
  colors: [],
  isDataFetched: false,
};

export const getAllColors = createAsyncThunk(
  'color/getAllColors',
  async (_, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data } = await API_GET('/color');

      if (status) {
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const addNewColor = createAsyncThunk(
  'color/addNewColor',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, message } = await API_POST('/color', payload);
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllColors());
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const editColor = createAsyncThunk(
  'color/editColor',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    const dataToSend = { name: payload.name, status: payload.status };
    try {
      const { status, data, message } = await API_PUT(
        '/color',
        payload.id,
        dataToSend,
      );
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllColors());
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const deleteColor = createAsyncThunk(
  'color/deleteColor',
  async (id, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_DELETE('/color', id);
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllColors());
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const colorSlice = createSlice({
  name: 'color',
  initialState,

  extraReducers: builder => {
    builder.addCase(getAllColors.fulfilled, (state, { payload }) => {
      state.colors = payload;
      state.isDataFetched = true;
    });
  },
});

export default colorSlice.reducer;
