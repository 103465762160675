import * as yup from 'yup';

export const userSchema = yup.object().shape({
  branch_id: yup.number().positive().required('Branch is Required'),
  color_id: yup.number().positive().required('Color is Required'),
  role_id: yup.number().positive().required('Role is Required'),
  phone_no: yup.string().max(20).required('Phone Number is Required'),
  phone_no_2: yup.string().max(20),
  contract: yup.string(),
  cv: yup.string(),
  image: yup.string(),
  cnic: yup.string(),
  reference: yup.string(),
  recommended_salary: yup.number().min(0, 'Salary must be positive'),
  name: yup.string().required('Name is Required'),
  username: yup
    .string()
    .max(30, 'Username should be less than 30 characters')
    .required('User Name is Required'),
  password: yup
    .string()
    .min(3, 'Password must be greater than 3 characters')
    .max(50, 'Password must be less than 50 characters')
    .when('id', {
      is: true,
      then: yup.string(),
      otherwise: yup.string().required('Password is required'),
    }),
  user_type: yup.string().required('User Type is Required'),
  status: yup.boolean().required('Status is Required'),
});
