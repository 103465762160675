import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Typography,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import LogoutIcon from '@mui/icons-material/Logout';
import { logOut } from 'app/store/features/authSlice';
import { Edit } from '@mui/icons-material';

const MenuDropDown = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(state => state.auth.user);

  const onLogout = () => {
    dispatch(logOut());
    navigate('/auth/login');
  };
  return (
    <>
      <Div
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          p: theme => theme.spacing(2.5),
        }}>
        <Avatar
          src={user.profile_pic}
          alt={user.username}
          sx={{ width: 60, height: 60, mb: 2 }}
        />
        <Typography variant={'h5'}>{user.username}</Typography>
        <Typography
          variant={'body1'}
          color="text.secondary"
          width={'200px'}
          textAlign={'center'}>
          {user.role}
        </Typography>
      </Div>
      <Divider />
      <nav>
        <List disablePadding sx={{ pb: 1 }}>
          {/* <ListItemButton>
            <ListItemIcon sx={{ minWidth: 36 }}>
              <PersonOutlineIcon />
            </ListItemIcon>
            <ListItemText primary="Profile" sx={{ my: 0 }} />
          </ListItemButton>
          <ListItemButton>
            <ListItemIcon sx={{ minWidth: 36 }}>
              <EditOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Edit Profile" sx={{ my: 0 }} />
          </ListItemButton> */}
          <ListItemButton onClick={() => navigate('/profile/edit')}>
            <ListItemIcon sx={{ minWidth: 36 }}>
              <Edit />
            </ListItemIcon>
            <ListItemText primary="Edit Profile" sx={{ my: 0 }} />
          </ListItemButton>
          <ListItemButton onClick={onLogout}>
            <ListItemIcon sx={{ minWidth: 36 }}>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" sx={{ my: 0 }} />
          </ListItemButton>
        </List>
      </nav>
    </>
  );
};

export default MenuDropDown;
