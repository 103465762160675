import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import ExaminationList from '../components/ExamFollowUpList';
import ExamFollowUpSidebar from '../components/ExamFollowUpSidebar';
import { getExamFollowUps } from 'app/store/features/examinationSlice';

const ExamFollowUp = () => {
  const dispatch = useDispatch();
  const isDataFetched = useSelector(
    state => state.examination.isExamFollowUpsFetched,
  );

  const fetchData = useCallback(() => {
    if (!isDataFetched) dispatch(getExamFollowUps());
  }, [dispatch, isDataFetched]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  return (
    <>
      <JumboContentLayout sidebar={<ExamFollowUpSidebar />}>
        <ExaminationList />
      </JumboContentLayout>
    </>
  );
};
export default ExamFollowUp;
