import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';

import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import Div from '@jumbo/shared/Div';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';

import { addExpense } from 'app/store/features/accountsSlice';
import { expenseSchema } from '../../utils/schema';

const initialValues = {
  expense: 0,
  expenseReason: '',
};

const ExpenseForm = ({ data }) => {
  const Swal = useSwalWrapper();
  const dispatch = useDispatch();
  const { hideDialog } = useJumboDialog();

  const onExpenseSave = data => {
    dispatch(addExpense(data));
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Expense has been added successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
    hideDialog();
  };

  return (
    <>
      <Formik
        validateOnChange={true}
        initialValues={data ? data : initialValues}
        validationSchema={expenseSchema}
        onSubmit={onExpenseSave}
        enableReinitialize={true}>
        {({ isSubmitting }) => (
          <Form noValidate autoComplete="off">
            <Div
              sx={{
                '& .MuiTextField-root': {
                  mb: 3,
                },
              }}>
              <JumboTextField
                fullWidth
                size="medium"
                variant="outlined"
                name="expense"
                label="Enter Expense Amount in PKR"
                type="number"
              />

              <JumboTextField
                fullWidth
                size="medium"
                variant="outlined"
                name="expenseReason"
                label="Withdrawal Reason"
                type="text"
              />
              <Div
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                }}>
                <LoadingButton
                  variant="contained"
                  size="large"
                  sx={{
                    backgroundColor: '#e44a77',
                    ':hover': {
                      backgroundColor: '#e44a77',
                    },
                    mb: 3,
                  }}
                  onClick={() => hideDialog()}>
                  Cancel
                </LoadingButton>

                <LoadingButton
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mb: 3 }}
                  loading={isSubmitting}>
                  Save
                </LoadingButton>
              </Div>
            </Div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ExpenseForm;
