import React, { useCallback, useEffect } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Tooltip,
  Typography,
  Stack,
} from '@mui/material';
import {
  AccountBalance,
  MoreHoriz,
  Money,
  ToggleOn,
  ToggleOff,
  Edit,
  Delete,
} from '@mui/icons-material';
import styled from '@emotion/styled';

import JumboDdMenu from '@jumbo/components/JumboDdMenu';
import Span from '@jumbo/shared/Span';
import JumboListItem from '@jumbo/components/JumboList/components/JumboListItem';
import JumboGridItem from '@jumbo/components/JumboList/components/JumboGridItem';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { deleteRole } from 'app/store/features/rolesSlice';

const Item = styled(Span)(({ theme }) => ({
  minWidth: 0,
  flexGrow: 0,
  padding: theme.spacing(0, 1),
}));

const RoleItem = ({ item, view }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleItemAction = menuItem => {
    switch (menuItem.action) {
      case 'edit':
        navigate('/app/roles/form', {
          state: {
            id: item.id,
          },
        });
        break;

      case 'delete':
        dispatch(deleteRole(item.id));
        break;
      default:
        break;
    }
  };

  if (view === 'grid') {
    return (
      <JumboGridItem xs={12} md={6} lg={4}>
        <Card variant="outlined" elevation={0}>
          <CardHeader
            action={
              <>
                <JumboDdMenu
                  icon={<MoreHoriz />}
                  menuItems={[
                    { icon: <Edit />, title: 'Edit', action: 'edit' },
                    { icon: <Delete />, title: 'Delete', action: 'delete' },
                  ]}
                  onClickCallback={handleItemAction}
                />
              </>
            }
            title={
              <Tooltip title="Role's Title">
                <Typography variant={'h6'} color={'text.primary'} mb={0.25}>
                  {item?.title}
                </Typography>
              </Tooltip>
            }
            subheader={
              <Tooltip title="Role's Display Title">
                <Typography variant={'body1'} color={'text.secondary'}>
                  {item?.display_title}
                </Typography>
              </Tooltip>
            }
          />
          <CardContent sx={{ pt: 0 }}>
            <Divider sx={{ mb: 2 }} />
            <List disablePadding>
              {item?.comment && (
                <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                  <ListItemIcon sx={{ minWidth: 50 }}>
                    <Money />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Tooltip title="Comment about Role">
                        <Typography variant={'body1'} color={'text.primary'}>
                          {item?.comment}
                        </Typography>
                      </Tooltip>
                    }
                  />
                </ListItem>
              )}

              <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                <ListItemIcon sx={{ minWidth: 50 }}>
                  {item?.status ? <ToggleOn /> : <ToggleOff />}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Tooltip title="Role's Status">
                      <Typography variant={'body1'}>
                        {item?.status ? 'Active' : 'InActive'}
                      </Typography>
                    </Tooltip>
                  }
                />
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </JumboGridItem>
    );
  }
  return (
    <JumboListItem
      componentElement={'div'}
      itemData={item}
      secondaryAction={
        <JumboDdMenu
          icon={<MoreHoriz />}
          menuItems={[
            { icon: <Edit />, title: 'Edit', action: 'edit' },
            { icon: <Delete />, title: 'Delete', action: 'delete' },
          ]}
          onClickCallback={handleItemAction}
        />
      }
      sx={{
        cursor: 'pointer',
        borderTop: 1,
        borderColor: 'divider',
        '&:hover': {
          bgcolor: 'action.hover',
        },
      }}>
      <ListItemText
        primary={
          <Typography variant={'body1'} component={'div'}>
            <Stack direction={'row'} alignItems={'center'} sx={{ minWidth: 0 }}>
              <Item
                sx={{
                  flexBasis: { xs: '100%', sm: '50%', md: '25%' },
                }}>
                <Tooltip title="Role's Title">
                  <Typography
                    variant={'h5'}
                    fontSize={14}
                    lineHeight={1.25}
                    mb={0}
                    noWrap>
                    {item?.title}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { sm: '50%', md: '28%' },
                  display: { xs: 'none', sm: 'block' },
                }}>
                <Tooltip title={"Role's Display Title"}>
                  <Typography variant={'body1'} noWrap>
                    {item?.display_title}
                  </Typography>
                </Tooltip>
              </Item>
              {item?.comment && (
                <Item
                  sx={{
                    flexBasis: { sm: '50%', md: '28%' },
                    display: { xs: 'none', sm: 'block' },
                  }}>
                  <Tooltip title={'Comment about Role'}>
                    <Typography variant={'body1'} noWrap>
                      {item?.comment}
                    </Typography>
                  </Tooltip>
                </Item>
              )}
              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title="Role's Status">
                  <Typography variant={'body1'} noWrap>
                    {item?.status ? 'Active' : 'InActive'}
                  </Typography>
                </Tooltip>
              </Item>

              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title="Role's Created At">
                  <Typography variant={'body1'} noWrap>
                    {moment(item?.createdAt).format('DD-MM-YYYY')}
                  </Typography>
                </Tooltip>
              </Item>
            </Stack>
          </Typography>
        }
      />
    </JumboListItem>
  );
};

export default RoleItem;
