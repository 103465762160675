import * as yup from 'yup';

export const subjectSchema = yup.object().shape({
  name: yup.string().required('Subject Name is Required'),
  user_subject_code: yup.string().required('Subject Code is Required'),
  fee_amount: yup
    .number()
    .min(500, 'Fee Should be Greater than PKR. 500')
    .required('Fee of Subject is Required'),
  group: yup.string().required('Subject Group is Required'),
  status: yup.string().required('Status of Subject is Required'),
});

export const classSectionSubjectSchema = yup.object().shape({
  class_section_id: yup
    .number()
    .positive()
    .required('Class Section is Required'),
  subject_id: yup.number().positive().required('Subject is Required'),
  teacher_id: yup.number().positive().required('Teacher is Required'),
  max_marks: yup
    .number()
    .min(33, 'Maximum marks cannot be less than 33')
    .max(100, 'Maximum marks cannot be greater than 100')
    .required('Maximum marks are required'),
  passing_percentage: yup
    .number()
    .min(25, 'Passing Percentage cannot be less than 25%')
    .max(50, 'Passing Percentage cannot be greater than 50%')
    .required('Passing Percentage is Required'),
  is_optional: yup.string().required('Is Subject Optional?'),
  student_paper_review: yup
    .string()
    .required('Student Paper Review is Required?'),
  status: yup.string().required('Status of Subject is Required'),
});
