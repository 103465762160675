import moment from 'moment';
import { config } from '../config/main';

export const getAssetPath = (url, size) => {
  if (config.useImagePlaceHolder) {
    return `https://via.placeholder.com/${size}.png`;
  }

  return url;
};

export const removeEmptyFromObject = obj => {
  let result = {};
  Object.keys(obj).forEach(key => {
    if (obj[key] !== '') {
      result = { ...result, obj: obj[key] };
    }
  });
};

export const titlalize = word => word[0].toUpperCase() + word.slice(1);

export const getCurrentFormattedDate = () => {
  let month = moment().month().toString();
  let date = moment().date().toString();

  if (month.length === 1) {
    month = `0${Number(month) + 1}`;
  }
  if (date.length === 1) {
    date = `0${date}`;
  }

  return `${moment().year()}-${month}-${date}`;
};

export const formatRoleName = role => {
  return role
    .split('-')
    .map(word => word[0].toUpperCase() + word.slice(1))
    .join(' ');
};

export const getSchoolCode = (id, villages) => {
  let village = villages.find(village => village.id === id);

  village = village.name;

  switch (village.toLowerCase()) {
    case 'Bheega-A'.toLowerCase():
      // return 'BHGA';
      return 1;
    case 'Bheega-B'.toLowerCase():
      // return 'BHGB';
      return 2;
    case 'Ghaiyyewala'.toLowerCase():
      // return 'GYW';
      return 3;
    case 'Dera Jammu'.toLowerCase():
      // return 'DRJ';
      return 4;
    case 'Ladhewala'.toLowerCase():
      // return 'LDW';
      return 5;
    case 'Kalaske'.toLowerCase():
      // return 'KLS';
      return 6;
    case 'Mohlinke'.toLowerCase():
      // return 'MLK';
      return 7;

    case 'Mallan Wali'.toLowerCase():
      // return 'MWL';
      return 8;
    case 'Hassan Wali'.toLowerCase():
      // return 'HSW';
      return 9;
    case 'Kot Qadir Bakhsh'.toLowerCase():
      // return 'KQB';
      return 10;
    case 'Kot Jahangeer'.toLowerCase():
      // return 'KJH';
      return 11;
    case 'Bhoma Bath'.toLowerCase():
      // return 'BBT';
      return 12;
    default:
      // return 'GWR';
      return 13;
  }
};

export const formatPhoneNumber = value => {
  const numbersOnly = value.replace(/\D/g, '');
  let countryCode = numbersOnly.slice(0, 2);
  let areaCode = numbersOnly.slice(2, 5);
  let remaining = numbersOnly.slice(5, 12);

  countryCode = countryCode.replace(/^0+/, '');
  areaCode = areaCode.replace(/^0+/, '');

  let formattedValue = '';
  if (countryCode) {
    formattedValue += `+${countryCode}`;
  }
  if (areaCode) {
    formattedValue += `-${areaCode}`;
  }
  if (remaining) {
    formattedValue += ` ${remaining}`;
  }

  return formattedValue;
};

export const formatResourcePermissions = permissions => {
  const permissionMap = {};

  permissions.forEach(permission => {
    const resourceId = permission.resource_id;
    if (!permissionMap[resourceId]) {
      permissionMap[resourceId] = {
        resource_id: resourceId,
        permissions: [],
      };
    }
    permissionMap[resourceId].permissions.push({
      permission_id: permission.permission_id,
      allowed: permission.allowed,
    });
  });

  const groupedPermissions = Object.values(permissionMap);
  return groupedPermissions;
};

export function removeEmptyKeys(obj) {
  const newObj = {};

  for (const key in obj) {
    if (obj[key] !== '' && obj[key] !== null && obj[key] !== undefined) {
      newObj[key] = obj[key];
    }
  }

  return newObj;
}
