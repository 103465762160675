import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Card, CardHeader, CardContent } from '@mui/material';

import Div from '@jumbo/shared/Div';
import DropDown from 'app/shared/components/DropDown';

import { extractBranches, calculateGraph } from '../../utils';

const MonthlyStats = ({ selectedBranch = 0, noCard }) => {
  const { branches } = useSelector(state => state.accounts.accounts);
  const results = extractBranches(branches);

  const [branch, setBranch] = useState(
    results.length ? results[selectedBranch]?.label : '',
  );
  const temp = calculateGraph(branches, branch);
  const [graphResults, setGraphResults] = useState(temp);

  useEffect(() => {
    setGraphResults(calculateGraph(branches, branch));
  }, [branch, branches]);

  const handleBranchChange = useCallback(e => {
    setBranch(e.target.value);
  }, []);

  if (noCard) {
    return (
      <CardContent>
        <DropDown
          label={'Select Branch'}
          options={results}
          value={branch}
          onChange={handleBranchChange}
        />
        <Div>
          <ResponsiveContainer width="100%" height={200}>
            <BarChart
              data={graphResults}
              margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
              <XAxis dataKey="name" />
              <YAxis yAxisId="Expenses" orientation="left" stroke="#e91e63" />
              <YAxis yAxisId="Earning" orientation="right" stroke="#3BD2A2" />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip
                labelStyle={{ color: 'black' }}
                itemStyle={{ color: 'black' }}
                cursor={false}
              />
              <Legend />
              <defs>
                <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#6200EE" stopOpacity={1} />
                  <stop offset="95%" stopColor="#B819D2" stopOpacity={1} />
                </linearGradient>
              </defs>
              <defs>
                <linearGradient id="color2" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#1ABBDE" stopOpacity={1} />
                  <stop offset="95%" stopColor="#09BCA7" stopOpacity={1} />
                </linearGradient>
              </defs>
              <Bar
                yAxisId="Expenses"
                barSize={10}
                dataKey="Expenses"
                fill={'#e91e63'}
              />
              <Bar
                yAxisId="Earning"
                barSize={10}
                dataKey="Earning"
                fill={'#3BD2A2'}
              />
            </BarChart>
          </ResponsiveContainer>
        </Div>
      </CardContent>
    );
  }

  return (
    <Card height={152} sx={{ height: '100%' }}>
      <CardHeader title={'Branches Monthly Performance'} />
      <CardContent>
        <DropDown
          label={'Select Branch'}
          options={results}
          value={branch}
          onChange={handleBranchChange}
        />
        <Div>
          <ResponsiveContainer width="100%" height={200}>
            <BarChart
              data={graphResults}
              margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
              <XAxis dataKey="name" />
              <YAxis yAxisId="Expenses" orientation="left" stroke="#e91e63" />
              <YAxis yAxisId="Earning" orientation="right" stroke="#3BD2A2" />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip
                labelStyle={{ color: 'black' }}
                itemStyle={{ color: 'black' }}
                cursor={false}
              />
              <Legend />
              <defs>
                <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#6200EE" stopOpacity={1} />
                  <stop offset="95%" stopColor="#B819D2" stopOpacity={1} />
                </linearGradient>
              </defs>
              <defs>
                <linearGradient id="color2" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#1ABBDE" stopOpacity={1} />
                  <stop offset="95%" stopColor="#09BCA7" stopOpacity={1} />
                </linearGradient>
              </defs>
              <Bar
                yAxisId="Expenses"
                barSize={10}
                dataKey="Expenses"
                fill={'#e91e63'}
              />
              <Bar
                yAxisId="Earning"
                barSize={10}
                dataKey="Earning"
                fill={'#3BD2A2'}
              />
            </BarChart>
          </ResponsiveContainer>
        </Div>
      </CardContent>
    </Card>
  );
};

export default MonthlyStats;
