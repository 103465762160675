import { useMemo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import Card from 'app/pages/dashboards/accounts/components/BitcoinPrice';
import StudentsSidebar from './components/StudentsSidebar';
import StudentsList from './components/StudentsList';

import { calculateMales } from './utils/calculations';

const Students = () => {
  const { category } = useParams();
  const students = useSelector(state => state.students.students);
  const [filteredData, setFilteredData] = useState(students);
  const user = useSelector(state => state.auth.user);

  const isUserHasAddAccess = user?.permissions['student']?.add || false;
  const isSidebarVisibleToUser =
    isUserHasAddAccess || user?.user_type === 'admin';

  const maleStudents = useMemo(
    () => calculateMales(filteredData),
    [filteredData],
  );

  useEffect(() => {
    if (category === 'all') {
      setFilteredData(students);
    } else {
      const filteredStudents = students?.filter(
        single => category === single.branch?.name.toLowerCase(),
      );
      setFilteredData(filteredStudents);
    }
  }, [category, students]);

  return (
    <>
      {/* <Grid container spacing={1} sx={{ marginBottom: 5 }}>
        <Grid item xs={12} sm={6} lg={4}>
          <Card
            title={filteredData.length}
            subHeader={'Total Students'}
            tooltip={false}
            growth={filteredData.length}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Card
            title={filteredData.length - maleStudents}
            subHeader={'Female Students'}
            growth={0}
            tooltip={false}
            bgColor={'#ff6f60'}
            graphStartColor={'#e53935'}
            graphEndColor={'#e53935'}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Card
            title={maleStudents}
            subHeader={'Male Students'}
            growth={maleStudents}
            tooltip={false}
            bgColor={'#A53860'}
            graphStartColor={'#00ada9'}
            graphEndColor={'#00ada9'}
          />
        </Grid>
      </Grid> */}
      <JumboContentLayout
        sidebar={
          isSidebarVisibleToUser && (
            <StudentsSidebar
              isUserHasAddAccess={isUserHasAddAccess}
              isUserAdmin={user?.user_type === 'admin' ? true : false}
            />
          )
        }>
        <StudentsList />
      </JumboContentLayout>
    </>
  );
};
export default Students;
