import React from 'react';
import { useField } from 'formik';
import TextField from '@mui/material/TextField';

const JumboCNICField = ({ name, ...props }) => {
  const [field, meta, helpers] = useField(name);
  const errorText = meta.touched && meta.error ? meta.error : '';

  const handleChange = e => {
    const { value } = e.target;
    const formattedValue = value
      .replace(/\D/g, '') // Remove non-numeric characters
      .slice(0, 13) // Limit to 13 digits
      .replace(/^(\d{5})(\d{1,7})?(\d{0,5})?$/, (match, p1, p2, p3) => {
        let formattedValue = `${p1}`;
        if (p2) formattedValue += `-${p2}`;
        if (p3) formattedValue += `-${p3}`;
        return formattedValue;
      });

    helpers.setValue(formattedValue);
  };

  return (
    <TextField
      {...props}
      {...field}
      helperText={errorText}
      error={!!errorText}
      onChange={handleChange}
    />
  );
};

export default JumboCNICField;
