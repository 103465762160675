import { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import { Card, ButtonGroup, Button } from '@mui/material';
import { List, ViewComfy } from '@mui/icons-material';

import JumboRqList from '@jumbo/components/JumboReactQuery/JumboRqList';
import JumboListToolbar from '@jumbo/components/JumboList/components/JumboListToolbar';
import JumboSearch from '@jumbo/components/JumboSearch';

import ClassItem from './ClassItem';

const ClassesList = () => {
  const { category } = useParams();
  const listRef = useRef();
  const classes = useSelector(state => state.classes.classes);

  const [view, setView] = useState('list');
  const [classesToShow, setClassesToShow] = useState(classes);

  useEffect(() => {
    setClassesToShow(classes);
  }, [classes]);

  useEffect(() => {
    if (category === 'all') {
      setClassesToShow(classes);
    } else {
      const filteredClasses = classes?.filter(singleClass => {
        const branchName = singleClass.branch.name;
        if (category === branchName?.toLowerCase()) {
          return singleClass;
        }
        return null;
      });
      setClassesToShow(filteredClasses);
    }
  }, [category, classes]);

  const renderClass = useCallback(
    contact => {
      return <ClassItem singleClass={contact} view={view} />;
    },
    [view],
  );

  const handleOnChange = useCallback(
    keyword => {
      let filteredClasses = [];
      filteredClasses = classes?.filter(singleClass => {
        return (
          singleClass?.name?.toLowerCase().search(keyword?.toLowerCase()) !== -1
        );
      });
      setClassesToShow(filteredClasses);
    },
    [classes],
  );

  return (
    <JumboRqList
      ref={listRef}
      data={classesToShow}
      wrapperComponent={Card}
      primaryKey={'id'}
      itemsPerPage={8}
      itemsPerPageOptions={[8, 15, 20]}
      renderItem={renderClass}
      componentElement={'div'}
      isClasses
      sx={view === 'grid' && { p: theme => theme.spacing(1, 3, 3) }}
      wrapperSx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
      toolbar={
        <JumboListToolbar
          hideItemsPerPage={true}
          actionTail={
            <ButtonGroup
              variant="outlined"
              disableElevation
              sx={{
                '& .MuiButton-root': {
                  px: 1,
                },
              }}>
              <Button
                variant={view === 'list' ? 'contained' : 'outlined'}
                onClick={() => setView('list')}>
                <List />
              </Button>
              <Button
                variant={view === 'grid' ? 'contained' : 'outlined'}
                onClick={() => setView('grid')}>
                <ViewComfy />
              </Button>
            </ButtonGroup>
          }>
          <JumboSearch
            onChange={handleOnChange}
            sx={{
              display: { xs: 'none', md: 'block' },
            }}
          />
        </JumboListToolbar>
      }
      view={view}
    />
  );
};

export default ClassesList;
