import * as yup from 'yup';

const schemaRole = yup.object().shape({
  title: yup.string().required('Title is required'),
  display_title: yup.string().required('Display Title is required'),
  comments: yup.string().max(500, 'Comments must be at most 500 characters'),
  status: yup.boolean().required('Status is required'),
});

export default schemaRole;
