import { Card, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Div from '@jumbo/shared/Div';
import JumboContent from '@jumbo/components/JumboContent';
import MonthlyStats from '../MonthlyStats';

const TwoBranchesComparison = () => {
  const theme = useTheme();

  return (
    <Card>
      <JumboContent
        title={
          <Typography variant={'h2'} color={'common.black'}>
            Branches Monthly Performance Comparison
          </Typography>
        }
        sx={{ color: 'common.black' }}
      />
      <JumboContent>
        <Grid container>
          <Grid item xs={12} lg={6}>
            <Div
              sx={{
                p: theme => theme.spacing(3, 2, 3, 0),
              }}>
              <MonthlyStats noCard />
            </Div>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Div
              sx={{
                p: theme => theme.spacing(3, 0, 3, 2),
                [theme.breakpoints.up('lg')]: {
                  borderLeft: theme => `solid 2px ${theme.palette.divider}`,
                },
                [theme.breakpoints.down('lg')]: {
                  borderTop: theme => `solid 2px ${theme.palette.divider}`,
                },
              }}>
              <MonthlyStats noCard selectedBranch={2} />
            </Div>
          </Grid>
        </Grid>
      </JumboContent>
    </Card>
  );
};

export default TwoBranchesComparison;
