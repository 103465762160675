import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  API_DELETE,
  API_GET,
  API_POST,
  API_PUT,
} from 'app/utils/constants/api/apiRequests';
import { setLoading, setLoadingFinished } from './loadingSlice';
import { showAlert } from './alertSlice';

const initialState = {
  classes: [],
  isDataFetched: false,
  isDataChanged: false,
  class: {},
  isClassFetching: true,
};

// class-section
export const getAllClasses = createAsyncThunk(
  'classes/getAllClasses',
  async (_, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data } = await API_GET('/class-section');
      if (status) {
        dispatch(
          showAlert({
            type: 'success',
            message: 'Classes Fetched Successfully',
          }),
        );
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const getClass = createAsyncThunk(
  'classes/getClass',
  async (id, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_GET(`/class-section/${id}`);

      if (status) {
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const addNewClass = createAsyncThunk(
  'classes/addNewClass',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    try {
      const {
        data: { status, message },
      } = await API_POST('/class-section', payload);
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllClasses());
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const updateClass = createAsyncThunk(
  'classes/updateClass',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_PUT(
        '/class-section',
        payload.id,
        payload.payload,
      );
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllClasses());
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const deleteClass = createAsyncThunk(
  'classes/deleteClass',
  async (id, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_DELETE('/class-section', id);
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllClasses());
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const classesSlice = createSlice({
  name: 'classes',
  initialState,
  extraReducers: builder => {
    builder.addCase(getAllClasses.fulfilled, (state, { payload }) => {
      state.classes = payload;
      state.isDataFetched = true;
    });
    builder.addCase(addNewClass.fulfilled, () => {
      window.location.replace('/app/classes/all');
    });
    builder.addCase(updateClass.fulfilled, () => {
      window.location.replace('/app/classes/all');
    });
    builder.addCase(getClass.fulfilled, (state, { payload }) => {
      state.class = payload;
      state.isClassFetching = false;
    });
  },
});

export default classesSlice.reducer;
