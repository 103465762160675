export const calculateNumberOfStudents = data => {
  let students = 0;

  data.map(({ numberOfStudents }) => {
    students += numberOfStudents;

    return null;
  });

  return students;
};

export const calculateSections = data => {
  let numberOfSections = 0;
  const sections = [];

  data.map(({ section }) => {
    if (sections.includes(section)) {
    } else {
      sections.push(section);
      numberOfSections++;
    }
    return null;
  });

  return numberOfSections;
};

export const calculateFavorites = data => {
  let favorites = 0;

  data.map(({ favorite }) => {
    if (favorite) favorites++;
    return null;
  });

  return favorites;
};

export const getBranches = data => {
  const branches = [];
  for (let i = 0; i < data.length; i++) {
    let push = true;
    for (let j = 0; j < branches.length; j++) {
      if (branches[j].value.toLowerCase() === data[i].branch.toLowerCase()) {
        push = false;
        break;
      }
    }

    if (push) {
      const obj = {
        id: i + 1,
        label: data[i].branch,
        value: data[i].branch,
      };
      branches.push(obj);
    }
  }
  return branches;
};

export const getClassInCharges = data => {
  const classInCharges = [];
  for (let i = 0; i < data.length; i++) {
    let push = true;
    for (let j = 0; j < classInCharges.length; j++) {
      if (
        classInCharges[j].value.toLowerCase() === data[i].inCharge.toLowerCase()
      ) {
        push = false;
        break;
      }
    }
    if (push) {
      const obj = {
        id: i + 1,
        label: data[i].inCharge,
        value: data[i].inCharge,
      };
      classInCharges.push(obj);
    }
  }
  return classInCharges;
};
