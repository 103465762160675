import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import { getExamInitiators } from 'app/store/features/examinationSlice';
import ExaminationList from '../components/ExamInitiatorList';
import ExamInitiatorSidebar from '../components/ExamInitiatorSidebar';

const ExamInitiator = () => {
  const dispatch = useDispatch();
  const isDataFetched = useSelector(state => state.examination.isDataFetched);

  const fetchData = useCallback(() => {
    if (!isDataFetched) dispatch(getExamInitiators());
  }, [dispatch, isDataFetched]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <JumboContentLayout sidebar={<ExamInitiatorSidebar />}>
        <ExaminationList />
      </JumboContentLayout>
    </>
  );
};
export default ExamInitiator;
