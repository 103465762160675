import { createSlice } from '@reduxjs/toolkit';

// local storage
const localStorageUser = JSON.parse(localStorage.getItem('fotanistanUser'));
const token = localStorage.getItem('fotanistanAccessToken');

const initialState = {
  user: localStorageUser ? localStorageUser : {},
  token,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logOut: state => {
      state.token = null;
      state.user = {};
      localStorage.removeItem('fotanistanUser');
      localStorage.removeItem('fotanistanAccessToken');
    },
    updateProfile: (state, { payload }) => {
      state.user = payload;
      localStorage.setItem('fotainstanUser', payload);
    },
    addUser: (state, { payload: { token, user } }) => {
      state.token = token;
      state.user = user;
    },
  },
  extraReducers: builder => {},
});

export const { logOut, updateProfile, addUser } = authSlice.actions;

export default authSlice.reducer;
