import * as yup from 'yup';

export const itemSchema = yup.object().shape({
  name: yup.string().required('Item Name is Required'),
  description: yup.string().required('Description is Required'),
  user_item_code: yup
    .number()
    .positive('User Item Code must be positive')
    .required('User Item Code is Required'),
  price: yup
    .number()
    .positive('Price should be positive')
    .required('Price is Required'),
  group: yup.string().required('Group is Required'),
  current_stock: yup
    .number()
    .positive('Current Stock cannot be negative')
    .required('Current Stock is Required'),
  min_threshhold: yup
    .number()
    .positive('Minimum Threshhold cannot be negative')
    .required('Minimum Thresh hold is Required'),
  is_stockable: yup.boolean().required('Is Stockable is Required'),
  status: yup.boolean().required('Status is Required'),
});
