import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  API_GET,
  API_POST,
  API_PUT,
  API_DELETE,
} from 'app/utils/constants/api/apiRequests';
import { setLoading, setLoadingFinished } from './loadingSlice';
import { showAlert } from './alertSlice';

const initialState = {
  expenseCategories: [],
  isExpenseCategoriesFetched: false,
  expenses: [],
  isDataFetched: false,
  bills: [],
  isBillsFetched: false,
};

// expense categories
export const getAllExpenseCategories = createAsyncThunk(
  'expense/getAllExpenseCategories',
  async (_, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data } = await API_GET('/expense-category');
      if (status) {
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const addNewExpenseCategory = createAsyncThunk(
  'expense/addNewExpenseCategory',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, message } = await API_POST('/expense-category', payload);
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllExpenseCategories());
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const updateExpenseCategory = createAsyncThunk(
  'expense/updateExpenseCategory',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_PUT(
        '/expense-category',
        payload.id,
        payload.payload,
      );
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllExpenseCategories());
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const removeExpenseCategory = createAsyncThunk(
  'expense/removeExpenseCategory',
  async (id, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_DELETE(
        '/expense-category',
        id,
      );
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllExpenseCategories());
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

// expenses
export const getAllExpenses = createAsyncThunk(
  'expense/getAllExpenses',
  async (_, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data } = await API_GET('/expense');
      if (status) {
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const addNewExpense = createAsyncThunk(
  'expense/addNewExpense',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, message } = await API_POST('/expense', payload);
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllExpenses());
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const updateExpense = createAsyncThunk(
  'expense/updateExpense',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_PUT(
        '/expense',
        payload.id,
        payload.payload,
      );
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllExpenses());
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const removeExpense = createAsyncThunk(
  'expense/removeExpense',
  async (id, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_DELETE('/expense', id);
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllExpenses());
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

// bill
export const getAllBills = createAsyncThunk(
  'expense/getAllBills',
  async (_, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data } = await API_GET('/bill');
      if (status) {
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const addNewBill = createAsyncThunk(
  'expense/addNewBill',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, message } = await API_POST('/bill', payload);
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllBills());
        return true;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const updateBill = createAsyncThunk(
  'expense/updateBill',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_PUT(
        '/bill',
        payload.id,
        payload.payload,
      );
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllBills());
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const removeBill = createAsyncThunk(
  'expense/removeBill',
  async (id, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_DELETE('/bill', id);
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllBills());
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const expenseSlice = createSlice({
  name: 'users',
  initialState,
  extraReducers: builder => {
    builder.addCase(getAllExpenseCategories.fulfilled, (state, { payload }) => {
      state.expenseCategories = payload;
      state.isExpenseCategoriesFetched = true;
    });
    builder.addCase(getAllExpenses.fulfilled, (state, { payload }) => {
      state.expenses = payload;
      state.isDataFetched = true;
    });
    builder.addCase(getAllBills.fulfilled, (state, { payload }) => {
      state.bills = payload;
      state.isBillsFetched = true;
    });
  },
});

export default expenseSlice.reducer;
