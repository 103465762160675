import React from 'react';
import { useParams } from 'react-router-dom';

import { Contacts, Stars } from '@mui/icons-material';

import CategoryItem from './CategoryItem';
import StyledMenu from '../../../../../shared/StyledMenu';
import { Typography } from '@mui/material';

const folder = {
  icon: <Contacts fontSize={'small'} />,
  label: 'All Students',
  slug: 'all',
  path: '/app/fees/all',
};
const categories = [
  {
    slug: 'pending',
    label: 'Pending Fee',
    icon: <Stars fontSize={'small'} />,
    path: '/app/fees/pending',
  },
  {
    slug: 'partial',
    label: 'Partially Cleared',
    icon: <Stars fontSize={'small'} />,
    path: '/app/fees/partial',
  },
  {
    slug: 'defaulter',
    label: 'Fee Defaulters',
    icon: <Stars fontSize={'small'} />,
    path: '/app/fees/defaulter',
  },
];

const Categories = () => {
  const params = useParams();

  return (
    <StyledMenu sx={{ mb: 2 }}>
      <CategoryItem
        key={folder.slug}
        path={folder.path}
        label={folder.label}
        icon={folder.icon}
        selected={folder.slug === params?.category}
      />

      <Typography sx={{ fontSize: 18, margin: '10px 0' }}>
        Categories
      </Typography>
      {categories?.map(({ slug, path, label, icon }) => (
        <CategoryItem
          key={slug}
          path={path}
          label={label}
          icon={icon}
          selected={slug.toLocaleLowerCase() === params?.category}
        />
      ))}
    </StyledMenu>
  );
};

export default React.memo(Categories);
