import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  API_DELETE,
  API_GET,
  API_POST,
  API_PUT,
} from 'app/utils/constants/api/apiRequests';
import { setLoading, setLoadingFinished } from './loadingSlice';
import { showAlert } from './alertSlice';

const initialState = {
  groups: [],
  isDataFetched: false,
};

export const getAllGroups = createAsyncThunk(
  'group/getAllGroups',
  async (_, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_GET('/group');

      if (status) {
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const addNewGroup = createAsyncThunk(
  'group/addNewGroup',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, message } = await API_POST('/group', payload);
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllGroups());
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const editGroup = createAsyncThunk(
  'group/editGroup',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    const dataToSend = JSON.parse(JSON.stringify(payload));
    delete dataToSend['id'];
    try {
      const { status, data, message } = await API_PUT(
        '/group',
        payload.id,
        dataToSend,
      );
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllGroups());
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const deleteGroup = createAsyncThunk(
  'group/deleteGroup',
  async (id, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_DELETE('/group', id);
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllGroups());
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const groupSlice = createSlice({
  name: 'group',
  initialState,

  extraReducers: builder => {
    builder.addCase(getAllGroups.fulfilled, (state, { payload }) => {
      state.groups = payload;
      state.isDataFetched = true;
    });
  },
});

export default groupSlice.reducer;
