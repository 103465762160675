import React from 'react';
import { useField } from 'formik';
import { Select, MenuItem, Typography } from '@mui/material';

import Div from '@jumbo/shared/Div';

const JumboDropDown = props => {
  const [field, meta] = useField(props);
  const { options, label } = props;
  const errorText = meta.error && meta.touched ? meta.error : '';
  const isExamFollowUp = props.isExamFollowUp;

  return (
    <Div sx={{ marginBottom: 2 }}>
      <Typography color="gray" sx={{ marginTop: -1 }}>
        {label}
      </Typography>
      <Select
        {...props}
        {...field}
        label={label}
        helperText={errorText}
        error={!!errorText}>
        {options.map((option, index) => (
          <MenuItem key={index} value={option.id ? option.id : option.value}>
            {props.isForFee
              ? `${option.first_name} ${option.last_name}`
              : isExamFollowUp
                ? `${option.subject?.name}, ${option.class_section?.class_section_title}`
                : option.display_title
                  ? option.display_title
                  : option.title
                    ? option.title
                    : option.label
                      ? option.label
                      : option.name
                        ? option.name
                        : option.class_section_title
                          ? option.class_section_title
                          : option.username}
          </MenuItem>
        ))}
      </Select>

      {errorText && (
        <Typography color="red" variant="body2" sx={{ fontSize: '0.75rem' }}>
          {errorText}
        </Typography>
      )}
    </Div>
  );
};

export default JumboDropDown;
