import { useField } from 'formik';
import { Switch, FormControlLabel } from '@mui/material';

const JumboSwitch = ({ name, label, ...rest }) => {
  const [field, meta] = useField({ name });

  const handleChange = event => {
    const { checked } = event.target;
    field.onChange({ target: { name, value: checked } });
  };

  const errorText = meta.error && meta.touched ? meta.error : '';
  return (
    <FormControlLabel
      control={
        <Switch checked={field.value} onChange={handleChange} {...rest} />
      }
      label={label}
      error={!!errorText}
      helperText={errorText}
    />
  );
};

export default JumboSwitch;
